import React from "react";

// Components
import BaseListViewComponent from "./BaseListViewComponent";

// Functions
import * as Helper from "./Helper";
import * as Constants from "./Constants";

class RepairList extends BaseListViewComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      hideFilter: true,
    };
  }

  getHeaderRowItems = () => {
    return [
      { classes: "header firstLeft", sortable: true, columnheading: "Date", sortkey: "orders.creationdatetime" },
      { classes: "header desktop", sortable: true, columnheading: "Due", sortkey: "orders.duedatetime" },
      { classes: "header desktop", sortable: true, columnheading: "Service", sortkey: "orders.ordernumber" },
      { classes: "header", sortable: true, columnheading: "Customer", sortkey: "orders.contactname" },
      { classes: "header desktop", columnheading: "Phone" },
      { classes: "header desktop", sortable: true, columnheading: "Model", sortkey: "first_item.model" },
      { classes: "header desktop", sortable: true, columnheading: "Technician", sortkey: "first_item.technician" },
      { classes: "header right-aligned desktop", sortable: true, columnheading: "Total", sortkey: "orders.totalprice" },
      { classes: "header centerAligned lastRight", sortable: true, columnheading: "Status", sortkey: "orders.orderstatus" },
    ];
  };

  renderItemToColumns = item => {
    const mobileCreationDate = <span data-testid="Mobile Creation Date"> {Helper.formatDate(item.creationdatetime, true)}</span>;
    const desktopCreationDate = <span data-testid="Desktop Creation Date"> {Helper.formatDate(item.creationdatetime, false)}</span>;
    const desktopDueDate = <span data-testid="Desktop Due Date">{Helper.formatDate(item.duedatetime, false)}</span>;
    const orderNumber = Helper.renderOrderNumber(item.ordertype, item.ordersubtype, item.ordernumber, item.externalid, this.props.filtertype);
    const customername = Helper.renderCustomerName(item.contactname, item.companyname);
    const phone = Helper.renderPhone(item.mobilephone, item.otherphone);
    const totalPricePlusTax = Helper.renderTotalPricePlusTax(item.totalpriceplustax);
    const orderstatus = Helper.renderStatus(item.orderstatus, item.ordersubtype);

    let model = item.model;
    if (item.itemcount > 1) {
      model += " (+" + (item.itemcount - 1) + ")";
    }
    model = <span data-testid="Service Model">{model}</span>;

    let technician = this.props.appState.technicians.find(technician => technician.value === item.technician ?? "")?.fullname ?? "";
    technician = <span data-testid="Service Technician">{technician}</span>;

    return [
      { rowvalue: mobileCreationDate, classes: "mobile" },
      { rowvalue: desktopCreationDate, classes: "desktop" },
      { rowvalue: desktopDueDate, classes: "desktop" },
      { rowvalue: orderNumber, classes: "desktop" },
      { rowvalue: customername },
      { rowvalue: phone, classes: "desktop" },
      { rowvalue: model, classes: "desktop" },
      { rowvalue: technician, classes: "desktop" },
      { rowvalue: totalPricePlusTax, classes: "right-aligned desktop" },
      { rowvalue: orderstatus, classes: "right-aligned" },
    ];
  };

  renderTopControlButtons = () => {
    let openTabClassName = "action-tab";
    let processedTabClassName = "action-tab";
    let allTabClassName = "action-tab";
    if (this.props.filtertype.tab === Constants.ORDER_STATUS_OPEN) {
      openTabClassName += " selected ";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_PROCESSED) {
      processedTabClassName += " selected ";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_ALL) {
      allTabClassName += " selected ";
    }

    return (
      <React.Fragment>
        <div className="orderlisttopcontrols">
          <span
            data-testid="Open Tab"
            className={openTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.ORDER_STATUS_OPEN });
              this.handleFilterList({ tab: Constants.ORDER_STATUS_OPEN });
            }}
          >
            Open
          </span>
          <span
            data-testid="Processed Tab"
            className={processedTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.ORDER_STATUS_PROCESSED });
              this.handleFilterList({ tab: Constants.ORDER_STATUS_PROCESSED });
            }}
          >
            Processed
          </span>
          <span
            data-testid="All Tab"
            className={allTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.ORDER_STATUS_ALL });
              this.handleFilterList({ tab: Constants.ORDER_STATUS_ALL });
            }}
          >
            All
          </span>
        </div>
        <span className="desktop"></span>
        <span className="action-tab-balance">
          <span></span>
          {this.renderCalendarFilter()}
        </span>
      </React.Fragment>
    );
  };

  getListGridClassName = () => {
    if (this.props.filtertype.tab === Constants.ORDER_STATUS_OPEN) {
      return "repairlist open";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_PROCESSED) {
      return "repairlist processed";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_ALL) {
      return "repairlist all";
    }
  };

  getOrderType = () => {
    return Constants.REPAIR;
  };
}
export default RepairList;
