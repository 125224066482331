import React from "react";

// Components
import BaseListViewComponent from "./BaseListViewComponent";

// Functions
import * as Helper from "./Helper";
import * as Constants from "./Constants";
import numeral from "numeral";

class OrderList extends BaseListViewComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      hideFilter: true,
      showInventory: false,
    };
  }

  getHeaderRowItems = () => {
    if (this.props.filtertype.tab === Constants.ORDER_STATUS_OPEN) {
      // The open tab has an eyeball on quantity to toggle inventory counts where the other tabs do not
      return this.getHeaderRowItemsOpen();
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_PROCESSED) {
      return this.getHeaderRowItemsProcessed();
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_ALL) {
      return this.getHeaderRowItemsProcessed();
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_SPECIALS) {
      return null;
    }
  };

  getHeaderRowItemsOpen = () => {
    let quantity = "Qty";
    if (this.state.showInventory) {
      quantity += " / Inv";
    }
    return [
      { classes: "header firstLeft", sortable: true, columnheading: "Date", sortkey: "orders.creationdatetime" },
      { classes: "header desktop", sortable: true, columnheading: "Order", sortkey: "orders.ordernumber" },
      { classes: "header desktop", sortable: true, columnheading: "Customer", sortkey: "orders.contactname" },
      { classes: "header desktop", columnheading: "Phone" },
      { classes: "header ", sortable: true, columnheading: "Product", sortkey: "orderitems.productname" },
      { classes: "header desktop", sortable: true, columnheading: "Supplier", sortkey: "companies.companyname" },
      {
        classes: "header desktop right-aligned",
        sortable: true,
        columnheading: quantity,
        sortkey: "orderitems.quantity",
        eyeball: true,
        handleToggleEyeball: this.handleToggleInventory,
        eyeballSlashed: this.state.showInventory,
        eyeballTitle: "Click to " + (this.state.showInventory ? "hide" : "show") + " inventory counts",
      },
      { classes: "header right-aligned lastRight", sortable: true, columnheading: "Total", sortkey: "totalprice" },
      { classes: "header desktop centerAligned lastRight", sortable: true, columnheading: "Status", sortkey: "orders.orderstatus" },
    ];
  };

  handleToggleInventory = () => {
    this.setState({ showInventory: !this.state.showInventory });
  };

  getHeaderRowItemsProcessed = () => {
    return [
      { classes: "header firstLeft", sortable: true, columnheading: "Date", sortkey: "orders.creationdatetime" },
      { classes: "header desktop", sortable: true, columnheading: "Order", sortkey: "orders.ordernumber" },
      { classes: "header desktop", sortable: true, columnheading: "Customer", sortkey: "orders.contactname" },
      { classes: "header desktop", columnheading: "Phone" },
      { classes: "header  ", sortable: true, columnheading: "Product", sortkey: "orderitems.productname" },
      { classes: "header desktop", sortable: true, columnheading: "Supplier", sortkey: "companies.companyname" },
      { classes: "header desktop right-aligned", sortable: true, columnheading: "Qty", sortkey: "orderitems.quantity" },
      { classes: "header right-aligned", sortable: true, columnheading: "Total", sortkey: "totalprice" },
      { classes: "header desktop centerAligned lastRight", sortable: true, columnheading: "Status", sortkey: "orders.orderstatus" },
    ];
  };

  getOrderType = () => {
    return Constants.ORDER;
  };

  renderItemToColumns = item => {
    if (this.props.filtertype.tab !== Constants.ORDER_STATUS_SPECIALS) {
      return this.renderItemToColumnsCommon(item);
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_SPECIALS) {
      if (item.type === "header") {
        return this.renderItemToColumnsSpecialsHeader(item);
      } else {
        return this.renderItemToColumnsSpecials(item);
      }
    }
  };

  renderItemToColumnsCommon = item => {
    const mobileCreationDate = <span data-testid="Mobile Creation Date"> {Helper.formatDate(item.creationdatetime, true)}</span>;
    const desktopCreationDate = <span data-testid="Desktop Creation Date"> {Helper.formatDate(item.creationdatetime, false)}</span>;
    const orderNumber = Helper.renderOrderNumber(item?.ordertype, item?.ordersubtype, item?.ordernumber, item?.externalid, this.props.filtertype);
    const customername = Helper.renderCustomerName(item.contactname, item.companyname);
    const phone = Helper.renderPhone(item.mobilephone, item.otherphone);
    const productname = <span data-testid="Product Name">{item.productname}</span>;
    const companyname = <span data-testid="Supplier Name">{item.companyname}</span>;
    const orderstatus = Helper.renderStatus(item.orderstatus, item.ordersubtype);

    let quantity = numeral(item.quantity).format(Constants.DECIMAL_VALUE);
    if (this.state.showInventory) {
      quantity += " / " + numeral(item.inventory).format(Constants.DECIMAL_VALUE);
    }
    quantity = <span data-testid="Quantity">{quantity}</span>;

    let totalprice = numeral(item.totalprice).format(Constants.CURRENCY);
    totalprice = <span data-testid="Total Price">{totalprice}</span>;

    return [
      { rowvalue: mobileCreationDate, classes: "mobile" },
      { rowvalue: desktopCreationDate, classes: "desktop" },
      { rowvalue: orderNumber, classes: "desktop" },
      { rowvalue: customername, classes: "desktop" },
      { rowvalue: phone, classes: "desktop" },
      { rowvalue: productname, classes: "" },
      { rowvalue: companyname, classes: "desktop" },
      { rowvalue: quantity, classes: "desktop right-aligned" },
      { rowvalue: totalprice, classes: "right-aligned" },
      { rowvalue: orderstatus, classes: "desktop right-aligned" },
    ];
  };

  renderDescriptionBlock = item => {
    return (
      <div>
        <div className="specialsku">{item.sku}</div>
        <div className="specialshortdesc desktop">{item.shortdescription}</div>
        <div className="specialdesc desktop">{item.description}</div>
      </div>
    );
  };

  renderPriceBlock = (heading, price) => {
    return (
      <div>
        <div>{heading}</div>
        <div>{numeral(price).format(Constants.CURRENCY_WITH_SYMBOL)}</div>
      </div>
    );
  };

  renderItemToColumnsSpecialsHeader = item => {
    const vendorname = item.vendorname;
    return [{ rowvalue: vendorname, classes: "span5" }];
  };

  renderItemToColumnsSpecials = item => {
    const imagealt = "Image of " + item.productname;
    const imageurl = <img className="vendorimage" alt={imagealt} src={item.imageurl} />;
    const descblock = this.renderDescriptionBlock(item);
    const addtoorders = <span className="action-button green-button">Add to orders</span>;
    return [
      { rowvalue: imageurl },
      { rowvalue: descblock },
      { rowvalue: this.renderPriceBlock("Normal", item.price) },
      { rowvalue: this.renderPriceBlock("Special", item.specialprice) },
      { rowvalue: addtoorders },
    ];
  };

  renderTopControlButtons = () => {
    let openTabClassName = "action-tab";
    let processedTabClassName = "action-tab";
    let allTabClassName = "action-tab";
    // let specialsTabClassName = "action-tab";
    let createButton = "";
    if (this.props.filtertype.tab === Constants.ORDER_STATUS_OPEN) {
      openTabClassName += " selected ";
      createButton = this.renderCreatePurchaseButton("");
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_PROCESSED) {
      processedTabClassName += " selected ";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_ALL) {
      allTabClassName += " selected ";
      // } else if (this.props.filtertype.tab === ORDER_STATUS_SPECIALS) {
      //   specialsTabClassName += " selected ";
    }

    return (
      <React.Fragment>
        <div className="orderlisttopcontrols">
          <span
            data-testid="Open Tab"
            className={openTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.ORDER_STATUS_OPEN });
              this.handleFilterList({ tab: Constants.ORDER_STATUS_OPEN });
            }}
          >
            Open
          </span>
          <span
            data-testid="Processed Tab"
            className={processedTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.ORDER_STATUS_PROCESSED });
              this.handleFilterList({ tab: Constants.ORDER_STATUS_PROCESSED });
            }}
          >
            Processed
          </span>
          <span
            data-testid="All Tab"
            className={allTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.ORDER_STATUS_ALL });
              this.handleFilterList({ tab: Constants.ORDER_STATUS_ALL });
            }}
          >
            All
          </span>
          {/*
          Specials Tab not ready to implement.
           <span
            className={specialsTabClassName}
            onClick={() => this.handleFilterList({tab:ORDER_STATUS_SPECIALS})}
          >
            Specials
          </span> 
          */}
        </div>
        <span className="desktop">{createButton}</span>
        <span className="action-tab-balance">
          <span></span>
          {this.renderCalendarFilter()}
        </span>
      </React.Fragment>
    );
  };

  getListGridClassName = () => {
    if (this.props.filtertype.tab === Constants.ORDER_STATUS_OPEN) {
      return "orderlist open";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_PROCESSED) {
      return "orderlist processed";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_ALL) {
      return "orderlist all";
    } else if (this.props.filtertype.tab === "specials") {
      return "orderlist specials";
    }
  };
}
export default OrderList;
