import { Component } from "react";

class TawkChat extends Component {
  constructor(props) {
    super(props);
    this.script = null; // Declare script property
  }

  componentDidMount() {
    if (!window.Tawk_API) {
      const host = window.location.hostname;
      let tawkSrc = "";

      if (host === "app.clerkhound.com") {
        // Production Tawk.to script
        tawkSrc = "https://embed.tawk.to/679c1dbe3a8427326077dc29/1iist8cdd";
        console.log("Tawk.to chat is enabled in production mode.");
      } else if (host === "app-test.clerkhound.com") {
        // QA Tawk.to script
        tawkSrc = "https://embed.tawk.to/679c1dbe3a8427326077dc29/1iist8cdd";
        console.log("Tawk.to chat is enabled in QA mode.");
      } else if (host === "localhost") {
        console.log("Tawk.to chat is disabled in development mode.");
        return; // Avoid loading script in development
      }

      // Create and append script
      this.script = document.createElement("script");
      this.script.id = "tawkchatscriptelement";
      this.script.src = tawkSrc;
      this.script.async = true;
      this.script.setAttribute("charset", "UTF-8");
      this.script.setAttribute("crossorigin", "*");

      document.body.appendChild(this.script);

      // Once the script loads, set user details
      this.script.onload = () => {
        if (window.Tawk_API) {
          this.setUserAttributes();
        }
      };
    } else {
      this.setUserAttributes();
    }
  }

  componentWillUnmount() {
    // Remove script when component unmounts to prevent duplicates
    if (this.script && document.body.contains(this.script)) {
      document.body.removeChild(this.script);
    }
  }

  render() {
    return null; // No UI elements needed, just loads the script
  }

  setUserAttributes = () => {
    if (window.Tawk_API) {
      window.Tawk_API.setAttributes(
        {
          name: this.props.salesperson || "Guest",
          email: this.props.username || "",
        },
        error => {
          if (error) console.error("Tawk.to error:", error);
        }
      );
    }
  };
}

export default TawkChat;
