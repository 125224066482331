import React from "react";

import { faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Constants from "./Constants";
import * as Helper from "./Helper";
import BaseDetailViewComponent from "./BaseDetailViewComponent";
import ButtonControls from "./ButtonControls";
import CashIcon from "./img/CashIcon.js";
import CheckIcon from "./img/CheckIcon.js";
import CreditCardEntry from "./CreditCardEntry";
import CreditIcon from "./img/CreditIcon.js";
import GiftCardIcon from "./img/GiftCardIcon.js";
import ListHeaderView from "./ListHeaderView";
import ListItemView from "./ListItemView";
import numeral from "numeral";
import PaymentAcceptIcon from "./img/PaymentAcceptIcon.js";
import PaymentDeclineIcon from "./img/PaymentDeclineIcon.js";
import PayPalIcon from "./img/PayPalIcon.js";
import TagList from "./TagList";
import Tooltip from "./Tooltip";

// View for editing a Order.
class Pay extends BaseDetailViewComponent {
  constructor(props) {
    super(props);

    this.tenderAmountRef = React.createRef();
    this.giftCardNumberRef = React.createRef();
    this.payPalConfRef = React.createRef();
    this.checkNumberRef = React.createRef();
    this.otherDescriptionRef = React.createRef();

    this.state = { ...this.state, giftcardnumber: "", giftcardvalue: 0.0, storeCreditValue: 0.0 };
  }

  componentDidMount() {
    super.componentDidMount();
    // Check customer for store credit balance
    const storeCredit = numeral(this.state.order.company?.storecredit ?? 0).value();
    if (storeCredit > 0) {
      this.setState({ storeCreditValue: storeCredit });
    }
    // If the customer is vaulted, go look for the customer's vaulted payment methods
    if (this.state.order?.company?.vaulted) {
      this.getCustomerFromVault(this.state.order?.company?.companyuuid);
    }
  }

  renderProductListNarrow = () => {
    return this.renderProductList();
  };

  renderProductList = () => {
    const headers = [
      { classes: "header", columnheading: "Product" },
      { classes: "header right-aligned", columnheading: "Qty" },
      { classes: "header right-aligned desktop", columnheading: "Each" },
      { classes: "header right-aligned", columnheading: "Total" },
    ];
    return (
      <div className="productListGrid payproductlist">
        <ListHeaderView headerRowItems={headers} />
        <hr className="headerRule4" />
        <ListItemView
          listitems={this.state.order.orderitems}
          expandedListItems={null}
          selectedListItems={[]}
          renderItemToColumns={this.renderItemsToColumns}
          toggleCollapsed={() => {}}
          selectListItem={() => {}}
          handleEditItem={() => {}}
          handleTouchStart={() => {}}
          handleTouchEnd={() => {}}
        />
      </div>
    );
  };

  renderItemsToColumns = item => {
    const each = numeral(item.sellprice).subtract(numeral(item.discount).value());
    const totalprice = each.multiply(numeral(item.quantity).value());

    let tooltip = "";
    if (item.tags?.length > 0) {
      const icon = <FontAwesomeIcon icon={faTag} />;
      let tagListElement = <TagList tags={item.tags} readonly={true} />;
      tooltip = <Tooltip text={icon} tooltip={tagListElement} wrapperClasses="tagListParent" />;
    }
    let productname = (
      <span>
        {item.productname + (item.isgiftcard ? " #" + item.giftCardNumber : "")} {tooltip}
      </span>
    );

    return [
      { rowvalue: productname },
      {
        rowvalue: numeral(item.quantity).format(Constants.DECIMAL_VALUE),
        classes: "right-aligned",
      },
      { rowvalue: each.format(Constants.CURRENCY), classes: "desktop right-aligned" },
      { rowvalue: totalprice.format(Constants.CURRENCY), classes: "right-aligned" },
    ];
  };

  renderPaymentButtons = () => {
    const buttonClasses = "payment-action-button green-button";
    const ppButtonClasses = "payment-action-button-pp green-button";
    let giftCardTitle = "";
    if (this.isTradeIn()) {
      giftCardTitle = "Issue store credit instead";
    } else if (this.isReturn()) {
      if (this.isWalkinCustomer()) {
        giftCardTitle = "Issue cash instead";
      } else {
        giftCardTitle = "Issue store credit instead";
      }
    }
    return (
      <div className="paymentMethods">
        {this.renderBalanceDue()}
        <span className="payment-action-button-cell gridCenter">
          <span
            data-testid="Credit Payment Button"
            onClick={() => {
              this.handleClick(Constants.CREDIT);
            }}
            className={buttonClasses + (this.enablePayment(Constants.CREDIT) ? "" : " save-disabled ")}
          >
            <CreditIcon />
            <span> Credit</span>
          </span>
        </span>

        <span className="payment-action-button-cell gridCenter">
          <span
            data-testid="Cash Payment Button"
            onClick={() => {
              this.handleClick(Constants.CASH);
            }}
            className={buttonClasses + (this.enablePayment(Constants.CASH) ? "" : " save-disabled ")}
          >
            <CashIcon />
            <span> Cash</span>
          </span>
        </span>

        <span className="payment-action-button-cell gridCenter">
          <span
            data-testid="Gift Card Payment Button"
            title={giftCardTitle}
            onClick={() => {
              this.handleClick(Constants.GIFTCARD);
            }}
            className={buttonClasses + (this.enablePayment(Constants.GIFTCARD) ? "" : " save-disabled ")}
          >
            <GiftCardIcon />
            <span> Gift Card</span>
          </span>
        </span>

        <span className="payment-action-button-cell gridCenter">
          <span
            data-testid="PayPal Payment Button"
            onClick={() => {
              this.handleClick(Constants.PAYPAL);
            }}
            className={ppButtonClasses + (this.enablePayment(Constants.PAYPAL) ? "" : " save-disabled ")}
            id="paypal"
          >
            <PayPalIcon />
          </span>
        </span>

        <span className="payment-action-button-cell gridCenter">
          <span
            data-testid="Check Payment Button"
            onClick={() => {
              this.handleClick(Constants.CHECK);
            }}
            className={buttonClasses + (this.enablePayment(Constants.CHECK) ? "" : " save-disabled ")}
          >
            <CheckIcon />
            <span> Check</span>
          </span>
        </span>

        <span className="payment-action-button-cell gridCenter">
          <span
            data-testid="Other Payment Button"
            onClick={() => {
              this.handleClick(Constants.OTHER);
            }}
            className={buttonClasses + (this.enablePayment(Constants.OTHER) ? "" : " save-disabled ") + " center-button "}
          >
            <span className="other"> Other</span>
          </span>
        </span>

        {/* 
        These two buttons come and go depending on circumstances, so they are rendered via function calls.
        The enablePayment() function is called inside the functions below.
        */}
        {this.renderStoreCreditButton()}
        {this.renderStoredPaymentButton()}
      </div>
    );
  };

  renderBalanceDue = () => {
    if (!Helper.inList([Constants.INVOICE, Constants.PAY], this.props.appState.currentView)) {
      return "";
    }
    const balanceDueClassName = this.state.order?.balancedue ?? this.props.selectedItem.balancedue ? "balanceDue" : "paidInFull";
    const balanceDue = numeral(this.state.order?.balancedue ?? this.props.selectedItem.balancedue).value();
    let balanceDueLabel = "";

    // If the invoice is a return, then display amount due to customer.
    // If not a return, but the balance due is a negative value, then change is due to the customer
    // If not a return, but the balance due is a positive value, then the customer still owes money
    if (this.isReturn()) {
      balanceDueLabel = "Refund Due";
    } else if (this.isTradeIn()) {
      balanceDueLabel = "Due to Customer";
    } else if (balanceDue <= 0) {
      balanceDueLabel = "Change Due";
    } else if (balanceDue > 0) {
      balanceDueLabel = "Balance Due";
    }
    return (
      <React.Fragment>
        <h1 className={balanceDueClassName}>{balanceDueLabel}: </h1>
        <h1 className={balanceDueClassName} data-testid="Balance Due Heading">
          {numeral(balanceDue).format(Constants.CURRENCY_WITH_SYMBOL)}
        </h1>
      </React.Fragment>
    );
  };

  renderStoreCreditButton = () => {
    if (!this.isProtectedCustomer() && (this.isReturn() || this.isTradeIn() || this.state.storeCreditValue > 0)) {
      return (
        <span className="payment-action-button-cell gridCenter">
          <span
            data-testid="Store Credit Payment Button"
            onClick={() => {
              this.handleClick(Constants.STORECREDIT);
            }}
            className="payment-action-button brown-button center-button"
          >
            <span className="storecredit"> Store Credit</span>
          </span>
        </span>
      );
    } else {
      return <span className="payment-action-button-cell gridCenter"></span>;
    }
  };

  // Manager login required to access this feature!!!
  // And manager authorization is required at the time of the credit auth
  renderStoredPaymentButton = () => {
    if (!this.enablePayment(Constants.VAULT)) {
      return <span className="payment-action-button-cell gridCenter"></span>;
    }
    if (
      Helper.isBillingFeatureEnabled(this.props.appState.features) &&
      Helper.authorize(Constants.ACTION_PAY_BY_VAULT, this.props.appState.usertype) &&
      !this.isProtectedCustomer() &&
      !Helper.isReturn(this.state.order) &&
      this.state.maastCustomer?.billing_cards?.length > 0
      // this.state.order?.company?.vaulted
    ) {
      return (
        <span className="payment-action-button-cell gridCenter">
          <span
            data-testid="Stored Payment Payment Button"
            onClick={() => {
              this.handleClick(Constants.STORED_PAYMENT);
            }}
            className="payment-action-button brown-button center-button"
          >
            <span className="storedpayment"> Vault</span>
          </span>
        </span>
      );
    } else {
      return <span className="payment-action-button-cell gridCenter"></span>;
    }
  };

  renderCredit = () => {
    return (
      <CreditCardEntry
        appState={this.props.appState}
        amount={this.state.order.balancedue}
        collectApiKey={this.props.collectApiKey}
        getCustomerFromVault={this.getCustomerFromVault}
        handleCancel={this.handleCancel}
        handleChange={this.handleChange}
        handleDecline={this.handleDecline}
        handleCreditApproval={this.handleCreditApproval}
        handleProcess={this.handleProcess}
        handleRefreshOrder={this.handleRefreshOrder}
        handpoint={this.props.appState.handpoint}
        hideOverlay={this.props.hideOverlay}
        maast={this.props.appState.maast}
        maastCustomer={this.state.maastCustomer}
        order={this.state.order}
        paymentView={this.state.paymentView}
        refund={Helper.isReturn(this.state.order)}
        showOverlay={this.props.showOverlay}
      />
    );
  };

  renderGiftcard = () => {
    return (
      <div className="paymentgiftcard">
        <h1 className="paymentheader">Gift Card Payment</h1>
        <div className="areaInputItem inputfields">
          {/* -------------------------------------------- */}
          <label htmlFor="giftcardnumber" className="giftcardnumber-label">
            Enter Gift Card Number
          </label>
          <input
            type="text"
            name="giftcardnumber"
            id="giftcardnumber"
            ref={this.giftCardNumberRef}
            className="giftcardnumber"
            autoComplete="off"
            autoFocus={true}
            maxLength={255}
            onChange={this.handleChange}
            onFocus={Helper.handleFocus}
            onBlur={this.handleCheckGiftCardBalance}
            value={this.state.giftcardnumber}
          />
        </div>
        <div className="areaInputItem inputfields">
          <label title="Amount Available on Gift Card" htmlFor="giftcardamount" className="giftcardamount-label">
            Amount on Card:{" "}
            <span data-testid="Gift Card Value" className="highlight">
              {numeral(this.state.giftcardvalue).format(Constants.CURRENCY_WITH_SYMBOL)}
            </span>
          </label>
        </div>
        <div className="areaInputItem inputfields">
          <label htmlFor="tenderamount" className="tenderamount-label">
            Amount to Apply
          </label>
          <input
            type="text"
            name="tenderamount"
            id="tenderamount"
            ref={this.tenderAmountRef}
            className="tenderamount"
            autoComplete="off"
            maxLength={13}
            onChange={this.handleChange}
            onFocus={Helper.handleFocus}
            onBlur={() => {}}
            value={this.state.tenderamount}
          />
        </div>

        {this.renderProcessButtons()}
      </div>
    );
  };

  renderCash = () => {
    let header = "Cash Payment";
    if (this.isReturn()) {
      header = "Cash Refund";
    }
    return (
      <div className="paymentcash">
        <h1 className="paymentheader">{header}</h1>
        {/* -------------------------------------------- */}
        <div className="areaInputItem inputfields">
          <label htmlFor="tenderamount" className="tenderamount-label">
            Amount:
          </label>
          <input
            type="text"
            name="tenderamount"
            id="tenderamount"
            data-testid="Cash Tender Amount"
            ref={this.tenderAmountRef}
            className="tenderamount"
            autoComplete="off"
            autoFocus={true}
            maxLength={13}
            onChange={this.handleChange}
            onFocus={Helper.handleFocus}
            onBlur={() => {}}
            value={this.state.tenderamount}
          />
        </div>

        {this.renderProcessButtons()}
      </div>
    );
  };

  renderCheck = () => {
    return (
      <div className="paymentcheck">
        <h1 className="paymentheader">Check Payment</h1>
        {/* -------------------------------------------- */}
        <div className="areaInputItem inputfields">
          <label htmlFor="confirmationcode" className="confirmationcode-label">
            Check Number
          </label>
          <input
            type="text"
            name="confirmationcode"
            id="confirmationcode"
            ref={this.checkNumberRef}
            className="confirmationcode"
            autoComplete="off"
            autoFocus={true}
            maxLength={32}
            onChange={this.handleChange}
            onFocus={Helper.handleFocus}
            onBlur={() => {}}
          />
        </div>
        <div className="areaInputItem inputfields">
          <label htmlFor="tenderamount" className="tenderamount-label">
            Amount:
          </label>
          <input
            type="text"
            name="tenderamount"
            id="tenderamount"
            className="tenderamount"
            autoComplete="off"
            maxLength={13}
            onChange={this.handleChange}
            onFocus={Helper.handleFocus}
            onBlur={() => {}}
            value={this.state.tenderamount}
          />
        </div>

        {this.renderProcessButtons()}
      </div>
    );
  };

  renderPaypal = () => {
    return (
      <div className="paymentpaypal">
        <h1 className="paymentheader">Paypal Payment</h1>
        {/* -------------------------------------------- */}
        <div className="areaInputItem inputfields">
          <label htmlFor="confirmationcode" className="confirmationcode-label">
            Paypal Confirmation #
          </label>
          <input
            type="text"
            name="confirmationcode"
            id="confirmationcode"
            ref={this.payPalConfRef}
            className="confirmationcode"
            autoComplete="off"
            autoFocus={true}
            maxLength={32}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={() => {}}
          />
        </div>
        <div className="areaInputItem inputfields">
          <label htmlFor="tenderamount" className="tenderamount-label">
            Amount:
          </label>
          <input
            type="text"
            name="tenderamount"
            id="tenderamount"
            className="tenderamount"
            autoComplete="off"
            maxLength={13}
            onChange={this.handleChange}
            onFocus={Helper.handleFocus}
            onBlur={() => {}}
            value={this.state.tenderamount}
          />
        </div>
        {this.renderProcessButtons()}
      </div>
    );
  };

  renderOther = () => {
    return (
      <div className="paymentother">
        <h1 className="paymentheader">Other Payment</h1>
        {/* -------------------------------------------- */}
        <div className="areaInputItem inputfields">
          <label htmlFor="responsecode" className="responsecode-label">
            Description
          </label>
          <input
            type="text"
            name="responsecode"
            id="responsecode"
            ref={this.otherDescriptionRef}
            className="responsecode"
            autoComplete="off"
            autoFocus={true}
            maxLength={32}
            onChange={this.handleChange}
            onFocus={Helper.handleFocus}
            onBlur={() => {}}
          />
        </div>
        <div className="areaInputItem inputfields">
          <label htmlFor="confirmationcode" className="confirmationcode-label">
            Confirmation Number
          </label>
          <input
            type="text"
            name="confirmationcode"
            id="confirmationcode"
            className="confirmationcode"
            autoComplete="off"
            maxLength={32}
            onChange={this.handleChange}
            onFocus={Helper.handleFocus}
            onBlur={() => {}}
          />
        </div>
        <div className="areaInputItem inputfields">
          <label htmlFor="tenderamount" className="tenderamount-label">
            Amount:
          </label>
          <input
            type="text"
            name="tenderamount"
            id="tenderamount"
            className="tenderamount"
            autoComplete="off"
            maxLength={13}
            onChange={this.handleChange}
            onFocus={Helper.handleFocus}
            onBlur={() => {}}
            value={this.state.tenderamount}
          />
        </div>

        {this.renderProcessButtons()}
      </div>
    );
  };

  renderStoreCredit = () => {
    let availableCredit = "";
    if (!Helper.isReturn(this.state.order)) {
      availableCredit = (
        <div className="areaInputItem inputfields">
          <label htmlFor="storecreditamount" className="storecreditamount-label">
            Available Credit: <span className="highlight">{numeral(this.state.storeCreditValue).format(Constants.CURRENCY_WITH_SYMBOL)}</span>
          </label>
        </div>
      );
    }
    return (
      <div className="paymentstorecredit">
        <h1 className="paymentheader">Store Credit</h1>
        {availableCredit}
        <div className="areaInputItem inputfields">
          <label htmlFor="tenderamount" className="tenderamount-label">
            Amount to Apply
          </label>
          <input
            type="text"
            name="tenderamount"
            id="tenderamount"
            className="tenderamount"
            autoComplete="off"
            autoFocus={true}
            maxLength={13}
            onChange={this.handleChange}
            onFocus={event => {
              Helper.handleFocus(event);
              event.target.select();
            }}
            onBlur={() => {}}
            value={this.state.tenderamount}
          />
        </div>

        {this.renderProcessButtons()}
      </div>
    );
  };

  // Handle non-credit payment types
  isReadyToSubmit = () => {
    // Field validate for each payment type (ALL invoice types)
    let amount = numeral(this.state.tenderamount).value() ?? 0.0;
    if (amount === 0) {
      return false;
    }
    // Gift cards can not be used unless you have a number
    if (this.state.paymentView === Constants.GIFTCARD && this.state.giftcardnumber === "") {
      return false;
    }
    // Confirmation code is required for certain payment types
    if (Helper.inList([Constants.CHECK, Constants.PAYPAL, Constants.OTHER], this.state.paymentView) && this.state.confirmationcode === "") {
      return false;
    }
    // The OTHER payment type requires a response code
    if (this.state.paymentView === Constants.OTHER && this.state.responsecode === "") {
      return false;
    }

    // Grab the overpayment flag
    const overpayment = this.isOverpayment();

    // **************
    // Trade-in rules
    // **************
    if (this.isTradeIn()) {
      const moneyOwedToCustomer = this.state.order.balancedue < 0;
      // When owing customer money on a TRADE-IN invoice, you must enter a negative amount
      // to balance the amount due to customer
      if (moneyOwedToCustomer && amount > 0) {
        return false;
      }
      // Cannot give the customer more money than they are owed on a TRADE-IN
      else if (moneyOwedToCustomer && overpayment) {
        return false;
      }
    }
    // ************
    // RETURN rules
    // ************
    // Returns normalize the positive/negative signs on the balance due so no need to check for that here
    else if (this.isReturn()) {
      // If this is a RETURN with a balance due TO the customer, then the amount cannot exceed the amount owed due for PayPal, Check, Other, Cash, Store Credit
      if (
        [Constants.CASH, Constants.CHECK, Constants.PAYPAL, Constants.STORECREDIT, Constants.OTHER].includes(this.state.paymentView) &&
        overpayment
      ) {
        return false;
      }
    }
    // **********
    // SALE rules
    // **********
    else {
      // If this is a SALE with a balance due FROM the customer, then the amount cannot be negative
      if (this.state.order.balancedue > 0 && amount < 0) {
        return false;
      }
      // If this is a SALE with a balance due FROM the customer, then the amount cannot exceed the balance due for GC, PayPal, Check, Other, Store Credit
      else if (
        [Constants.CHECK, Constants.GIFTCARD, Constants.PAYPAL, Constants.OTHER, Constants.STORECREDIT].includes(this.state.paymentView) &&
        overpayment
      ) {
        return false;
      }
      // Taking a deposit must be less than or equal to the balance due
      else if (
        this.state.paymentView === Constants.CASH &&
        Constants.DEPOSIT_ENABLED_ORDER_TYPES.includes(this.state.order.ordertype) &&
        overpayment
      ) {
        return false;
      }
      // Tender amount cannot exceed gift card available balance
      else if (this.state.paymentView === Constants.GIFTCARD && amount > numeral(this.state.giftcardvalue).value()) {
        return false;
      }
    }

    return true;
  };

  isOverpayment = () => {
    let amount = numeral(this.state.tenderamount).value() ?? 0.0;
    const absAmount = Math.abs(numeral(amount).value());
    const absBalanceDue = Math.abs(numeral(this.state.order.balancedue).value());
    const overpayment = absAmount - absBalanceDue > 0;
    return overpayment;
  };

  renderProcessButtons = () => {
    let processClassName = "payment-action-button green-button save-disabled";
    let title = "";
    if (this.isReadyToSubmit()) {
      processClassName = "payment-action-button green-button";
    } else {
      const overpayment = this.isOverpayment();
      if (this.state.paymentView === Constants.CASH && Constants.DEPOSIT_ENABLED_ORDER_TYPES.includes(this.state.order.ordertype) && overpayment) {
        title = "Deposit amount cannot exceed balance due";
      } else if (overpayment) {
        title = "Amount cannot exceed balance due";
      }
    }

    return (
      <div className="processButtons">
        <div className="payment-action-buttons cancelbutton ">
          <div className="payment-action-button-wrapper">
            <span className="payment-action-button-cell gridCenter">
              <span onClick={this.handleCancel} className="payment-action-button red-button" data-testid="Payment Cancel Button">
                <span className="cancel">Cancel</span>
              </span>
            </span>
          </div>
        </div>

        <div className="payment-action-buttons processbutton ">
          <div className="payment-action-button-wrapper">
            <span className="payment-action-button-cell gridCenter">
              <span onClick={this.handleProcess} className={processClassName} data-testid="Payment Process Button" title={title}>
                <span className="process">Process</span>
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderApproval = () => {
    let changedue = "";
    if (this.state.order.payments.length > 0) {
      const change = this.state.order.payments.filter(item => item.paymenttype === Constants.CHANGE);
      if (change.length > 0) {
        const changeamount = numeral(change[0].amount).multiply(-1).format(Constants.CURRENCY_WITH_SYMBOL);
        changedue = <h1 className="approvalHeader gridCenter balanceDue ">Change Due: {changeamount}</h1>;
      }
    }
    const title = Helper.isReturn(this.state.order) ? "Refund Successful!" : "Payment Successful!";
    return (
      <div className="payment-icon-container">
        <h1 className="approvalHeader gridCenter">{title}</h1>
        {changedue}
        <span className="gridCenter">
          <PaymentAcceptIcon />
        </span>
        <ButtonControls
          appState={this.props.appState}
          datatestidcancel="Pay Cancel"
          datatestidsave="Pay Save"
          enableActionButtons={true}
          followBreadcrumb={this.props.followBreadcrumb}
          handleCreateGenericOrder={() => {}}
          handleDelete={() => {}}
          handleEmail={this.handleEmail}
          handlePay={() => {}}
          handlePrint={this.handlePrint}
          handleRefund={() => {}}
          handleReturn={() => {}}
          handleSMS={this.handleSMS}
          order={this.state.order}
          orderstatus={this.state.order.orderstatus}
          ordertype={this.state.order ? this.state.order.ordertype : null}
          save={this.save}
          showOverlay={this.props.showOverlay}
          showSaveCancel={this.state.isNew}
          totalprice={null}
          //isReadyToSubmit in ButtonControls is for the Save button.
          //This component does not have a Save Button.
          //The isReadyToSubmit function in pay is for field validation.
          isReadyToSubmit={() => {
            return true;
          }}
        />
      </div>
    );
  };

  renderDecline = () => {
    return (
      <div className="payment-icon-container">
        <h1 className="DeclineHeader">Payment Failure!</h1>
        <span className="gridCenter">
          <PaymentDeclineIcon />
        </span>
        <div className="centerAligned declineMessage">{this.state.declineMessage ? this.state.declineMessage : ""}</div>
        <div className="payment-action-buttons cancelbutton ">
          <div className="oneButtonCancel">
            <span className="payment-action-button-cell gridCenter">
              <span onClick={this.handleCancel} className="payment-action-button red-button">
                <span className="cancel">Cancel</span>
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  handleChange = event => {
    if (event.target.id === "tenderamount") {
      // Prevent duplicate decimal points
      if (event.target.value.split(".").length > 2) {
        return;
      }
      // Allow only numeric characters, decimal points, and negative signs
      if (!/^-?\d*\.?\d*$/.test(event.target.value)) {
        return;
      }
      this.setState({ tenderamount: event.target.value });
    } else if (event.target.id === "confirmationcode") {
      this.setState({ confirmationcode: event.target.value });
    } else if (event.target.id === "responsecode") {
      this.setState({ responsecode: event.target.value });
    } else if (event.target.id === "giftcardnumber") {
      this.setState({ giftcardnumber: event.target.value });
    }
  };

  handleClick = view => {
    if (!this.enablePayment(view)) {
      return;
    }
    let tenderAmount = "";
    // Prepopulate the tender amount if this is not cash
    if (view !== Constants.CASH) {
      tenderAmount = numeral(this.state.order.balancedue).format(Constants.CURRENCY);
    }
    // If this is a return, then prepopulate the tender amount based on the original payment method.
    // Add up all the cash-like payments (minus any amount already refunded against that original payment) to determine the total available amount to refund as a cash-like tender.
    // Compare it to the balance due to determine the maximum amount that can be refunded.
    // Note: Return a negative value for the tender amount input field.
    if (this.isReturn()) {
      const totalCashlikePayments = this.state.order?.parentPayments
        ?.filter(payment => payment.paymenttype !== Constants.CREDIT)
        .reduce((acc, item) => {
          return numeral(acc).add(numeral(item.amount).value()).subtract(numeral(item.amountRefunded).value()).value();
        }, 0);
      const balancedue = Math.abs(numeral(this.state.order.balancedue).value());
      tenderAmount = numeral(Math.min(balancedue, totalCashlikePayments)).multiply(-1).format(Constants.CURRENCY);
    }
    // For cash, if this is an invoice with an amount owed to customer (ex.- trade-in)
    // then use the balance due to customer
    else if (view === Constants.CASH && !this.isReturn() && this.state.order.balancedue < 0) {
      tenderAmount = numeral(this.state.order.balancedue).format(Constants.CURRENCY);
    }
    // If this is a credit refund, then use the credit refund view
    if (this.isReturn() && view === Constants.CREDIT) {
      view = Constants.CREDIT_REFUND;
    }
    // If using store credit, then take the lesser of the amount due and credit available
    if (view === Constants.STORECREDIT) {
      tenderAmount = numeral(Math.min(numeral(this.state.order.balancedue).value(), numeral(this.state.storeCreditValue).value())).format(
        Constants.CURRENCY
      );
    }

    this.setState({
      paymentView: view,
      tenderamount: tenderAmount,
      confirmationcode: "",
      responsecode: "",
      giftcardnumber: "",
      giftcardvalue: 0.0,
    });
  };

  handleCancel = authResponse => {
    if (authResponse?.status) {
      // Append the authorization message to the order
      this.setState(prevState => ({
        paymentView: Constants.PAYMENT_BUTTONS,
        tenderamount: "",
        order: { ...prevState.order, authorizations: [authResponse, ...prevState.order.authorizations] },
      }));
    } else {
      this.setState({
        paymentView: Constants.PAYMENT_BUTTONS,
        tenderamount: "",
      });
    }
  };

  handleProcess = () => {
    if (this.isReadyToSubmit()) {
      if (this.postingPayment) {
        return;
      }
      this.postingPayment = true;
      let tenderAmount = numeral(this.state.tenderamount);
      const balanceDue = numeral(this.state.order.balancedue);

      // Return transactions deal in negative amounts
      //    so make sure the tender amount is negative
      if (this.isReturn() && tenderAmount.value() > 0) {
        tenderAmount = tenderAmount.multiply(-1);
      }

      // Return transactions cannot be for an amount "larger" than
      //    the refund due to the customer
      if (this.isReturn() && tenderAmount.value() < balanceDue.value()) {
        this.setState({ error: null, downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Cannot refund more than the balance due to the customer.",
            callback: () => {
              this.postingPayment = false;
            },
          });
        });
        return;
      }

      const payment = {
        amount: numeral(tenderAmount).value(),
        paymenttype: this.state.paymentView,
        confirmationcode: this.state.confirmationcode,
        responsecode: this.state.responsecode,
      };
      this.postPayment(payment);
    }
  };

  handleRefreshOrder = () => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_ORDERS;
    const params = { orderuuid: this.state.order.orderuuid };
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Update the UI based on the updated order
        const order = Helper.formatOrder(response.body);
        this.handleCreditApproval(order);
      } else {
        this.setState({ error: "GET", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the order.",
          });
        });
      }
    });
  };

  handleCreditApproval = order => {
    const balancedue = numeral(order.balancedue).value();
    if (!Helper.isReturn(order) && balancedue <= 0) {
      this.setState({ order: order, paymentView: Constants.APPROVED, downloading: false }, () => {
        this.props.hideOverlay();
      });
      // REFUND transaction with no balance due to customer
    } else if (this.isReturn() && balancedue >= 0) {
      this.setState({ order: order, paymentView: Constants.APPROVED, downloading: false }, () => {
        this.props.hideOverlay();
      });
    } else {
      this.setState({ order: order, paymentView: Constants.PAYMENT_BUTTONS, downloading: false }, () => {
        this.props.hideOverlay();
      });
    }
  };

  handleDecline = (authResponse, message) => {
    this.setState(
      prevState => ({
        paymentView: Constants.DECLINED,
        declineMessage: message,
        order: { ...prevState.order, authorizations: [authResponse, ...prevState.order.authorizations] },
      }),
      () => {
        this.props.hideOverlay();
      }
    );
  };

  handleCheckGiftCardBalance = () => {
    if (this.state.giftcardnumber) {
      this.props.getPayment(
        this.state.giftcardnumber,
        giftcard => {
          const balance = numeral(giftcard.balance);
          let tenderAmount = numeral(this.state.tenderamount);
          if (balance.value() < tenderAmount.value()) {
            tenderAmount = balance;
          }
          this.setState(
            {
              tenderamount: tenderAmount.format(Constants.CURRENCY),
              giftcardvalue: balance.format(Constants.CURRENCY_WITH_SYMBOL),
              error: null,
              downloading: false,
            },
            () => {
              this.props.hideOverlay();
              if (this.tenderAmountRef.current) {
                this.tenderAmountRef.current.focus();
                this.tenderAmountRef.current.select();
              }
            }
          );
        },
        message => {
          // gift card not found
          this.setState(
            {
              giftcardvalue: "0.00",
              error: null,
              downloading: false,
            },
            () => {
              this.props.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: message,
              });
            }
          );
        }
      );
    }
  };

  enablePayment = paymentType => {
    // If the neither credit processor is enabled, disable credit button
    if (
      paymentType === Constants.CREDIT &&
      Helper.isFalse(this.props.appState.handpoint.enabled) &&
      Helper.isFalse(this.props.appState.valor.enabled) &&
      Helper.isFalse(this.props.appState.maast.enabled)
    ) {
      return false;
    }

    // ********************************************************
    // The following code applies only to TRADE-IN transactions
    // ********************************************************
    // If a SALE transaction has a balance due to the customer (e.g. trade-in), then disable credit and vault buttons
    if (this.isTradeIn()) {
      if ([Constants.CREDIT, Constants.VAULT, Constants.GIFTCARD].includes(paymentType)) {
        return false;
      }
      return true;
    }
    // ******************************************************
    // The following code applies only to RETURN transactions
    // ******************************************************
    else if (this.isReturn()) {
      // NOTE: VAULT is not a valid payment type for returns, as vault payments will appear as a "credit" payment

      // For return invoices, disable the buttons until we've evaluated the parent payments
      if (!this.state.order.parentPayments) {
        return false;
      }

      // Gift card is not (YET) an option for returns
      if (paymentType === Constants.GIFTCARD) {
        return false;
      }

      // Store credit is always an option for returns
      if (paymentType === Constants.STORECREDIT) {
        return true;
      }

      // Credit is enable if the original sale (parent) invoice contained a credit payment
      if (paymentType === Constants.CREDIT) {
        if (this.state.order.parentPayments.filter(item => item.paymenttype === Constants.CREDIT).length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        // Cash-like payments are enable if the original sale (parent) invoice contained a non-credit payment
        if (this.state.order.parentPayments.filter(item => item.paymenttype !== Constants.CREDIT).length > 0) {
          return true;
        } else {
          return false;
        }
      }
    }

    // ****************************************************
    // The following code applies only to SALE transactions
    // ****************************************************
    else {
      // Only enable credit if merchant settings are fully present for at least one processor
      if (paymentType === Constants.CREDIT) {
        // Check settings for each processor
        const maastEnabled = this.props.appState.maast.merchant_id && this.props.appState.maast.mode;
        const handpointEnabled = Helper.isTrue(this.props.appState.handpoint.enabled);
        const valorEnabled = Helper.isTrue(this.props.appState.valor.enabled);

        // If neither processor is enabled, disable credit button
        if (!maastEnabled && !handpointEnabled && !valorEnabled) {
          return false;
        }
      }
      return true;
    }
  };

  setCardID = cardid => {
    this.setState({ cardid: cardid });
  };

  postPayment = payment => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    // Build the request
    payment = {
      ...payment,
      action: "post-payment",
      orderuuid: this.state.order.orderuuid,
      contactuuid: this.state.order.contactuuid,
    };

    // If this is a gift card, then include the gift card number
    if (payment.paymenttype === Constants.GIFTCARD) {
      payment = { ...payment, giftCardNumber: this.state.giftcardnumber };
    }

    //set up to make database call
    const url = Constants.URL_PAYMENTS;
    Helper.postData(url, payment).then(response => {
      if (response.status === 200 && response.body) {
        // The entire order comes back after a payment
        const order = Helper.formatOrder(response.body);
        const balancedue = order.balancedue;

        // Define a DRY local function to show the payment approval message/view
        const showPaymentApproval = order => {
          this.setState({ order: order, paymentView: Constants.APPROVED, downloading: false }, () => {
            // We may received a message indicating the payment posted but a subsequent action failed
            // such as updating a customer's store credit balance or a gift card balance
            if (order.message) {
              this.props.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: order.message,
              });
            } else {
              this.props.hideOverlay();
            }
          });
        };

        const showPaymentMethods = order => {
          this.setState({ order: order, paymentView: Constants.PAYMENT_BUTTONS, downloading: false }, () => {
            this.props.hideOverlay();
          });
        };

        // *****************
        // TRADE-IN invoices
        // *****************
        if (this.isTradeIn()) {
          // Check for trade-in invoice with no balance due to customer
          if (balancedue >= 0) {
            showPaymentApproval(order);
          }
          // More money due to customer
          else {
            showPaymentMethods(order);
          }
        }
        // ************
        // SALE INVOICE
        // ************
        else if (!this.isReturn()) {
          // SALE invoices with a change due to customer
          if (balancedue <= 0) {
            showPaymentApproval(order);
          }
          // Customer owes more money
          else {
            showPaymentMethods(order);
          }
        }
        // **************
        // RETURN invoice
        // **************
        else {
          // REFUND transaction with no balance due to customer
          if (balancedue >= 0) {
            showPaymentApproval(order);
          } else {
            showPaymentMethods(order);
          }
        }
      } else if (response.status === 409 || response.status === 503) {
        this.setState({ error: null, downloading: false }, () => {
          const message = response.body?.message ? response.body.message : "Unable to record payment";
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: message,
          });
        });
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error adding the item.",
          });
        });
      }
      this.postingPayment = false;
    });
  };
}
export default Pay;
