import React from "react";

import { faSpinner, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faFaceFrown } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Constants from "./Constants";
import * as Helper from "./Helper";
import numeral from "numeral";

class ClientPage extends React.Component {
  constructor(props) {
    super(props);

    this.mfaCodeInput = React.createRef();

    let page = Constants.PAGE_TERMS;
    if (props.requestuuid) {
      page = Constants.PAGE_VAULT;
    } else if (props.orderuuid) {
      page = Constants.PAGE_CHECKOUT;
    } else if (props.prospectuuid || (props.campaignuuid && props.preview)) {
      page = Constants.PAGE_PROSPECT;
    } else if (props.campaignuuid) {
      page = Constants.PAGE_CAMPAIGN;
    }

    this.state = {
      additionalDataValues: {},
      campaign: null,
      client: null,
      customer: null,
      downloading: false,
      email: "",
      firstname: "",
      lastname: "",
      mobilephone: "",
      page: page,
      preview: props.preview ?? false,
      prospect: null,
      mfaCode: "",
      mfaStep: "Email Me the Verification Code",
      requiredAdditionalDataValues: [],
      step: Constants.WORKFLOW_STATUS_NEW,
      subdomain: props.subdomain,
    };
  }

  componentDidMount() {
    // If this is a vault request, load the customer details
    if (this.props.requestuuid) {
      // Load the recaptcha script
      this.renderRecaptchaScript();

      // Show the progress overlay and retrieve the request details
      this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Retrieving request details..." });
      const data = {
        requestuuid: this.props.requestuuid,
      };
      Helper.getData(Constants.URL_COMPANIES, data).then(response => {
        this.props.hideOverlay();
        if (response.status === 200) {
          this.setState({ customer: response.body, client: response.body.client });
        } else {
          this.setState({
            error: response.status,
            page: Constants.PAGE_ERROR,
          });
        }
      });
    } else if (this.props.orderuuid) {
      // Load the recaptcha script
      this.renderRecaptchaScript();

      // Show the progress overlay and retrieve the order details
      this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Retrieving order details..." });
      this.getOrder(this.props.orderuuid, Constants.PAY, response => {
        this.props.hideOverlay();
        if (response.status === 200) {
          const collectApiKey = response.body.client?.settings?.find(
            s => s.category === Constants.SETTINGS_CATEGORY_NMI && s.description === Constants.SETTINGS_COLLECT_API_KEY
          )?.value;
          this.props.setCollectApiKey(collectApiKey);
          this.setState({ client: response.body.client });
        } else {
          this.setState({
            error: response.status,
            page: Constants.PAGE_ERROR,
          });
        }
      });
    } else if (this.props.prospectuuid) {
      const data = {
        marketingprospectuuid: this.props.prospectuuid,
      };
      Helper.getData(Constants.URL_CAMPAIGNS, data).then(response => {
        this.props.hideOverlay();
        if (response.status === 200 && response.body && response.body?.campaign) {
          if (response.body?.campaign?.campaignstatus !== Constants.CAMPAIGN_STATUS_PUBLISHED) {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "The requested form is not currently available.\nPlease check with the store for more information.",
              callback: () => {
                this.setState({ step: Constants.WORKFLOW_STATUS_FAILED });
              },
            });
          } else {
            const fields = Helper.getAdditionalDataPromptFieldNames(response.body.campaign?.additionaldataprompt ?? "");
            const fieldDefinitions = Helper.getAdditionalDataPromptFields(response.body.campaign?.additionaldataprompt ?? "");
            const required = fieldDefinitions.filter(field => field.required).map(field => field.fieldName);
            const prospectAdditionalData = JSON.parse(response.body.additionaldata || "{}");
            const additionalDataValues = this.state.additionalDataValues;
            fields.forEach(field => {
              additionalDataValues[field] = prospectAdditionalData[field] || "";
              if (!prospectAdditionalData[field]) {
                const definition = fieldDefinitions.find(f => f.fieldName === field);
                if (definition?.fieldType?.toLowerCase() === "checkbox") {
                  additionalDataValues[field] = false;
                }
              }
            });
            // Get the card_number from the tokenization response
            if (response.body?.tokenizationresponse) {
              const tokens = JSON.parse(response.body.tokenizationresponse);
              if (tokens?.card_number) {
                response.body.card_number = tokens.card_number;
              }
            }
            // If the campaign does not require an agreement, set the agreed flag to true
            // If the prospect has already agreed, set the agreed flag to true
            if (!response.body?.campaign?.agreementtext || response.body?.agreedatetime) {
              response.body.agreed = true;
            } else {
              response.body.agreed = false;
            }
            this.setState(
              {
                prospect: response.body,
                additionalDataValues: additionalDataValues,
                requiredAdditionalDataValues: required,
                campaign: response.body.campaign,
                client: response.body.client,
              },
              () => {
                // Load the recaptcha script
                if (this.state.campaign?.requirecard) {
                  this.renderRecaptchaScript();
                }
              }
            );
          }
        } else {
          this.setState({
            error: response.status,
            page: Constants.PAGE_ERROR,
          });
        }
      });
    } else if (this.props.campaignuuid) {
      const data = {
        campaignuuid: this.props.campaignuuid,
      };
      Helper.getData(Constants.URL_CAMPAIGNS, data).then(response => {
        this.props.hideOverlay();
        if (response.status === 200) {
          const campaign = response.body;
          if (!this.props.preview && campaign?.campaignstatus !== Constants.CAMPAIGN_STATUS_PUBLISHED) {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "The requested form is not currently available.\nPlease check with the store for more information.",
              callback: () => {
                this.setState({ step: Constants.WORKFLOW_STATUS_FAILED });
              },
            });
          } else {
            let prospect = this.state.prospect;
            if (this.props.preview) {
              prospect = { agreed: false, prospectstatus: Constants.PROSPECT_STATUS_NEW };
            }
            this.setState({ campaign: campaign, prospect: prospect, client: campaign.client });
          }
        } else {
          this.setState({
            error: response.status,
            page: Constants.PAGE_ERROR,
          });
        }
      });
    } else {
      // Load the client's logo and details based on the subdomain, if provided
      Helper.getData(Constants.URL_CLIENTS, { subdomain: this.state.subdomain }, null, [], Constants.PREEMPT_GET_CLIENT_PAGE).then(response => {
        if (response.status === 200) {
          this.setState({ client: response.body });
        } else if (response.status === 400) {
          this.setState({
            client: { error: "Invalid request. Did you copy the entire link?" },
          });
        } else if (response.status === 404) {
          this.setState({
            client: { error: "The requested store was not found. Did you copy the entire link?" },
          });
        } else if (response.status === 503) {
          this.setState({
            client: { error: "The request timed out. Please check your internet connection." },
          });
        } else {
          this.setState({
            client: { error: "An unexpected error occurred. Please try again later or contact the store." },
          });
        }
      });
    }
  }

  renderRecaptchaScript() {
    const script = document.createElement("script");
    script.id = "chrecaptcha";
    script.async = true;
    script.src = "https://www.google.com/recaptcha/api.js?render=6LfKkocpAAAAADZU8RE6ZR0A2qME3z3I2ZqrRMOW";
    document.getElementById("chrecaptchaElement").appendChild(script);
  }

  componentWillUnmount() {
    // Remove the recaptcha script
    const script = document.getElementById("chrecaptcha");
    if (script) {
      script.remove();
    }
    // Remove class=grecaptcha-badge elements
    const elements = document.getElementsByClassName("grecaptcha-badge");
    if (elements.length > 0) {
      if (elements[0].parentNode.id !== "chrecaptchaElement" && elements[0].parentNode.tagName !== "html") {
        elements[0].parentNode.parentNode.removeChild(elements[0].parentNode);
      }
    }
  }

  getStoreAttributes = () => {
    const storeName = this.state.client?.settings?.find(
      s => s.category === Constants.SETTINGS_CATEGORY_CLIENT && s.description === Constants.SETTINGS_NAME
    )?.value;
    const address = this.state.client?.settings?.find(
      s => s.category === Constants.SETTINGS_CATEGORY_CLIENT && s.description === Constants.SETTINGS_STREET_ADDRESS
    )?.value;
    const cityStateZip = this.state.client?.settings?.find(
      s => s.category === Constants.SETTINGS_CATEGORY_CLIENT && s.description === Constants.SETTINGS_CITY_ST_ZIP
    )?.value;
    const phone = this.state.client?.settings?.find(
      s => s.category === Constants.SETTINGS_CATEGORY_CLIENT && s.description === Constants.SETTINGS_PHONE
    )?.value;
    const website = this.state.client?.settings?.find(
      s => s.category === Constants.SETTINGS_CATEGORY_CLIENT && s.description === Constants.SETTINGS_WEBSITE
    )?.value;
    const legalName = this.state.client?.settings?.find(
      s => s.category === Constants.SETTINGS_CATEGORY_TWILIO && s.description === Constants.SETTINGS_TWILIO_LEGAL_NAME
    )?.value;
    const tenDLC = this.state.client?.settings?.find(
      s => s.category === Constants.SETTINGS_CATEGORY_TWILIO && s.description === Constants.SETTINGS_TWILIO_PHONE_NUMBER
    )?.value;
    const email = this.state.client?.settings?.find(
      s => s.category === Constants.SETTINGS_CATEGORY_CLIENT && s.description === Constants.SETTINGS_EMAIL
    )?.value;
    const logo = this.state.client?.settings?.find(
      s => s.category === Constants.SETTINGS_CATEGORY_CLIENT && s.description === Constants.SETTINGS_LOGO
    )?.value;
    return {
      storeName: storeName,
      address: address,
      cityStateZip: cityStateZip,
      phone: phone,
      website: website,
      legalName: legalName,
      tenDLC: tenDLC,
      email: email,
      logo: logo,
    };
  };

  render() {
    if (!this.state.client?.name && !this.state.client?.error && !this.state.error && !this.state.prospect) {
      return this.renderLoadingContent();
    } else if (this.state.client?.error) {
      document.title = "Error";
      return <div>{this.state.client.error}</div>;
    } else if (this.state.error) {
      return this.renderError();
    } else {
      const store = this.getStoreAttributes();
      document.title = store.storeName ?? "";

      store.name = store.storeName;
      if (store.legalName && store.legalName !== store.storeName) {
        store.name = `${store.legalName} d.b.a. "${store.storeName}"`;
      }

      let content;
      if (this.state.page === Constants.PAGE_PRIVACY) {
        content = this.renderPrivacy(store);
      } else if (this.state.page === Constants.PAGE_TERMS) {
        content = this.renderTerms(store);
      } else if (this.state.page === Constants.PAGE_VAULT) {
        content = this.renderVault(store);
      } else if (this.state.page === Constants.PAGE_CHECKOUT) {
        content = this.renderCheckout(store);
      } else if (this.state.page === Constants.PAGE_CAMPAIGN) {
        content = this.renderCampaignEmailRequest(store);
      } else if (this.state.page === Constants.PAGE_PROSPECT) {
        content = this.renderCampaignFormForProspect(store);
      } else {
        content = this.renderTerms(store);
      }

      let vaultCard = "";
      if (this.props.requestuuid) {
        vaultCard = (
          <div
            className={"navLink " + (this.state.page === Constants.PAGE_VAULT ? "selectedClientPageMenu" : "")}
            title="Payment Vault"
            onClick={() => {
              this.setState({ page: Constants.PAGE_VAULT });
            }}
          >
            Payment Vault
          </div>
        );
      }

      let checkoutLink = "";
      if (this.props.orderuuid) {
        checkoutLink = (
          <div
            className={"navLink " + (this.state.page === Constants.PAGE_CHECKOUT ? "selectedClientPageMenu" : "")}
            title="Checkout"
            onClick={() => {
              this.setState({ page: Constants.PAGE_CHECKOUT });
            }}
          >
            Checkout
          </div>
        );
      }

      let campaign = "";
      if (this.props.campaignuuid || this.state.preview) {
        campaign = (
          <div
            className={"navLink " + (this.state.page === Constants.PAGE_CAMPAIGN ? "selectedClientPageMenu" : "")}
            title="Invitation"
            onClick={() => {
              this.setState({ page: Constants.PAGE_CAMPAIGN });
            }}
          >
            Invitation
          </div>
        );
      }

      let prospect = "";
      if (this.props.prospectuuid || this.state.preview) {
        prospect = (
          <div
            className={"navLink " + (this.state.page === Constants.PAGE_PROSPECT ? "selectedClientPageMenu" : "")}
            title="Request Form"
            onClick={() => {
              this.setState({ page: Constants.PAGE_PROSPECT });
            }}
          >
            Request Form
          </div>
        );
      }

      return (
        <div className="app-body">
          <div className="clientPage">
            <div className="logo">
              {this.renderAreaClientLogo(store.logo, store.name)}
              <div>
                {campaign}
                {prospect}
                {vaultCard}
                {checkoutLink}
                <div
                  className={"navLink " + (this.state.page === Constants.PAGE_TERMS ? "selectedClientPageMenu" : "")}
                  title="Terms and Conditions"
                  onClick={() => {
                    this.setState({ page: Constants.PAGE_TERMS });
                  }}
                >
                  SMS Terms and Conditions
                </div>
                <div
                  className={"navLink " + (this.state.page === Constants.PAGE_PRIVACY ? "selectedClientPageMenu" : "")}
                  title="Privacy Policy"
                  onClick={() => {
                    this.setState({ page: Constants.PAGE_PRIVACY });
                  }}
                >
                  SMS Privacy Policy
                </div>
              </div>
            </div>
            <div className="clientPageWrapper">
              <div className="storeName">{store.name}</div>
              <div>{store.address}</div>
              <div>{store.cityStateZip}</div>
              <div>{store.phone}</div>
              <div>{store.website}</div>
              {content}
            </div>
          </div>
        </div>
      );
    }
  }

  renderAreaClientLogo = (src, name) => {
    // TODO: Replace with a server setting
    src = "https://p4529ijsv5.execute-api.us-east-1.amazonaws.com/Stage/uploads?uploaduuid=" + src;
    name += " Logo";
    return <img src={src} alt={name} title="Store Logo" />;
  };

  renderError = () => {
    document.title = "Error";

    return (
      <div className="app-body">
        <div className="clientPage">
          <div className="clientPageWrapper centerAligned span2">
            <FontAwesomeIcon icon={faFaceFrown} className="oopsIcon" />
            &nbsp;&nbsp;&nbsp;
            <span className="storeName">Uh-oh! We're having trouble fulfilling your request.</span>
            <h3>Please try again later or reach out to the store directly for assistance.</h3>
          </div>
        </div>
      </div>
    );
  };

  renderPrivacy = store => {
    return (
      <React.Fragment>
        <h3>Privacy Policy for SMS Messaging</h3>
        <div>
          <h4>Description of SMS Service</h4>
          <p>
            Welcome to {store.name} ("we," "us," or "our"). Our SMS service enables users to receive Customer Care and Account Notifications related
            to your orders and billing. By opting in, users consent to receive SMS messages from us.
          </p>
          <h4>Types of Personal Information Collected</h4>
          <p>
            We collect your mobile phone number. During store interactions, we will collect account information, such as name, email, address, and
            phone number(s). Your account information will be matched with your mobile number to provide you with SMS service related to your account.
          </p>
          <h4>Methods of Personal Data Collection</h4>
          <p>
            During the opt-in process, we collect your mobile number. During store interactions, we will request and collect personal information such
            as name, email, address, and phone number(s).
          </p>
          <h4>How We Use Personal Data</h4>
          <p>We use the collected information for the following purposes:</p>
          <ul>
            <li>To provide Customer Care and Account Notifications.</li>
            <li>To comply with legal obligations.</li>
          </ul>
          <h4>Storage, Maintenance, and Protection</h4>
          <p>
            We take measures to securely store and protect the personal information we collect. This includes encryption, access controls, and regular
            security assessments.
          </p>
          <h4>Sharing Personal Data with Third Parties</h4>
          <p>We do not share personal data with third parties, except:</p>
          <ul>
            <li>As required by law or legal process.</li>
            <li>With service providers who assist in delivering our SMS messages.</li>
            <li>With our payment processor(s) to facilitate fulfillment of any customer financial obligations.</li>
          </ul>
          <h4>Options for Users to Manage Personal Information</h4>
          <p>Users have the following options to manage their personal information:</p>
          <ul>
            <li>
              Opt-out: Users can opt-out of receiving SMS messages by replying with "STOP" to the received SMS or to{" "}
              {Helper.formatPhoneNumber(store.tenDLC, true)}.{" "}
            </li>
            <li>Correction/Verification: Users can contact us at {store.phone} to correct or verify their personal information. </li>
            <li>Removal: Users can request the removal of their personal information by contacting us at {store.phone}.</li>
          </ul>
          <h4>Changes to This Privacy Policy</h4>
          <p>
            We reserve the right to update our SMS Messaging Privacy Policy. We will notify users of any material changes through SMS or other
            reasonable means.
          </p>
          <h4>Contact Us</h4>
          <p>If you have any questions or concerns about this SMS Messaging Privacy Policy, please contact us at {store.email}.</p>
        </div>
      </React.Fragment>
    );
  };

  renderTerms = store => {
    const tenDLC = Helper.formatPhoneNumber(store.tenDLC, true);
    return (
      <React.Fragment>
        <h3>Terms of Service for SMS Messaging</h3>
        <div>
          <p>
            Your mobile number will be used for Customer Care and Account Notifications. Customer Care messages include technical and billing support
            messaging, as well as updates regarding changes in order status. Account Notifications include messages confirming scheduled payments and
            billing changes.
          </p>
          <p>
            You can cancel the SMS service at any time. Just text "STOP" to {tenDLC}. After you send the SMS message "STOP" to us, you will no longer
            receive SMS messages from us. If you want to receive SMS messages again, just text START to {tenDLC} and we will resume sending SMS
            messages to you.
          </p>
          <p>
            If you are experiencing issues with the messaging program you can reply with the keyword "HELP" for more assistance, or you can get help
            directly at {store.phone}.
          </p>
          <p>Carriers are not liable for delayed or undelivered messages.</p>
          <p>
            As always, message and data rates may apply for any messages sent to you from us and to us from you. The number of messages you will
            receive is based on your account inquiries and transactions. If you have any questions about your text plan or data plan, it is best to
            contact your wireless provider.
          </p>
          <p>
            If you have any questions regarding privacy, please read our{" "}
            <span
              className="navLink"
              onClick={() => {
                this.setState({ page: Constants.PAGE_PRIVACY });
              }}
            >
              privacy policy
            </span>{" "}
            or contact us at {store.email} or {store.phone}.
          </p>
        </div>
      </React.Fragment>
    );
  };

  renderVault = store => {
    if (this.state.error) {
      return <h3>Missing or expired request.</h3>;
    } else if (!this.state.customer) {
      return this.renderLoadingContent();
    }
    const storename = this.state.client?.settings?.find(
      s => s.category === Constants.SETTINGS_CATEGORY_CLIENT && s.description === Constants.SETTINGS_NAME
    )?.value;
    let contact = this.state.customer?.contacts[0];
    if (this.state.customer?.vaultrequest?.contactuuid) {
      const c = this.state.customer?.contacts.find(c => c.contactuuid === this.state.customer?.vaultrequest?.contactuuid);
      if (c) {
        contact = c;
      }
    }
    // Display a thank you message if the payment method has been processed/vaulted
    if (this.state.step === Constants.WORKFLOW_STATUS_PROCESSED) {
      return (
        <React.Fragment>
          <h3>Thank you!</h3>
          <div>
            <p>Your payment information has been successfully vaulted with {storename}.</p>
            <p>
              You can now make purchases without re-entering your payment details. If you have any questions or need assistance, please contact us at{" "}
              {store.phone} or {store.email}.
            </p>
          </div>
        </React.Fragment>
      );
    } else if (this.state.step === Constants.WORKFLOW_STATUS_PROCESSING) {
      return (
        <React.Fragment>
          <h3>Processing...</h3>
          <div>
            <p>Your request is being processed.</p>
          </div>
        </React.Fragment>
      );
    } else {
      let sendMobile = "";
      let sendOther = "";
      let sendEmailButton = "";
      if (this.state.step === Constants.RECAPTCHA_FAILED) {
        // sendMobile = (
        //   <span className="action-button green-button" onClick={this.handleSendMfa(Constants.MFA_MOBILE)}>
        //     Send
        //   </span>
        // );
        // sendOther = (
        //   <span className="action-button green-button" onClick={this.handleSendMfa(Constants.MFA_OTHER)}>
        //     Send
        //   </span>
        // );
        if (this.state.mfaStep !== "Email Me the Verification Code") {
          sendEmailButton = <span>{this.state.mfaStep}</span>;
        } else {
          sendEmailButton = (
            <span
              title="Email Me the Verification Code"
              className={
                "action-button action-button-inline green-button" + (this.state.mfaStep !== "Email Me the Verification Code" ? " save-disabled" : "")
              }
              onClick={() => {
                this.handleSendMfa(Constants.MFA_EMAIL);
              }}
            >
              {this.state.mfaStep}
            </span>
          );
        }
      }
      let addButtonClasses = "action-button green-button";
      let addButtonHandler = () => {
        const gateway = this.state.client?.settings?.find(
          s => s.category === Constants.SETTINGS_CATEGORY_CLIENT && s.description === Constants.SETTINGS_PAYMENT_GATEWAY
        )?.value;
        let loading = null;
        if (gateway === Constants.MAAST) {
          loading = "Contacting payment gateway...";
        }
        this.props.showOverlay({
          type: Constants.OVERLAY_TOKENIZE,
          text: "Enter payment information",
          tokenize: true,
          recaptcha: true,
          vault: true,
          recaptchaFailureCallback: this.handleRecaptchaFailure,
          mfaCode: this.state.mfaCode,
          mfaRequestuuid: this.state.mfaRequestuuid,
          loading: loading,
          paymentGateway: gateway,
          callback: this.handlePostStoredPayment,
        });
      };
      let addButtonLabel = "Enter Payment Information";
      if (this.state.step === Constants.RECAPTCHA_FAILED) {
        addButtonLabel = "Continue";
        if (this.state.mfaCode?.length !== 6) {
          addButtonClasses += " save-disabled";
          addButtonHandler = () => {};
        }
      }
      let addButton = (
        <div className="controls-one-button control-buttons gridCenter">
          <span className={addButtonClasses} onClick={addButtonHandler} title="Add Button">
            {addButtonLabel}
          </span>
        </div>
      );
      const name = (
        <React.Fragment>
          {contact.firstname} {contact.lastname}
          <br />
        </React.Fragment>
      );
      const email = contact.email ? (
        <React.Fragment>
          {contact.email}
          <br />
        </React.Fragment>
      ) : (
        ""
      );
      const mobilephone = contact.mobilephone ? (
        <React.Fragment>
          {contact.mobilephone} (mobile) {sendMobile}
          <br />
        </React.Fragment>
      ) : (
        ""
      );
      const otherphone = contact.otherphone ? (
        <React.Fragment>
          {contact.otherphone} (other) {sendOther}
          <br />
        </React.Fragment>
      ) : (
        ""
      );
      const addr1 = contact.address1 ? (
        <React.Fragment>
          {contact.address1}
          <br />
        </React.Fragment>
      ) : (
        ""
      );
      const addr2 = contact.address2 ? (
        <React.Fragment>
          {contact.address2}
          <br />
        </React.Fragment>
      ) : (
        ""
      );
      const cityStateZip = contact.city ? (
        <React.Fragment>
          {contact.city}, {contact.state} {contact.zip}
          <br />
        </React.Fragment>
      ) : (
        ""
      );
      let mfaVerification = "";
      if (this.state.step === Constants.RECAPTCHA_FAILED) {
        mfaVerification = (
          <React.Fragment>
            <p className="highlight">
              <FontAwesomeIcon icon={faTriangleExclamation} className="highlight" /> To verify your identity and protect your payment information, we
              will send you a one-time, six-digit verification code. Enter the code in the box below when you receive it and click Continue.
            </p>
            <p data-testid="MFA Code Container for Vault" title="MFA Code Container for Vault">
              Verification Code:{" "}
              <input
                type="text"
                id="mfaCode"
                data-testid="MFA Code for Vault"
                title="MFA Code for Vault"
                name="mfaCode"
                ref={this.mfaCodeInput}
                maxLength={6}
                onChange={this.handleChangeMfaCode}
                value={this.state.mfaCode}
                autoFocus
              />{" "}
              {sendEmailButton}
            </p>
            {/* <p>
              To verify your identity and protect your payment information, we will send you a one-time verification code. Please enter the code in
              the box below to proceed. We can send the code to your mobile phone or email address. Click the Send button next to your preferred
              method above.
            </p> */}
          </React.Fragment>
        );
      }

      return (
        <React.Fragment>
          <h3>
            Welcome {contact.firstname} {contact.lastname}!
          </h3>
          <div>
            <p>You have requested {storename} to securely store your payment information with their payment processor.</p>
            <p>
              Our payment processor provides an encrypted payment vault to safely and securely store your payment information, which allows you to
              make future purchases without re-entering your payment details.
            </p>
            <p>Before proceeding, please verify the following information we have on file and contact the store with any corrections.</p>
            <p className="italic">
              {name}
              {email}
              {mobilephone}
              {otherphone}
              {addr1}
              {addr2}
              {cityStateZip}
            </p>
            <p>When you're ready to provide your payment information, click the button below.</p>
            {mfaVerification}
            {addButton}
            <div className="recaptchaDisclaimer centerAligned">
              This site is protected by reCAPTCHA and <br />
              the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
              <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </div>
          </div>
        </React.Fragment>
      );
    }
  };

  setAdditionalDataValue = (field, value) => {
    const additionalDataValues = this.state.additionalDataValues ?? {};
    additionalDataValues[field] = value;
    this.setState({ additionalDataValues: additionalDataValues });
  };

  getAdditionalDataValue = field => {
    return this.state.additionalDataValues[field];
  };

  renderProspectBlock = () => {
    let address = "";
    let mobilephone = "";
    let otherphone = "";
    if (this.state.campaign?.requireaddress) {
      address = (
        <React.Fragment>
          <label htmlFor="address1" className="desktop">
            Address 1 *
          </label>
          <input
            type="text"
            id="address1"
            data-testid="Address Line 1"
            title="Address Line 1"
            name="address1"
            onChange={this.handleChangeProspect}
            value={this.state.prospect?.address1}
            placeholder="Address Line 1"
          />
          <label htmlFor="address2" className="desktop">
            Address 2
          </label>
          <input
            type="text"
            id="address2"
            data-testid="Address Line 2"
            title="Address Line 2"
            name="address2"
            onChange={this.handleChangeProspect}
            value={this.state.prospect?.address2}
            placeholder="Address Line 2"
          />
          <label htmlFor="city" className="desktop">
            City *
          </label>
          <input
            type="text"
            id="city"
            data-testid="City"
            title="City"
            name="city"
            onChange={this.handleChangeProspect}
            value={this.state.prospect?.city}
            placeholder="City"
          />
          <label htmlFor="state" className="desktop">
            State *
          </label>
          <input
            type="text"
            id="state"
            data-testid="State"
            title="State"
            name="state"
            onChange={this.handleChangeProspect}
            value={this.state.prospect?.state}
            placeholder="State"
          />
          <label htmlFor="postalcode" className="desktop">
            Zip Code *
          </label>
          <input
            type="text"
            id="postalcode"
            data-testid="Zip Code"
            title="Zip Code"
            name="postalcode"
            onChange={this.handleChangeProspect}
            value={this.state.prospect?.postalcode}
            placeholder="Zip Code"
          />
        </React.Fragment>
      );
    }
    if (this.state.campaign?.requiremobilephone) {
      mobilephone = (
        <React.Fragment>
          <label htmlFor="mobilephone" className="desktop">
            Mobile Phone *
          </label>
          <input
            type="text"
            id="mobilephone"
            data-testid="Mobile Phone"
            title="Mobile Phone"
            name="mobilephone"
            onChange={this.handleChangeProspect}
            value={this.state.prospect?.mobilephone}
            placeholder="Mobile Phone"
          />
        </React.Fragment>
      );
    }
    if (this.state.campaign?.requireotherphone) {
      otherphone = (
        <React.Fragment>
          <label htmlFor="otherphone" className="desktop">
            Other Phone *
          </label>
          <input
            type="text"
            id="otherphone"
            data-testid="Other Phone"
            title="Other Phone"
            name="otherphone"
            onChange={this.handleChangeProspect}
            value={this.state.prospect?.otherphone}
            placeholder="Other Phone"
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h3>Your Information</h3>
        <div className="footnoteText">Required fields are marked with an asterisk (*)</div>
        <div className="campaignGrid campaign">
          <label htmlFor="firstname" className="desktop">
            First Name *
          </label>
          <input
            type="text"
            id="firstname"
            data-testid="First Name"
            title="First Name"
            name="firstname"
            onChange={this.handleChangeProspect}
            value={this.state.prospect?.firstname}
            placeholder="First Name"
          />
          <label htmlFor="lastname" className="desktop">
            Last Name *
          </label>
          <input
            type="text"
            id="lastname"
            data-testid="Last Name"
            title="Last Name"
            name="lastname"
            onChange={this.handleChangeProspect}
            value={this.state.prospect?.lastname}
            placeholder="Last Name"
          />
          {address}
          {mobilephone}
          {otherphone}
        </div>
      </React.Fragment>
    );
  };

  renderCampaignFormForProspect = store => {
    if (this.state.error) {
      return <h3>Page not found.</h3>;
    } else if (!this.state.prospect && !this.state.campaign) {
      return this.renderLoadingContent();
    } else if (
      this.state.step === Constants.WORKFLOW_STATUS_PROCESSED ||
      ![Constants.PROSPECT_STATUS_NEW, Constants.PROSPECT_STATUS_PREMATCHED].includes(this.state.prospect?.prospectstatus)
    ) {
      return (
        <React.Fragment>
          <h3>Thank you!</h3>
          <div>
            <p>
              Your request has been received. A copy of your response has been emailed to <strong>{this.state.prospect?.email}</strong>
            </p>
          </div>
        </React.Fragment>
      );
    }
    const { additionaldataprompt } = Helper.renderAdditionalDataPrompt(
      this.state.campaign?.additionaldataprompt,
      this.setAdditionalDataValue,
      this.getAdditionalDataValue
    );

    let paymentCard = "";
    if (this.state.campaign.requirecard && this.state.prospect?.cardid) {
      paymentCard = (
        <React.Fragment>
          <h3>Payment Information</h3>
          <div>
            Name: {this.state.prospect?.billingfirst} {this.state.prospect?.billinglast}
          </div>
          <div>Zip Code: {this.state.prospect?.billingzip}</div>
          <div>Card Number: {this.state.prospect?.card_number}</div>
        </React.Fragment>
      );
    } else if (this.state.campaign.requirecard) {
      let addButtonClasses = "action-button green-button";
      let addButtonHandler = () => {
        const gateway = this.state.client?.settings?.find(
          s => s.category === Constants.SETTINGS_CATEGORY_CLIENT && s.description === Constants.SETTINGS_PAYMENT_GATEWAY
        )?.value;
        console.log("gateway", gateway);
        let loading = null;
        if (gateway === Constants.MAAST) {
          loading = "Contacting payment gateway...";
        }
        this.props.showOverlay({
          type: Constants.OVERLAY_TOKENIZE,
          text: "Enter payment information",
          tokenize: true,
          vault: true,
          recaptcha: true,
          recaptchaFailureCallback: () => {},
          mfaCode: this.state.mfaCode,
          mfaRequestuuid: this.state.mfaRequestuuid,
          loading: loading,
          paymentGateway: gateway,
          callback: this.handleChangeProspectPayment,
        });
      };
      if (this.props.preview) {
        addButtonClasses += " save-disabled";
        addButtonHandler = () => {};
      }
      let addButtonLabel = "Add Payment Information";
      let addButton = (
        <div className="control-buttons control-buttons-left">
          <span className={addButtonClasses} onClick={addButtonHandler}>
            {addButtonLabel}
          </span>
        </div>
      );
      paymentCard = (
        <React.Fragment>
          <h3>Payment Information</h3>
          <div>
            To process your request, we need you to provide your credit card details. Rest assured, your information will be securely stored by our
            payment processor, in their encrypted card vault, ensuring the highest level of security and confidentiality.
          </div>
          <div>{addButton}</div>
        </React.Fragment>
      );
    }

    let agreement = "";
    if (this.state.campaign.agreementtext) {
      agreement = (
        <div>
          <h3>Terms and Conditions</h3>
          <div className="campaignTerms marginBottomhalfem">{Helper.renderTextAsHtml(this.state.campaign.agreementtext)}</div>
          <div className="campaign ">
            <input
              type="checkbox"
              id="agreement"
              data-testid="Agreement Checkbox"
              title="Agreement Checkbox"
              name="agreement"
              onChange={this.handleChangeAgreement}
              checked={this.state.prospect.agreed}
            />
            <label htmlFor="agreement">I have read and agree to the terms and conditions.</label>
          </div>
        </div>
      );
    }
    let submitButtonClasses = "action-button green-button";
    let handler = this.handleClickSubmitCampaign;
    if (!this.isReadyToSubmitProspect()) {
      submitButtonClasses += " save-disabled";
      handler = () => {};
    }
    let submitButton = (
      <div className="control-buttons control-buttons-left">
        <span className={submitButtonClasses} onClick={handler}>
          Submit
        </span>
      </div>
    );
    let recaptcha = this.state.campaign.requirecard ? (
      <div className="recaptchaDisclaimer centerAligned">
        This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
    ) : (
      ""
    );
    return (
      <React.Fragment>
        <h3 className="noBottomMargin">{this.state.campaign.campaignname}</h3>
        <div className="fontSizeSmall">(Reference: {this.state.prospect?.email ?? "preview"})</div>
        <div>&nbsp;</div>
        <div>{Helper.renderTextAsHtml(this.state.campaign.description)}</div>
        {this.renderProspectBlock()}
        {additionaldataprompt}
        {paymentCard}
        {agreement}
        {submitButton}
        {recaptcha}
      </React.Fragment>
    );
  };

  renderCampaignEmailRequest = store => {
    if (this.state.error) {
      return <h3>Page not found.</h3>;
    } else if (this.state.step === Constants.WORKFLOW_STATUS_FAILED) {
      return <h3>Form not found.</h3>;
    } else if (!this.state.campaign) {
      return this.renderLoadingContent();
    }
    let buttonClasses = "action-button action-button-inline green-button";
    if (!Helper.isValidEmail(this.state.email)) {
      buttonClasses += " save-disabled";
    } else if (this.state.campaign?.requirenameinvitation && (!this.state.firstname || !this.state.lastname)) {
      buttonClasses += " save-disabled";
    } else if (this.state.campaign?.requiremobilephoneinvitation && !this.state.mobilephone) {
      buttonClasses += " save-disabled";
    }
    let input = "";
    if (this.state.step === Constants.WORKFLOW_STATUS_NEW) {
      let name = "";
      let mobilephone = "";
      if (this.state.campaign?.requirenameinvitation) {
        name = (
          <React.Fragment>
            <div className="marginBottom1em">
              <input
                type="text"
                id="firstname"
                className="paddingqtrem noBorder border-radius"
                data-testid="firstname"
                title="firstname"
                name="firstname"
                placeholder="First Name"
                onChange={this.handleChangeFirstName}
                value={this.state.firstname}
              />
            </div>
            <div className="marginBottom1em">
              <input
                type="text"
                id="lastname"
                className="paddingqtrem noBorder border-radius"
                data-testid="lastname"
                title="lastname"
                name="lastname"
                placeholder="Last Name"
                onChange={this.handleChangeLastName}
                value={this.state.lastname}
              />
            </div>
          </React.Fragment>
        );
      }
      if (this.state.campaign?.requiremobilephoneinvitation) {
        mobilephone = (
          <div className="marginBottom1em">
            <input
              type="text"
              id="mobilephone"
              className="paddingqtrem noBorder border-radius"
              data-testid="mobilephone"
              title="mobilephone"
              name="mobilephone"
              placeholder="Mobile Phone"
              onChange={this.handleChangeMobilePhone}
              value={this.state.mobilephone}
            />
          </div>
        );
      }
      input = (
        <div>
          {name}
          {mobilephone}
          <div className="marginBottom1em">
            <input
              type="text"
              id="email"
              className="paddingqtrem noBorder border-radius"
              data-testid="Email Address"
              title="Email Address"
              name="email"
              placeholder="Email Address"
              onChange={this.handleChangeEmail}
              value={this.state.email}
            />
          </div>
          <div className={buttonClasses} onClick={this.handleClickSend} title="Send Link Button">
            Send Link
          </div>
        </div>
      );
    } else if (this.state.step === Constants.WORKFLOW_STATUS_PROCESSED) {
      input = (
        <React.Fragment>
          <div className="highlight marginBottom1em">Please check your inbox for an email from {store.email} with a link to the request form.</div>
          <div className="highlight">If you do not receive the email at {this.state.email} within a few minutes, please check your spam folder.</div>
        </React.Fragment>
      );
    }
    let instructions = "";
    let description = "";
    if (this.state.step !== Constants.WORKFLOW_STATUS_PROCESSED) {
      description = Helper.renderTextAsHtml(this.state.campaign.description);
      instructions = (
        <div className="securityText marginBottom1em">
          <div>To ensure the security of your personal information, we would like to send you a link to the request form via email.</div>
          <div>Please enter your information below and click "Send Link" to continue.</div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <h3>{this.state.campaign.campaignname}</h3>
        <div>{description}</div>
        {instructions}
        {input}
      </React.Fragment>
    );
  };

  renderCheckout = store => {
    if (this.state.error) {
      return <h3>Invoice not found.</h3>;
    } else if (!this.props.order) {
      return this.renderLoadingContent();
    }
    // let sendMobile = "";
    // let sendOther = "";
    let sendEmailButton = "";
    if (this.state.step === Constants.RECAPTCHA_FAILED) {
      // sendMobile = (
      //   <span className="action-button green-button" onClick={this.handleSendMfa(Constants.MFA_MOBILE)}>
      //     Send
      //   </span>
      // );
      // sendOther = (
      //   <span className="action-button green-button" onClick={this.handleSendMfa(Constants.MFA_OTHER)}>
      //     Send
      //   </span>
      // );
      if (this.state.mfaStep !== "Email Me the Verification Code") {
        sendEmailButton = <span>{this.state.mfaStep}</span>;
      } else {
        sendEmailButton = (
          <span
            className="action-button action-button-inline green-button"
            onClick={() => {
              this.handleSendMfa(Constants.MFA_EMAIL);
            }}
          >
            {this.state.mfaStep}
          </span>
        );
      }
    }
    let paymentButtonClasses = "action-button green-button";
    let paymentButtonHandler = () => {
      const gateway = this.state.client?.settings?.find(
        s => s.category === Constants.SETTINGS_CATEGORY_CLIENT && s.description === Constants.SETTINGS_PAYMENT_GATEWAY
      )?.value;
      let loading = null;
      if (gateway === Constants.MAAST) {
        loading = "Contacting payment gateway...";
      }
      this.props.showOverlay({
        type: Constants.OVERLAY_TOKENIZE,
        text: "Enter payment information",
        tokenize: true,
        vault: false,
        saveButtonLabel: "Pay",
        recaptcha: true,
        recaptchaFailureCallback: this.handleRecaptchaFailure,
        mfaCode: this.state.mfaCode,
        mfaRequestuuid: this.state.mfaRequestuuid,
        loading: loading,
        paymentGateway: gateway,
        callback: this.handleTokenizedPayment,
      });
    };
    let addButtonLabel = "Click to Pay";
    if (this.state.step === Constants.RECAPTCHA_FAILED) {
      addButtonLabel = "Continue";
      if (this.state.mfaCode?.length !== 6) {
        paymentButtonClasses += " save-disabled";
        paymentButtonHandler = () => {};
      }
    }
    let paymentButton = (
      <div className="controls-one-button control-buttons gridCenter span2">
        <span className={paymentButtonClasses} onClick={paymentButtonHandler}>
          {addButtonLabel}
        </span>
      </div>
    );
    // No need to show the payment button if the invoice is already paid
    if (numeral(this.props.order?.balancedue ?? 0).value() === 0.0) {
      paymentButton = (
        <div className="">
          <span className="action-button green-button" onClick={this.handleViewReceipt}>
            View Receipt
          </span>
        </div>
      );
    }
    const name = (
      <React.Fragment>
        {this.props.order?.contactname}
        <br />
      </React.Fragment>
    );
    let mfaVerification = "";
    if (this.state.step === Constants.RECAPTCHA_FAILED) {
      mfaVerification = (
        <React.Fragment>
          <p className="highlight">
            <FontAwesomeIcon icon={faTriangleExclamation} className="highlight" /> To verify your identity and protect your payment information, we
            will send a one-time, six-digit verification code to the email address on file for this invoice. Enter the code in the box below when you
            receive it and click Continue.
          </p>
          <p data-testid="MFA Code Container for Checkout" title="MFA Code Container for Checkout">
            Verification Code:{" "}
            <input
              type="text"
              id="mfaCode"
              data-testid="MFA Code for Checkout"
              title="MFA Code for Checkout"
              name="mfaCode"
              ref={this.mfaCodeInput}
              maxLength={6}
              onChange={this.handleChangeMfaCode}
              autoFocus
              value={this.state.mfaCode}
            />{" "}
            {sendEmailButton}
          </p>
          {/* <p>
              To verify your identity and protect your payment information, we will send you a one-time verification code. Please enter the code in
              the box below to proceed. We can send the code to your mobile phone or email address. Click the Send button next to your preferred
              method above.
            </p> */}
        </React.Fragment>
      );
    }

    let payment = "";
    if (this.state.billing_first_name && this.state.billing_last_name) {
      payment = (
        <React.Fragment>
          {payment}
          <div>Cardholder:</div>
          <div data-testid="Payment Name" title="Payment Name">
            {this.state.billing_first_name} {this.state.billing_last_name}
          </div>
          <div></div>
        </React.Fragment>
      );
    }
    if (this.state.card_number) {
      payment = (
        <React.Fragment>
          {payment}
          <div>Card Number:</div>
          <div data-testid="Payment Card Number" title="Payment Card Number">
            {this.state.card_number}
          </div>
          <div></div>
        </React.Fragment>
      );
    }
    if (this.state.exp_date) {
      payment = (
        <React.Fragment>
          {payment}
          <div>Expires:</div>
          <div data-testid="Payment Expiration" title="Payment Expiration">
            {this.state.exp_date.slice(0, 2) + "|" + this.state.exp_date.slice(2, 4)}
          </div>
          <div></div>
        </React.Fragment>
      );
    }
    let message = <p>Before proceeding, please verify the order information.</p>;
    if (this.state.step === Constants.WORKFLOW_STATUS_PROCESSED) {
      message = (
        <p>
          Thank you! Your payment has been approved. You can view and print your receipt by clicking the button below. If you have any questions or
          need assistance, please contact us at {store.phone} or {store.email}.
        </p>
      );
    } else if (this.props.order?.balancedue === 0) {
      message = <p>This invoice has been paid in full.</p>;
    }
    let recaptchaDisclaimer = "";
    if (this.props.order?.balancedue !== 0) {
      recaptchaDisclaimer = (
        <div className="recaptchaDisclaimer centerAligned">
          This site is protected by reCAPTCHA and <br />
          the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </div>
      );
    }

    let totalpayments = "";
    if (this.props.order?.totalpayments) {
      totalpayments = (
        <React.Fragment>
          <div>Total Payments</div>
          <div className="bold totalcontainer" data-testid="Total Payments" title="Total Payments">
            {numeral(this.props.order?.totalpayments).format(Constants.CURRENCY_WITH_SYMBOL)}
          </div>
          <div></div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h3>Invoice Summary for {name}</h3>
        <div className="threeColumnGrid ">
          <div>Order Subtotal</div>
          <div className="totalcontainer" data-testid="Total Price" title="Total Price">
            {numeral(this.props.order?.totalprice).format(Constants.CURRENCY_WITH_SYMBOL)}
          </div>
          <div></div>
          <div>Tax</div>
          <div className="totalcontainer" data-testid="Total Tax" title="Total Tax">
            {numeral(this.props.order?.totaltax).format(Constants.CURRENCY_WITH_SYMBOL)}
          </div>
          <div></div>
          <div className="bold">Total</div>
          <div className="bold totalcontainer" data-testid="Total with Tax" title="Total with Tax">
            {numeral(this.props.order?.totalwithtax).format(Constants.CURRENCY_WITH_SYMBOL)}
          </div>
          <div></div>
          <div className="span2">
            <hr />
          </div>
          <div></div>
          {totalpayments}
          <div className="bold">Balance Due</div>
          <div className="bold totalcontainer" data-testid="Balance Due" title="Balance Due">
            {numeral(this.props.order?.balancedue).format(Constants.CURRENCY_WITH_SYMBOL)}
          </div>
          <div></div>
          {payment}
          <div className="span3">
            {message}
            {mfaVerification}
          </div>
          {paymentButton}
          <div></div>
          <div></div>
          <div className="span2">{recaptchaDisclaimer}</div>
        </div>
      </React.Fragment>
    );
  };

  isReadyToSubmitProspect = () => {
    if (this.props.preview) {
      return false;
    }
    if (!this.state.prospect.firstname || !this.state.prospect.lastname) {
      return false;
    }
    if (this.state.step !== Constants.WORKFLOW_STATUS_NEW) {
      return false;
    }
    if (this.state.campaign.agreementtext && !(this.state.prospect.agreed && this.state.prospect.agreedatetime)) {
      return false;
    }
    if (
      this.state.campaign.requireaddress &&
      !(this.state.prospect.address1 && this.state.prospect.city && this.state.prospect.state && this.state.prospect.postalcode)
    ) {
      return false;
    }
    if (this.state.campaign.requiremobilephone && !this.state.prospect.mobilephone) {
      return false;
    }
    if (this.state.campaign.requireotherphone && !this.state.prospect.otherphone) {
      return false;
    }
    if (
      this.state.campaign.requirecard &&
      !(
        this.state.prospect.cardid &&
        this.state.prospect.tokenizationresponse &&
        this.state.prospect.card_number &&
        this.state.prospect.billingfirst &&
        this.state.prospect.billinglast &&
        this.state.prospect.billingzip
      )
    ) {
      return false;
    }
    // TODO: Check for required additional data fields
    const missingRequiredField = this.state.requiredAdditionalDataValues.some(field => !this.state.additionalDataValues[field]);
    if (missingRequiredField) {
      return false;
    }
    return true;
  };

  handleChangeProspect = event => {
    const { id, value } = event.target;
    this.setState(prevState => ({
      prospect: {
        ...prevState.prospect,
        [id]: value,
      },
    }));
  };

  handleChangeProspectPayment = (response, billingfirst, billinglast, billingzip, card_id, exp_date, card_number, tokenization_response) => {
    if (response === Constants.OVERLAY_RESPONSE_OK) {
      // Stringify the tokenization response and store it in the state
      const tr = JSON.stringify(tokenization_response);
      this.setState(
        prevState => ({
          prospect: {
            ...prevState.prospect,
            cardid: card_id,
            tokenizationresponse: tr,
            billingfirst: billingfirst,
            billinglast: billinglast,
            billingzip: billingzip,
            card_number: card_number,
          },
        }),
        () => {
          // Tell the user they're not done yet
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            title: "You're not done yet!",
            text: "Please review your information and click Submit to complete your request.",
          });
        }
      );
    } else {
      this.props.hideOverlay();
    }
  };

  handleChangeAgreement = event => {
    const dateTime = event.target.checked ? new Date().toISOString() : "";
    this.setState(prevState => ({ prospect: { ...prevState.prospect, agreedatetime: dateTime, agreed: event.target.checked } }));
  };

  handleClickSubmitCampaign = () => {
    this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Submitting request..." });
    this.setState({ step: Constants.WORKFLOW_STATUS_PROCESSING }, () => {
      this.putCampaignRequest();
    });
  };

  handleClickSend = () => {
    if (!Helper.isValidEmail(this.state.email)) {
      return;
    } else if (this.state.campaign?.requirenameinvitation && (!this.state.firstname || !this.state.lastname)) {
      return;
    } else if (this.state.campaign?.requiremobilephoneinvitation && !this.state.mobilephone) {
      return;
    }
    this.props.handleSendCampaignLink(
      this.state.email,
      this.state.firstname,
      this.state.lastname,
      this.state.mobilephone,
      this.props.campaignuuid,
      () => {
        this.setState({ step: Constants.WORKFLOW_STATUS_PROCESSED });
      }
    );
  };

  handleChangeEmail = event => {
    this.setState({ email: event.target.value });
  };

  handleChangeFirstName = event => {
    this.setState({ firstname: event.target.value });
  };

  handleChangeLastName = event => {
    this.setState({ lastname: event.target.value });
  };

  handleChangeMobilePhone = event => {
    this.setState({ mobilephone: event.target.value });
  };

  handlePayInvoice = () => {
    // Show a progress message
    this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Processing payment..." });
    this.postPayment();
  };

  handleViewReceipt = () => {
    // Show a progress message
    this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Retrieving receipt..." });
    // Call GET /orders
    this.getOrder(this.props.orderuuid, Constants.PRINT_VIEW, response => {
      if (response.status === 200 && response.body.order) {
        const newTab = Helper.handleViewHtml(response.body.order);
        if (!newTab) {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Could not open the receipt in a new tab.\nPlease check that your browser is allowing pop-ups for this site.",
          });
        } else {
          this.props.hideOverlay();
        }
      } else {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "There was an error retrieving the receipt. Please try again later.",
        });
      }
    });
  };

  handleChangeMfaCode = event => {
    this.setState({ mfaCode: event.target.value });
  };

  handleSendMfa = method => {
    console.log("Sending verification code...");
    this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Sending verification code..." });
    this.setState({ step: Constants.RECAPTCHA_FAILED, mfaStep: "Sending..." }, () => {
      this.postSendMfa(method);
    });
  };

  handleRecaptchaFailure = responseCode => {
    const tooMayAttempts = responseCode === 429;
    const mfaNotFound = responseCode === 404;
    if (tooMayAttempts) {
      this.setState({ step: Constants.RECAPTCHA_FAILED, mfaCode: "", mfaStep: "Email Me the Verification Code" }, () => {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Too many failed attempts. Please request a new verification code.",
        });
      });
    }
    // The verification code request was not found in the database
    else if (mfaNotFound) {
      this.setState({ step: Constants.RECAPTCHA_FAILED, mfaCode: "", mfaStep: "Email Me the Verification Code" }, () => {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "The verification code request was not found. Please request a new verification code.",
        });
      });
    }
    // If we just sent a verification code as part of a get transient token request, tell the user the code is wrong
    else if (this.state.mfaCode) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "The verification code you entered is incorrect. Please try again.",
      });
    } else {
      // If the recaptcha fails, hide the overlay and display an error message
      this.setState({ step: Constants.RECAPTCHA_FAILED }, () => {
        this.props.hideOverlay();
      });
    }
  };

  handleTokenizedPayment = (response, billingfirst, billinglast, billingzip, card_id, exp_date, card_number, tokenization_response) => {
    if (response === Constants.OVERLAY_RESPONSE_OK) {
      // Stringify the tokenization response and store it in the state, then call the pay invoice function
      const tr = JSON.stringify(tokenization_response);
      this.setState(
        {
          step: Constants.WORKFLOW_STATUS_READY,
          billingfirst: billingfirst,
          billinglast: billinglast,
          billingzip: billingzip,
          card_id: card_id,
          exp_date: exp_date,
          card_number: card_number,
          tokenization_response: tr,
        },
        this.handlePayInvoice
      );
    } else {
      this.setState({ step: Constants.WORKFLOW_STATUS_NEW, mfaCode: "", mfaStep: "Email Me the Verification Code" });
    }
  };

  handlePostStoredPayment = (response, billingfirst, billinglast, billingzip, card_id, exp_date, card_number) => {
    if (response === Constants.OVERLAY_RESPONSE_OK) {
      this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Vaulting payment information..." });
      this.setState({ step: Constants.WORKFLOW_STATUS_PROCESSING }, () => {
        this.postStoredPayment(this.state.customer?.companyuuid, billingfirst, billinglast, billingzip, card_id, exp_date, card_number);
      });
    } else {
      this.setState({ step: Constants.WORKFLOW_STATUS_NEW, mfaCode: "", mfaStep: "Email Me the Verification Code" });
    }
  };

  getOrder = (orderuuid, action, callback) => {
    const url = Constants.URL_ORDERS;
    const params = { orderuuid: orderuuid };
    if (action) {
      params.action = action;
    }
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // If we get back an order, then push it up to App to be stored in state and pushed back down in props
        this.props.setOrder(response.body, () => {
          callback(response);
        });
      } else {
        callback(response);
      }
    });
  };

  postSendMfa = channel => {
    const requestuuid = this.props.requestuuid;
    const orderuuid = this.props.orderuuid;
    const url = Constants.URL_COMPANIES;
    const data = {
      action: Constants.ACTION_SEND_MFA,
      requestuuid: requestuuid,
      orderuuid: orderuuid,
      channel: channel,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body.mfarequestuuid) {
        this.setState({ mfaStep: "Sent!", mfaRequestuuid: response.body.mfarequestuuid }, () => {
          this.props.hideOverlay();
          if (this.mfaCodeInput.current) {
            this.mfaCodeInput.current.focus();
          }
        });
      } else {
        this.setState({ step: Constants.WORKFLOW_STATUS_FAILED }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an unexpected error sending the verification code. Please try again later.",
          });
        });
      }
    });
  };

  postPayment = () => {
    const url = Constants.URL_PAYMENTS;
    const data = {
      ...this.props.params,
      action: Constants.AUTHORIZE_ORDER_PAYMENT,
      orderuuid: this.props.order?.orderuuid,
      cardid: this.state.card_id,
      amount: this.props.order?.balancedue,
      avs_zip: this.state.billingzip,
      tokenization_response: this.state.tokenization_response,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body.approved === true && response.body.order) {
        this.props.setOrder(response.body.order, () => {
          this.setState({ step: Constants.WORKFLOW_STATUS_PROCESSED }, () => {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "Thank you! Your payment has been approved.",
            });
          });
        });
      } else if (response.status === 200 && response.body.approved !== true) {
        this.setState(
          {
            step: Constants.WORKFLOW_STATUS_NEW,
            billingfirst: null,
            billinglast: null,
            billingzip: null,
            card_id: null,
            exp_date: null,
            card_number: null,
            tokenization_response: null,
          },
          () => {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "Your payment has been declined. Please try again with a different card.",
            });
          }
        );
      } else {
        this.setState({ step: Constants.WORKFLOW_STATUS_FAILED }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an unexpected error processing your payment. Please try again later.",
          });
        });
      }
    });
  };

  postStoredPayment = (customer_id, billingfirst, billinglast, billingzip, card_id, exp_date, masked_card_number) => {
    //Protect screen during downloading data
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_BILLING;
    const data = {
      ...this.props.params,
      action: Constants.BILLING_POST_STORED_PAYMENT,
      requestuuid: this.props.requestuuid,
      customer_id: customer_id,
      card_id: card_id,
      card_number: masked_card_number,
      billing_first_name: billingfirst,
      billing_last_name: billinglast,
      billing_zip: billingzip,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200) {
        this.setState({ step: Constants.WORKFLOW_STATUS_PROCESSED }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Thank you! Your card has been securely stored in the vault.",
          });
        });
      } else if (response.status === 400 && response?.body?.code === 2 && response?.body?.data?.card_id === "Cannot add duplicate card") {
        this.setState({ step: Constants.WORKFLOW_STATUS_FAILED }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "This card is already on file.\nPlease use a different payment method.",
          });
        });
      } else {
        this.setState({ step: Constants.WORKFLOW_STATUS_FAILED }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an unexpected error vaulting your card. Please try again later.",
          });
        });
      }
    });
  };

  putCampaignRequest = () => {
    const url = Constants.URL_CAMPAIGNS;
    let data = {
      marketingprospectuuid: this.state.prospect.marketingprospectuuid,
      additionaldatavalues: JSON.stringify(this.state.additionalDataValues),
      agreedatetime: this.state.prospect.agreedatetime,
      agreement: this.state.campaign.agreementtext,
      address1: this.state.prospect.address1,
      address2: this.state.prospect.address2,
      city: this.state.prospect.city,
      firstname: this.state.prospect.firstname,
      lastname: this.state.prospect.lastname,
      mobilephone: this.state.prospect.mobilephone,
      otherphone: this.state.prospect.otherphone,
      postalcode: this.state.prospect.postalcode,
      prospectstatus: this.state.prospect.companyuuid ? Constants.PROSPECT_STATUS_PROCESSED_MATCHED : Constants.PROSPECT_STATUS_PENDING,
      state: this.state.prospect.state,
    };
    if (this.state.campaign?.requirecard) {
      data = {
        ...data,
        billingfirst: this.state.prospect.billingfirst,
        billinglast: this.state.prospect.billinglast,
        billingzip: this.state.prospect.billingzip,
        cardid: this.state.prospect.cardid,
        tokenizationresponse: this.state.prospect.tokenizationresponse,
      };
    }
    Helper.putData(url, data).then(response => {
      if (response.status === 200) {
        this.setState({ step: Constants.WORKFLOW_STATUS_PROCESSED }, () => {
          this.props.hideOverlay();
        });
      } else {
        this.setState({ step: Constants.WORKFLOW_STATUS_FAILED }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an unexpected error submitting your request. Please try again later.",
          });
        });
      }
    });
  };

  renderLoadingContent() {
    document.title = "Loading...";
    return (
      <div className="app-body">
        <div className="clientPageLoading">
          <div className="clientPageWrapper centerAligned span2">
            <FontAwesomeIcon icon={faSpinner} className="oopsIcon" spin />
            &nbsp;&nbsp;&nbsp;
            <span className="storeName">Loading...</span>
          </div>
        </div>
      </div>
    );
  }
}

export default ClientPage;
