import React from "react";

import FaqSection from "./FaqSection";
import Capo from "./img/Capo.js";
import * as Constants from "./Constants";
import BillingsIcon from "./img/BillingsIcon.js";
import CustomersIcon from "./img/CustomersIcon.js";
import InvoicesIcon from "./img/InvoicesIcon.js";
import OrdersIcon from "./img/OrdersIcon.js";
import ProductsIcon from "./img/ProductsIcon.js";
import RepairsIcon from "./img/RepairsIcon.js";
import QuotesIcon from "./img/QuotesIcon.js";
import PurchasesIcon from "./img/PurchasesIcon.js";
import ReportsIcon from "./img/ReportsIcon.js";
import SuppliersIcon from "./img/SuppliersIcon.js";
import MegaphoneIcon from "./img/MegaphoneIcon.js";
import MessagesIcon from "./img/MessagesIcon.js";
import TimesheetsIcon from "./img/TimesheetsIcon.js";
import SearchBar from "./SearchBar.jsx";
import * as Helper from "./Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import {
  faAngleRight,
  faCalculator,
  faCaretRight,
  faCircleCheck,
  faEye,
  faGear,
  faPaw,
  faPencil,
  faPlus,
  faSearch,
  faTag,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.searchInputRef = React.createRef();
    this.categories = [
      {
        title: "ClerkHound",
        anchor: "clerkhound",
        questions: [
          {
            question: "What is ClerkHound?",
            answer: (
              <React.Fragment>
                ClerkHound is both our company name and our flagship product. ClerkHound is a web-based point-of-sale system that helps small
                businesses manage their sales, sales taxes, inventory, and recurring billing.
              </React.Fragment>
            ),
          },
          {
            question: "Who is ClerkHound?",
            answer: (
              <React.Fragment>
                ClerkHound is a small company owned by Chris Mitchell (CEO), Russell Thackston (CTO), and Laura Thackston (CFO). The three of them are
                the sole owners and they make all the decisions.
              </React.Fragment>
            ),
          },
          {
            question: "How do I sign up for ClerkHound?",
            answer: (
              <React.Fragment>
                Wow! You only asked two questions so far! You've really got the sales fire in your eyes! We love it! For sales information, call sales
                support at 912-766-5888, or email us at sales@clerkhound.com. You can view our prices on the{" "}
                <a href="https://clerkhound.com/pricing/" target="_blank" rel="noreferrer" className="headerText">
                  ClerkHound
                </a>{" "}
                website.
              </React.Fragment>
            ),
          },
          {
            question: "Where can I find how-to videos for Clerkhound?",
            answer: (
              <React.Fragment>
                When logged in, from the gear menu ( <FontAwesomeIcon icon={faGear} className="highlight" /> ) you can select "How To Videos" to view
                a list of available videos. You can also visit our website at{" "}
                <a href="https://clerkhound.com/videos/" target="_blank" rel="noreferrer" className="highlight">
                  ClerkHound Videos
                </a>
              </React.Fragment>
            ),
          },
          {
            question: "How do I upgrade my ClerkHound subscription?",
            answer: (
              <React.Fragment>
                That's the spirit we like to see! Just contact product support at 912-766-5888 or support@clerkhound.com to upgrade your subscription.
              </React.Fragment>
            ),
          },
          {
            question: "How do I cancel my ClerkHound subscription?",
            answer: (
              <React.Fragment>
                But I thought we were doing so well together! Are we smothering you with our love? It's okay - if we're going to break up, contact
                support at 912-766-5888 or support@clerkhound.com to cancel your subscription. Just know that you'll never find anyone that loves you
                like we do. Just sayin'...
              </React.Fragment>
            ),
          },
          {
            question: "Does ClerkHound support surcharges?",
            answer: <React.Fragment>Not yet; however, we are working with our payment vendors to support surcharges soon.</React.Fragment>,
          },
          {
            question: <React.Fragment>Does ClerkHound support "Layaway"?</React.Fragment>,
            answer: (
              <React.Fragment>
                Oh yeah! Create an invoice and click “Pay”. Enter the amount that the customer would like to pay at the time of checkout (it will
                obviously be less than the full total if the customer wants to pay over time). This will create an "partially paid" invoice on the
                "Open" tab. You can easily come back to the invoice to make future payments. You can also view that invoice under that customer's
                account on the "Unpaid" folder. Additionally, you can put a tag on the invoice to easily find it later by creating a Dashboard widget
                for "Layaway" invoices.
              </React.Fragment>
            ),
          },
          {
            question: "What browser should I use for ClerkHound?",
            answer: (
              <React.Fragment>
                We support Chrome, Brave, and Chromium browsers. However, you can use other browsers, like Firefox or Safari, but be aware there may
                be some unexpected results in display and printing. It's always best practice to use the most up-to-date browser!
              </React.Fragment>
            ),
          },
          {
            question: "What receipt printer should I use?",
            answer: (
              <React.Fragment>
                We support any receipt printer that can print a text file from your computer. We have confirmed that we work with Star Micronics MCP31
                as well as a few cheaper ones (like the Rongta RP332) from Amazon. In other words, if it works on your computer now - it will most
                likely work with us!
              </React.Fragment>
            ),
          },
          {
            question: "What label printer should I use for product labels?",
            answer: (
              <React.Fragment>
                We really like the IDPRT SP310. You can get it off of Amazon at a very reasonable price. What's awesome about it is that it doesn't
                require you to use those RF chip labels like some companies (so you can buy the labels for dirt cheap too! WOOT WOOT!)
              </React.Fragment>
            ),
          },
          {
            question: "Does ClerkHound support online marketplaces like Reverb?",
            answer: (
              <React.Fragment>
                ClerkHound DOES support integration with online marketplaces like Reverb. We're even working on adding more marketplaces in the
                future! When your inventory is updated in ClerkHound, it will automatically update in Reverb. However, when you sell an item in
                Reverb, you must manually update the inventory in ClerkHound.
              </React.Fragment>
            ),
          },
          {
            question: "Does ClerkHound have a built-in online storefront?",
            answer: (
              <React.Fragment>
                ClerkHound's built-in online storefront is being worked on right now with our partner, CartLoom. We will be integrating our inventory
                and invoices with CartLoom so you have one place to go to see all of your invoice activity.
              </React.Fragment>
            ),
          },
          {
            question: "Cartloom?  I've never heard of that.  Is it good?",
            answer: (
              <React.Fragment>
                Oh yeah! Here's the story: Chris' buddy, Mike started CartLoom a long time ago and Chris was one of the early adopters of CartLoom for
                his online music store. It makes selling a breeze! Plus, it's lightweight and the store loads SUPER FAST on your website! We wanted to
                integrate with a small company like us that could meet our customer needs in a timely fashion - just like we do!
              </React.Fragment>
            ),
          },
        ],
      },
      {
        title: "BillingHound",
        anchor: "billinghound",
        questions: [
          {
            question: "What is BillingHound?",
            answer: (
              <React.Fragment>
                BillingHound is a recurring billing software. It's perfect for a business that just needs recurring billing. The ClerkHound "pro" plan
                has BillingHound built in.
              </React.Fragment>
            ),
          },
          {
            question: "Can I upgrade from BillingHound to ClerkHound?",
            answer: (
              <React.Fragment>
                You betcha! Just contact product support at 912-766-5888 or support@clerkhound.com to upgrade your subscription.
              </React.Fragment>
            ),
          },
        ],
      },
      {
        title: "General",
        anchor: "general",
        questions: [
          {
            question: "Is ClerkHound secure?",
            answer: (
              <React.Fragment>
                ClerkHound uses industry-standard encryption to protect you and your customer's data. We also adhere to all{" "}
                <a href="https://owasp.org/about/" target="_blank" rel="noreferrer" className="inlineLink">
                  OWASP security recommendations
                </a>{" "}
                for our software.
              </React.Fragment>
            ),
          },
          {
            question: "Is ClerkHound PCI Compliant?",
            answer: (
              <React.Fragment>
                Yes. Actually ClerkHound never stores credit card information, like the card number or cvv code. Our payment partners take care of all
                credit card interaction and they are PCI compliant.
              </React.Fragment>
            ),
          },

          {
            question: "What does PCI mean?",
            answer: (
              <React.Fragment>
                PCI simply stands for payment card industry. This financial industry segment includes all the various organizations responsible for
                storing, processing, and transmitting cardholder data. This includes both debit cards and credit cards. So when you get those e-mails
                that say "Is your software PCI compliant?", you can rest easy knowing that it is!
              </React.Fragment>
            ),
          },

          {
            question: "How do I report an issue with the software?",
            answer: (
              <React.Fragment>If the issue is urgent, please call 912-766-5888. Otherwise, please email us at support@clerkhound.com</React.Fragment>
            ),
          },
          {
            question: "Do you support light and dark mode?",
            answer: (
              <React.Fragment>
                Yes. Just like the force, there are both light and dark sides. You can find the light/dark mode option in the gear menu ({" "}
                <FontAwesomeIcon icon={faGear} className="highlight" /> ) It's our understanding that once you travel down the dark side - you might
                not come back!
              </React.Fragment>
            ),
          },
          {
            question: "Does ClerkHound support multi-location businesses?",
            answer: (
              <React.Fragment>
                Yes. We call this an "enterprise account." A business that has multiple locations can use ClerkHound; however, each location will need
                its own subscription if you want to have the data separated. If you want to share data between locations, you will only need one
                subscription.
              </React.Fragment>
            ),
          },
          {
            question: "How do I switch between locations in my enterprise account?",
            answer: (
              <React.Fragment>
                From the gear menu ( <FontAwesomeIcon icon={faGear} className="highlight" /> ), the owner user can select "Switch Store" to view a
                list of other stores associated with the enterprise. From there you can choose the store you want to work with.
              </React.Fragment>
            ),
          },
          {
            question: "How do I add a new location to my enterprise account?",
            answer: (
              <React.Fragment>
                You must contact product support at 912-766-5888 or support@clerkhound.com to add a new location to your enterprise. Once the new
                location is added, you can switch between locations. This is only applicable if your data is separated between locations.
              </React.Fragment>
            ),
          },
          {
            question: "How do I remove a location from my enterprise account?",
            answer: (
              <React.Fragment>
                You must contact product support at 912-766-5888 or support@clerkhound.com to remove a location from your enterprise.
              </React.Fragment>
            ),
          },
          {
            question: "Does ClerkHound support commission sales?",
            answer: (
              <React.Fragment>
                We sure do. First you add the commission rate to each product. When when a salesperson sells a product, the commission rate and the
                salesperson is appended to the line item on the invoice. At the conclusion of each pay period, you can export the line items to a
                spreadsheet, enabling a clear overview of sales, associated commissions, and the respective salesperson for that time period.
              </React.Fragment>
            ),
          },
        ],
      },
      {
        title: "Users",
        anchor: "users",
        questions: [
          {
            question: "What is my username?",
            answer: (
              <React.Fragment>
                For the owner, the username is the email address you used to sign up for ClerkHound. For managers and employees, the username is the
                email address the owner used when the user was added to the system. Usernames can be found in Settings under the "Users" category.
                Settings can be accessed by clicking the gear menu ( <FontAwesomeIcon icon={faGear} className="highlight" /> ).
              </React.Fragment>
            ),
          },
          {
            question: "How do I reset my password?",
            answer: (
              <React.Fragment>
                The change password option can be accessed by clicking the gear menu ( <FontAwesomeIcon icon={faGear} className="highlight" /> ).
              </React.Fragment>
            ),
          },
          {
            question: "What are the password requirements?",
            answer: (
              <React.Fragment>
                <li>
                  <b>Length and Complexity:</b> Your password must be at least 12 characters long and include a combination of uppercase letters,
                  lowercase letters, numbers, and Special characters (e.g., ! @ # $ % ^ & *). Tip: Create a strong password using a memorable
                  passphrase, such as MyD0gLoves$now!.
                </li>
                <li>
                  <b>Regular Password Updates: </b> You are required to update your password every 90 days to remain compliant.
                </li>
                <li>
                  <b>Compromised Passwords: </b> If you suspect that your password has been compromised, please change it immediately and report the
                  incident to our support team at support@clerkhound.com or 912-766-5888.
                </li>
                <li>
                  <b>Don't reuse old Passwords: </b> For security purposes, avoid reusing any of your last four passwords.
                </li>
                <li>
                  <b>Password Sharing: </b> Never share your password with others. Doing so could put your account and sensitive data at risk.
                </li>
                By adhering to these guidelines, you help protect your account and maintain the integrity of our system. If you have trouble updating
                your password or need assistance, please reach out to our support team.
              </React.Fragment>
            ),
          },
          {
            question: "How do I change my email address/username?",
            answer: (
              <React.Fragment>
                Only an owner can change the email address/username for a user. Usernames can be found in Settings under the Users category. Settings
                can be accessed by clicking the gear menu ( <FontAwesomeIcon icon={faGear} className="highlight" /> ).
              </React.Fragment>
            ),
          },
          {
            question: "How do I delete my user account?",
            answer: (
              <React.Fragment>
                Only an owner can delete an account for a user. Users can be found in Settings under the "Users" category. Settings can be accessed by
                clicking the gear menu ( <FontAwesomeIcon icon={faGear} className="highlight" /> ).
              </React.Fragment>
            ),
          },
        ],
      },
      {
        title: "Dashboard",
        anchor: "dashboard",
        questions: [
          {
            question: "What is the Dashboard?",
            answer: (
              <React.Fragment>
                The Dashboard is a place where you can hear all of the new things that ClerkHound is developing. It is also where we describe the
                issues that have been corrected. The dashboard also is where you can define all of the items that you need follow-up on. It is a
                personal space for you to manage your day-to-day workflow.
              </React.Fragment>
            ),
          },
          {
            question: "Can I customize the Dashboard?",
            answer: (
              <React.Fragment>
                Yes, you can add as many dashboard widgets as you want. Click the <FontAwesomeIcon icon={faSquarePlus} className="highlight" /> icon
                on the right of the dashboard screen.
              </React.Fragment>
            ),
          },
          {
            question: "Will others see my dashboard widgets?",
            answer: (
              <React.Fragment>
                What's cool about the dashboard is that it can be both personal, and shared. So if you have a list that you want people to see like
                "subscription failures" - all they have to do is create a dashboard widget that uses your tag list. For things like personal reminder
                messages, only you can see what you create.
              </React.Fragment>
            ),
          },
          {
            question: "What type of widgets are there?",
            answer: <React.Fragment>There are three types of dashboard widgets: Tag List, Message, and Clock.</React.Fragment>,
          },
          {
            question: "What is a taglist dashboard widget?",
            answer: (
              <React.Fragment>
                The Tag List widget provides a convenient way to monitor and follow-up on customers, products, suppliers, invoices, orders, etc.{" "}
                <br />
                After you have applied a tag to the customer, supplier, product, invoice or order, you can then add a tag list widget to the
                dashboard, and selecting the corresponding tag. The widget will display all customers, suppliers, products, invoices or orders
                associated with that tag. This is especially great for follow up items like "Insufficient Funds", "Failed subscription payments" or
                "Layaway" items.
              </React.Fragment>
            ),
          },
          {
            question: "What are tags and how do I use them?",
            answer: (
              <React.Fragment>
                A tag serves as a way to mark customers, suppliers, products, invoices, orders, quotes, or purchases, etc, kind of like a post-it
                note. To assign a tag, locate the tag icon ( <FontAwesomeIcon icon={faTag} className="highlight" />) on the top of the detail screen.
                Clicking on it prompts you to enter your tag text, such as "Insufficient Funds," "Follow-up," "Call immediately" or "Layaway," then
                save by clicking the checkbox icon ( <FontAwesomeIcon icon={faCircleCheck} className="highlight" /> ). Afterwards, you can add a tag
                list widget on the dashboard to conveniently view all items associated with that tag. This tag will remain on the item until manually
                removed by clicking the trashcan icon ( <FontAwesomeIcon icon={faTrashCan} className="highlight" /> ).
              </React.Fragment>
            ),
          },

          {
            question: "Can I remove the Update Widget from the dashboard?",
            answer: <React.Fragment>Nope, not that one! The Update Widget is how we share important news with our users.</React.Fragment>,
          },
        ],
      },
      {
        title: "Customer",
        anchor: "customer",
        questions: [
          {
            question: "What is the Customer View?",
            answer: (
              <React.Fragment>
                If you select the customer icon{" "}
                <span className="faqMenuIcon">
                  ( <CustomersIcon key={Constants.CUSTOMERS} /> )
                </span>{" "}
                Customers that are part of a family or a company can be grouped together. You can identify these groups of customers in the list with
                the <FontAwesomeIcon icon={faCaretRight} className="highlight" /> icon next to the customer or company name. When you click on this
                icon, you will see all of the customers in that group.
                <br /> <br />
                You can scroll through your customers pressing the page down/up key. The customer list is sortable by clicking on the column headers.
                It is also searchable by typing in the search bar at the top of the screen.
              </React.Fragment>
            ),
          },
          {
            question: "How do I search for a customer?",
            answer: (
              <React.Fragment>
                If you want to search for a specific customer you can type their name in the search bar at the top of the screen. We use a keyword
                search which allows you to enter one or more search terms and we will return all customers that match any or all of your search terms,
                just like Google.
              </React.Fragment>
            ),
          },
          {
            question: "How do I add a new customer?",
            answer: (
              <React.Fragment>
                To add a new customer, click the new button on the customer list screen. First and last name are required for every contact created,
                the other fields are optional. Once the required fields are entered, you can click the Save button.
                <br />
                <br />
                If this is a group of related customers, like a family or company, you can add additional contacts by clicking the{" "}
                <FontAwesomeIcon icon={faPlus} className="highlight" /> icon. This will open a new blank customer card for you to enter the customer's
                information. Once you have entered the data, tab out of the field you are editing, and the system will automatically save the changes.
              </React.Fragment>
            ),
          },

          {
            question: "How do I edit a customer?",
            answer: (
              <React.Fragment>
                Double click on the customer you want to edit. This will open the customer detail screen where you can make changes to the customer.
                Click the pencil ( <FontAwesomeIcon icon={faPencil} className="highlight" /> ) icon to turn on edit mode. Edit the field. Changes are
                automatically save, when you tab out of the field.
              </React.Fragment>
            ),
          },
          {
            question: "How do I delete a customer?",
            answer: (
              <React.Fragment>
                Double click on the customer you want to delete. This will open the customer detail screen. Scroll down until the delete button is
                visible and click it. If this customer has never been used in an invoice, etc, the customer will be deleted. If the customer has ever
                had any system activity like an invoice, etc, the customer will be marked as inactive. Inactive customers can be viewed by changing
                the filter to inactive.
              </React.Fragment>
            ),
          },
          {
            question: "Can I add a new customer to the database after I've already started ringing up an invoice?",
            answer: (
              <React.Fragment>
                If you are in the middle of ringing up an invoice and you realize that you need to add a new customer, you can click the{" "}
                <FontAwesomeIcon icon={faPlus} className="highlight" /> icon next to the search customers field. This will open a new blank customer
                card for you to enter the customer's information right there in the invoice.
              </React.Fragment>
            ),
          },
          {
            question: "What is the Walk-in Customer and why can't I delete them?",
            answer: (
              <React.Fragment>
                A Walk-in customer was created for you when you first signed up for ClerkHound. This customer is used when you are ringing up someone
                that does not want to provide their customer information. Since this customer is tied to the "Walk-in" customer button, it cannot be
                deleted. However, you can change the name of your walk-in customer to something else if you like. We named our Christopher Walkin -
                get it? Man, we're funny
              </React.Fragment>
            ),
          },
          {
            question: "What is the Stock Customer and why can't I delete them?",
            answer: (
              <React.Fragment>
                A Stock Order customer was created for you when you first signed up for ClerkHound. This customer is used you are ordering stock for
                your store. Since this customer is tied to the "Stock Order" button, it cannot be deleted. However, you can change the name of your
                Stock Order customer to something else if you like. We named ours Stocky McStockface.
              </React.Fragment>
            ),
          },
          {
            question: "How do I see the details for a customer?",
            answer: (
              <React.Fragment>
                If you want to see the details of a specific customer, you can double click on a customer name in the list view. Only the name, phone
                numbers, and email address show in the default view of the contact card. If you would like to see more details, click the{" "}
                <FontAwesomeIcon icon={faPencil} /> icon. If the contact card contains an <FontAwesomeIcon icon={faAngleRight} /> at the top, then
                that means there are more customers in the group. Click the <FontAwesomeIcon icon={faAngleRight} /> icon to see them.
              </React.Fragment>
            ),
          },

          {
            question: "How do I see inactive customers?",
            answer: (
              <React.Fragment>
                If you want to see the customers that you have made inactive, then change the filter by option at the top of the screen to Inactive.
              </React.Fragment>
            ),
          },
          {
            question: "How do I keep notes about my customers?",
            answer: (
              <React.Fragment>
                At the bottom of the screen, there is a folder tab called Notes. It is there that you can write a free text note regarding your
                customer. This note is searchable from the search bar at the top of the Customer List View screen. The only thing that cannot be
                stored in a note is the customer's credit card information. If a customer wants you to keep their credit card on file, you can do so
                by adding their card to the "card vault".
              </React.Fragment>
            ),
          },
          {
            question: "Why can't I delete a customer that has invoices/orders tied to it?",
            answer: (
              <React.Fragment>
                Behind the scenes, the customer number is used to tie invoices/orders together, if that number is deleted, then those relationships
                will programmatically become lost. Therefore, we just mark them inactive instead so you don't have to see them in your view.
              </React.Fragment>
            ),
          },
          {
            question: "Where did all these notes come from on the customer record, I didn't put them there?",
            answer: (
              <React.Fragment>
                For certain high risk items, like updates to the billing module, the system will automatically write a note that cannot be deleted.
                This ensures that there is an audit trail of what was done and by whom. In other words, we got your back.
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>Why can't I click on the "Save" button when I am adding a new customer?</React.Fragment>,
            answer: (
              <React.Fragment>The customer must have at least the first and last name entered before the save button is accessible.</React.Fragment>
            ),
          },
          {
            question: <React.Fragment>How do I add a family or company to my customer list?</React.Fragment>,
            answer: (
              <React.Fragment>
                To begin, click the "New" button on the customer list screen and input the primary customer for the family. Once the primary customer
                details are entered, click "Save." With the primary customer saved, proceed to add other family members by clicking the{" "}
                <FontAwesomeIcon icon={faPlus} className="highlight" /> icon. Repeat this step until all family members are included. This grouping
                ensures convenient access to all family members on the Customer List screen. When viewing the customer list, clicking on an individual
                family member will display their details. To view all family members collectively, click the{" "}
                <FontAwesomeIcon icon={faAngleRight} className="highlight" /> icon to expand the list.
              </React.Fragment>
            ),
          },
        ],
      },
      {
        title: "Products",
        anchor: "products",
        questions: [
          {
            question: "What is the Product View?",
            answer: (
              <React.Fragment>
                If you select the Product icon{" "}
                <span className="faqMenuIcon">
                  ( <ProductsIcon key={Constants.PRODUCTS} /> )
                </span>{" "}
                on the navigation menu, you will see a list of products that you have entered into the system. You can scroll through your Products
                pressing the page down/up key. The product list is sortable by clicking on the column headers. It is also searchable by typing in the
                search bar at the top of the screen.
              </React.Fragment>
            ),
          },
          {
            question: "How do I add a product?",
            answer: (
              <React.Fragment>
                To add a new product, click the New button at the top of the product list screen. There are only two fields required for a product,
                the Short Product Description and the Sell Price. All of the other fields are not required. However, if you want to know how much
                profit you are making off a product, you must add the supplier name and cost.
              </React.Fragment>
            ),
          },
          {
            question: "How do I edit a product?",
            answer: (
              <React.Fragment>
                From the Product List, double-click on the product you want to edit. This will open the product detail screen where you can make
                changes to the product. When you tab out of the field you are editing, the system will automatically save the changes.
              </React.Fragment>
            ),
          },
          {
            question: "How do I delete a product?",
            answer: (
              <React.Fragment>
                From the Product List, double-click on the product you want to delete. This will open the product detail screen. Scroll to where the
                buttons display, click the Delete button. You will be asked to confirm the deletion. If the product has been used in an invoice, etc,
                the product will be marked as inactive; however, if it has never been used, it will be deleted.
              </React.Fragment>
            ),
          },
          {
            question: "Can I add a new product to the database after I've already started ringing up an invoice?",
            answer: (
              <React.Fragment>
                If you are in the middle of ringing up an invoice and you realize that you need to add a new product, you can click the{" "}
                <FontAwesomeIcon icon={faPlus} className="highlight" /> icon next to the search products field. This will display a short list of
                fields for you to enter the product's information right there in the invoice. You may need to go back and add more details later. To
                do that you would access the product from the product list screen.
              </React.Fragment>
            ),
          },
          {
            question: "Why can I not modify the max discount and commission fields?",

            answer: (
              <React.Fragment>
                Only owners and managers can modify the max discount and commission fields. Employee users do not have the ability to modify these
                fields.
              </React.Fragment>
            ),
          },
          {
            question: "How do I print labels for my products?",
            answer: (
              <React.Fragment>
                First you need a label printer that supports 1 1/4" x 3 1/2" labels. We do not support any other size. We recommend the IDPRT SP310.
                You can get it off of Amazon at a very reasonable price. You will need to install the printer driver on your computer. Once you have
                the printer installed, you can select that printer from the print dialog box in the product detail screen. Ensure your paper size is
                correct. The print preview will show you what the label will look like before you print it and should include a barcode for scanning.
              </React.Fragment>
            ),
          },
          {
            question: "Does Reverb get notified when in the inventory count for a product changes in ClerkHound?",
            answer: (
              <React.Fragment>
                Absolutely. Whenever products are sold using ClerkHound and that product is published to Reverb, we will let Reverb know of the
                inventory changes.
              </React.Fragment>
            ),
          },
          {
            question: "How do I add a product to Reverb?",
            answer: (
              <React.Fragment>
                To add a product to Reverb, you must first have a Reverb account. Once you have an account and a properly applied Reverb API key in
                Settings, you can publish products to Reverb. On the product you want to publish, upload photos of the product under the photo folder.
                After the photos are uploaded, access the Reverb folder to enter all of the pertinent information about the product. Once you have
                entered the information, click the "Publish" button. The product will be added to your Reverb account.
              </React.Fragment>
            ),
          },
          {
            question: "How do I remove a product from Reverb?",
            answer: <React.Fragment>You must delist the product from Reverb on the Reverb website.</React.Fragment>,
          },
        ],
      },
      {
        title: "Inventory",
        anchor: "inventory",
        questions: [
          {
            question: "How does ClerkHound treat the inventory reconciliation process?",
            answer: (
              <React.Fragment>
                ClerkHound uses a perpetual inventory system, which means that the system is always tracking the inventory levels when you buy and
                sell products. However, ClerkHound also supports a physical inventory count process. This process is used to verify the inventory
                levels in the system match the actual inventory levels in the store.
              </React.Fragment>
            ),
          },
          {
            question: "What are the main steps in performing an inventory count?",
            answer: (
              <React.Fragment>
                You can begin your inventory reconciliation process by clicking on the gear menu ({" "}
                <FontAwesomeIcon icon={faGear} className="highlight" /> ) and selecting "Begin Inventory". This will create two new tabs on the
                products page, "Inventory" and "Summary". The Inventory tab is where users will enter or scan each product in the store. The Summary
                Tab is where the manager or owner can see the progress of the overall inventory.
                <br />
                <br />
                To inventory a product, scan or enter its Name, UPC, or SKU in the search field above and press Enter. (If you are using a barcode
                scanner, it should be set to send an Enter key at the end of each scan. Refer to your scanner's user manual for instructions on
                changing this setting.) Repeat for each physical item in your store to count items individually, or you can scan a single item and
                enter the total quantity of items in the Quantity column. You choose what works best for your store.
                <br />
                <br />
                Items on the "Inventory" tab are not saved until the user presses the "Save Inventory" button. Once saved, the list clear the screen
                and allow you to scan or enter additional items.
                <span className="highlight"> If you log out before saving, the inventory counts will be lost! </span>If changes are made to products
                during the inventory process, the user can reinitialize to update the information on the device they are using to scan the products.
                <br />
                <br />
                Anytime, during the inventory process, the owner or manager can access the summary tab to see the overall progress. Only products
                marked as affecting inventory will be displayed on the summary tab. The items on this tab can be filtered many different ways to help
                you see the data you need to understand your progress.
                <br />
                <br />
                When all of the products are counted in the store, you should export the product data to an csv file for audit and recovery purposes.
                Then the owner or manager can click the "Complete" button to complete the process. This will update the inventory levels in the system
                to match the physical inventory levels in the store. However, if any of the products are not counted, the system will ask if you want
                to overwrite the inventory levels with zero or leave them as they are. If you choose to overwrite, the system ask that you confirm the
                action. Once confirmed, the inventory levels will be updated.
              </React.Fragment>
            ),
          },
          {
            question: "What if I want to cancel my inventory count?",
            answer: (
              <React.Fragment>
                If you start the inventory reconciliation process and decide you want to cancel, you can click the "Abandon" button at the top of the
                Summary Tab
              </React.Fragment>
            ),
          },
          {
            question: "What if I want to pause my inventory count?",
            answer: (
              <React.Fragment>
                If you start the inventory reconciliation process and decide you want to pause the process temporarily, you can click the "Pause"
                button at the top of the Summary Tab
              </React.Fragment>
            ),
          },
          {
            question: "Why do I have to type such a long confirmation when I am going to zero out some inventory items?",
            answer: (
              <React.Fragment>
                Zeroing out inventory items is a big deal. And that long phrase just makes sure you understand what is going to happen.
              </React.Fragment>
            ),
          },
        ],
      },
      {
        title: "Invoices",
        anchor: "invoices",
        questions: [
          {
            question: "What is the Invoices view?",
            answer: (
              <React.Fragment>
                Invoices are your records of sales transactions. If you select the Invoices icon{" "}
                <span className="faqMenuIcon">
                  ( <InvoicesIcon key={Constants.INVOICES} /> )
                </span>{" "}
                on the navigation menu, you will see a list of Invoices that you have entered into the system. There are four tabs at the top of the
                screen: Today, Open, Processed, and All. The "Today" tab shows all of the invoices created today regardless of paid status. The "Open"
                tab shows all Unpaid Invoices. The "Processed" tab shows all invoices that have been paid or refunded. The "All" tab shows all of the
                Quotes regardless of status.The invoice list is sortable by clicking on the column headers. It is also searchable by typing in the
                search bar at the top of the screen.
              </React.Fragment>
            ),
          },
          {
            question: "Can I edit an Invoice?",
            answer: (
              <React.Fragment>
                That depends on the status of the invoice. If the invoice is paid, you can only edit it if you are an owner or manager user. If the
                invoice is refunded, you cannot edit it. If the invoice is open, you can edit it. To edit, double-click on the invoice you want to
                edit from the list screen. This will open the invoice detail screen where you can make changes to the invoice. When you tab out of the
                field you are editing, the system will automatically save the changes.
              </React.Fragment>
            ),
          },
          {
            question: "Can I change the customer on an Invoice?",
            answer: (
              <React.Fragment>
                Yes, you can change the customer on an invoice regardless of paid status. To do this, click the ({" "}
                <FontAwesomeIcon icon={faXmark} className="highlight" /> ) next to the customer contact card on the invoice in question. Enter the
                correct name in the "Select a new customer for the invoice" prompt and select the correct customer. The system will update the
                customer on the invoice.
              </React.Fragment>
            ),
          },
          {
            question: "Why can I delete some paid invoices but not others?",
            answer: (
              <React.Fragment>
                Credit Card Associations, like Visa and MasterCard, prohibit the deletion of invoices with credit payments; however, invoices with
                other types of payments, like cash or check, can be deleted with manager approval.
              </React.Fragment>
            ),
          },
          {
            question: "Can I delete a payment off an invoice I made by mistake?",
            answer: (
              <React.Fragment>
                If the payment is a credit card payment that you need to delete, you will have to go through the refund process to fix it. However, if
                the payment is a cash, check, or other type of "cash-like" payment, a manager or owner can delete the payment by clicking the trashcan
                icon ( <FontAwesomeIcon icon={faTrashCan} className="highlight" /> ) next to the payment.
              </React.Fragment>
            ),
          },

          {
            question: "Why is scanning a barcode so much faster than searching a product by SKU or name?",
            answer: (
              <React.Fragment>
                Scanning using a barcode scanner is faster than searching by SKU or name because the system maintains a list of your UPC and EAN
                values in memory for quick access. If you search by SKU or name, the system must search the database for the product, which takes
                longer.
              </React.Fragment>
            ),
          },
          {
            question: "What do the column label icons mean on the Invoice Detail Screen?",
            answer: (
              <React.Fragment>
                The <FontAwesomeIcon icon={faEye} /> icon next to "Qty" shows the inventory amounts for the line items. <br />
                The <FontAwesomeIcon icon={faCalculator} /> icon next to "Discount" triggers a pop-up for entering a percent discount that applies to
                the entire order. <br />
                The <FontAwesomeIcon icon={faEye} /> icon next to "Total" toggles between the price and cost amounts.
              </React.Fragment>
            ),
          },
          {
            question: "I need to reopen a paid invoice to add more items, is it possible?",
            answer: (
              <React.Fragment>
                If you are an owner or manager user, you can open a paid invoice by clicking on the green "paid" badge under the invoice number. This
                will allow you to add more line items and take additional payments. Employee users to not have the ability to reopen paid invoices.
              </React.Fragment>
            ),
          },
          {
            question: 'Can I change the "create date" for an invoice?',
            answer: (
              <React.Fragment>
                Owner and manager users have the ability to change the creation date for an invoice; however, employees users cannot.
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>Why would I change a "create date" for an invoice?</React.Fragment>,
            answer: (
              <React.Fragment>
                Government entities (such as schools) are notorious for requesting a “Must be dated after xx/xx/xx”. They want the goods fast, but
                they didn't get the PO to you on time. Now they want you to fix it for them. This lets you do just that.
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>How do I refund a deposit?</React.Fragment>,
            answer: (
              <React.Fragment>
                The only way to refund a deposit is to create an invoice from the Order, Quote, or Service. In the invoice, reduce the line item
                amount(s) to match the deposit amount which should result in a balance due to zero. Mark the Invoice as paid. Refund the invoice for
                the deposit amount.
              </React.Fragment>
            ),
          },
          {
            question: "Can I delete a deposit off an order I made by mistake?",
            answer: (
              <React.Fragment>
                If the deposit is a credit card payment that you need to delete, you will have to go through the refund process to fix it (see above).
                However, if the deposit is a cash, check, or other type of "cash-like" deposit, a manager or owner can delete the deposit by clicking
                the trashcan icon ( <FontAwesomeIcon icon={faTrashCan} className="highlight" /> ) next to the deposit.
                <br /> <br />
                However, if you have applied this deposit to an invoice (or other order type), you will need to delete the applied deposit from the
                invoice first before you can delete the deposit.
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>What is the "Online" tab on invoices?</React.Fragment>,
            answer: (
              <React.Fragment>
                If you do business with the online retailer Reverb and have updated your settings to include your Reverb API key, you will see an
                "Online" tab on the invoice list screen. This tab will show all of the online orders that have been retrieved from Reverb. Once an
                order has been successfully marked as "Shipped", that order will move to the Processed tab.
              </React.Fragment>
            ),
          },
          {
            question: "How do I retrieve my online orders from Reverb?",
            answer: (
              <React.Fragment>
                Users can retrieve their online orders from Reverb by clicking the "Retrieve Orders" button on the invoice list screen. This action
                will contact Reverb and retrieve all of the online orders since the last retrieval.
              </React.Fragment>
            ),
          },
          {
            question: "I deleted a Reverb order by mistake, is there a way to get it back?",
            answer: (
              <React.Fragment>
                Users can change the last retrieved date next to the "Retrieve Orders" button to a date before the order was created. This will
                retrieve all of the online orders from Reverb since the last retrieval. Don't worry, we prevent duplicates orders from being created
                based on the Reverb order number.
              </React.Fragment>
            ),
          },

          {
            question: "How do I mark an online order as shipped?",
            answer: (
              <React.Fragment>
                You can change the status of an online order to "Shipped" two ways. The first is to enter a tracking number in the tracking number
                field on the invoice. When you tab out of the field, the system will ask you if you want to mark the order as shipped and if you want
                to inform Reverb. If you agree, you will pick the company that you used to ship the item and the invoice will be marked as "Shipped".
                The second way is to click the Invoice status badge on the invoice detail screen and select "Shipped" or "Picked up". The system will
                ask you for the tracking number and, if you provide one, it will ask if you want to inform Reverb. If you agree, you will pick the
                company that you used to ship the item and the invoice will be marked as "Shipped". If you choose to inform Reverb that the item has
                shipped, Reverb will email the customer to let them know that the item is on the way.
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>I retrieved my Reverb orders today, but I don't see them on the "Today" tab?</React.Fragment>,
            answer: (
              <React.Fragment>
                The creation date for Reverb Orders are based on when they were created on the Reverb website, not when they were downloaded to
                ClerkHound. You can find all of your unshipped Reverb orders on the "Online" tab. You will only see those orders on the "Today" tab if
                they were created today on the Reverb website.
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>How do I handle refunds and order cancellations with Reverb?</React.Fragment>,
            answer: (
              <React.Fragment>
                Refunds and cancellations will be processed through the Reverb website. If a customer requests a refund, you as the merchant must
                login to the Reverb website to approve. Once you have approved the refund on Reverb, return to ClerkHound, navigate to the Online tab
                and click "Retrieve Orders" button. This will create a new "Return" invoice that will reverse the total original invoice amounts
                (including the item, shipping, taxes and fees assessed). After some time, Reverb will update the refunded order to "Cancelled". When
                ClerkHound retrieves the order again, it will mark the order as "Cancelled" in ClerkHound. This will ensure that your sales reports
                are accurate. If the original sales invoice was never imported into ClerkHound, a "Return" or "Cancelled" invoice will not be created.
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>I marked my Reverb Order shipped, but I had a problem notifying Reverb. What do I do?</React.Fragment>,
            answer: (
              <React.Fragment>
                If you received an error saying that your Reverb API key was missing a scope, then you need to update your Reverb API key on the
                Reverb website to include "write_orders". Once you have updated your API key, set the status as "Not Shipped" on the ClerkHound
                invoice and then reset it back to "shipped". If you agree to update Reverb, the shipped status will be sent.
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>What do I do if I received an Online order, but the product was not found?</React.Fragment>,
            answer: (
              <React.Fragment>
                This means that the store sku for the product on Reverb does not match any store skus in ClerkHound. You will be prompted to search
                for the product in your product list. If the product is not available or you cannot fulfill the order, cancel the search and delete
                the ClerkHound invoice. You will have to log into Reverb to cancel the order there as well.
              </React.Fragment>
            ),
          },
          {
            question: (
              <React.Fragment>Oops, I matched the wrong product with the Reverb order in my ClerkHound Invoice. How do I fix it?</React.Fragment>
            ),
            answer: (
              <React.Fragment>
                If you have matched the wrong product with the Reverb order, you can click the magnifying glass icon{" "}
                <span className="faqMenuIcon">
                  ( <FontAwesomeIcon icon={faSearch} className="highlight" />)
                </span>{" "}
                next the product description on the invoice detail screen. You will be prompted to search for and select another product. Once you
                have selected the correct product, the invoice will be updated with the new product information.
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>How does processing a Reverb order in ClerkHound impact my inventory?</React.Fragment>,
            answer: (
              <React.Fragment>
                When you process a Reverb order in ClerkHound, the system will automatically reduce the inventory levels for the products in the
                Invoice when you mark the Invoice as "Shipped" or "Picked up".
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>I deleted a Reverb Order from ClerkHound, but it came back. Why?</React.Fragment>,
            answer: (
              <React.Fragment>
                ClerkHound will retrieve any Reverb order that was modified since the last retrieval. If you delete a Reverb order from ClerkHound, it
                will be retrieved again if it was modified on the Reverb website. There are many reasons why the date modified on Reverb may change.
                If you want to permanently delete the order, you must delete it from the Reverb website.
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>Can customers pay for their invoices online?</React.Fragment>,
            answer: (
              <React.Fragment>
                ClerkHound supports online payments. When an invoice has a balance and you email that invoice to a customer, an option to include a
                payment link is available by default. The customer can click the link and pay the invoice online.
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>Why was my customer prompted for a verification code when trying to pay their invoice online?</React.Fragment>,
            answer: (
              <React.Fragment>
                ClerkHound uses various security measures to protect your customers' information. If a customer is prompted for a verification code,
                it is because the system has detected a potential security risk. The customer will receive a code via email to verify their identity.
                Once the code is entered, the customer can proceed with the payment.
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>Why is the MFA code that was sent to the customer not shown in the ClerkHound Messages tab?</React.Fragment>,
            answer: (
              <React.Fragment>
                ClerkHound takes security very seriously. As a result, the MFA code is not shown on the Messages tab. This is to ensure that only the
                customer has access to the code.
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>Can I see when and if a customer tried to pay their invoice online?</React.Fragment>,
            answer: (
              <React.Fragment>
                When a customer accesses the payment link in the email, the system will record the date and time of the attempt, along with the IP
                address of the request. The system will also record payment attempts, whether successful or not. This information can be viewed in the
                Internal Notes folder.
              </React.Fragment>
            ),
          },
        ],
      },
      {
        title: "Orders",
        anchor: "orders",
        questions: [
          {
            question: "What is the Orders view?",
            answer: (
              <React.Fragment>
                Orders are your records of products that you want to order for your customers or your store. Our powerful ordering system allows you
                to build a list of individual orders that can be combined into a single purchase order.
                <br /> <br />
                If you select the Orders icon{" "}
                <span className="faqMenuIcon">
                  ( <OrdersIcon key={Constants.ORDERS} /> )
                </span>{" "}
                on the navigation menu, you will see a list of Orders that you have entered into the system. There are three tabs at the top of the
                screen: Open, Processed, and All. The "Open" tab shows all Orders that have not been ordered through a purchase order. The "Processed"
                tab shows all Orders that have been ordered. The "All" tab shows all of the Orders regardless of status. The Order list is sortable by
                clicking on the column headers. It is also searchable by typing in the search bar at the top of the screen.
              </React.Fragment>
            ),
          },
          {
            question: "Why did the status change on my customer order?",
            answer: (
              <React.Fragment>
                Customer orders have a symbiotic relationship with Purchase Orders and Invoices. When activity happens on the Purchase Order, it
                reflects on the Customer order. When a customer order is first created, the status will be "Open". Once you have created a purchase
                order from the customer order, the customer order and purchase order status will change to "PO Generated". When the purchase order has
                been sent to the supplier, the status will change to "Ordered". When the products have been received from the supplier, the status
                will change to "Received" or "Partially Received". When the customer order is ready to be invoiced, the customer order status will
                change to "Invoiced" and when the invoice is paid, the status will change to "Paid".
              </React.Fragment>
            ),
          },
          {
            question: "What is the difference between a deposit and a payment?",
            answer: (
              <React.Fragment>
                Deposits can be accepted for Orders, Quotes, and Repairs, whereas payments can only be processed for Invoices.
                <br /> <br />
                When an invoice is created from an Order, Quote, or Service that contains a deposit, the deposit amount will be applied as a payment
                to the invoice. It's essential to note that deposits are not recognized as income until the entire balance is settled. However,
                regular payments are immediately classified as income upon receipt.
              </React.Fragment>
            ),
          },
          {
            question: "What happens when I click on the icon next to Qty on the Order List screen?",
            answer: (
              <React.Fragment>
                The <FontAwesomeIcon icon={faEye} /> icon next to "Qty", shows the inventory amounts for the products.
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>Why can't I click on the "New PO from selected" button?</React.Fragment>,
            answer: <React.Fragment>You must select at least one item in the list before the button is accessible.</React.Fragment>,
          },
          {
            question: <React.Fragment>What is "New PO from selected" button for?</React.Fragment>,
            answer: (
              <React.Fragment>
                Users have the option to sort the order screen by supplier and conveniently select all products associated with that supplier. Upon
                pressing the "New PO from selected" button, the chosen items will be automatically transferred onto a purchase order tailored for the
                respective supplier. This method serves as an efficient means to consolidate orders for each supplier, streamlining the process
                significantly.
              </React.Fragment>
            ),
          },
          {
            question: "How do I order stock for my store using ClerkHound?",
            answer: (
              <React.Fragment>
                There are a couple of ways to do this. You can directly create a PO for one of your suppliers, or you can create “stock orders” as you
                go along (to group orders together). To replenish your store's stock using the "stock order method", begin by creating a new order and
                selecting the "stock order" button for the customer. Include all the necessary items for your store, regardless of the supplier. When
                you are complete, return to the list screen to view all of the pending order items. Sort them by supplier, select the items for a
                supplier and press the "New PO from selected" button. This will create a consolidated purchase order for the supplier. Continue until
                all of your pending items have generated a purchase order.
              </React.Fragment>
            ),
          },
        ],
      },
      {
        title: "Service",
        anchor: "Service",
        questions: [
          {
            question: "What is the Service view?",
            answer: (
              <React.Fragment>
                Service Orders are your records of items that you are servicing for your customers.
                <br /> <br />
                If you select the Service Orders icon{" "}
                <span className="faqMenuIcon">
                  ( <RepairsIcon key={Constants.REPAIR} /> )
                </span>{" "}
                on the navigation menu, you will see a list of service items that you have entered into the system. There are three tabs at the top of
                the screen: Open, Processed, and All. The "Open" tab shows all Service items that have not been completed and they have a variety of
                statuses, like "Open" or "Parts Ordered". The "Processed" tab shows all Service items that have been invoiced or paid. The "All" tab
                shows all of the Service items regardless of status. The Service list is sortable by clicking on the column headers. It is also
                searchable by typing in the search bar at the top of the screen.
              </React.Fragment>
            ),
          },
          {
            question: "Explain the statuses on the customer service item",
            answer: (
              <React.Fragment>
                The status of a service is a reflection of the progress of the service. There can be one (or many items) on a service order, as a
                result each service item will have their own statuses and they must be manually changed by the technician. Those service item statuses
                are "Open", "Diagnosed", "Parts Ordered", "On Bench", "Cancelled", "Cancelled/Return", and "Invoiced". There are also statuses at the
                line item and Service Order level. These statuses are updated by ClerkHound, based on service order's activity. For example, when a
                line item is copied to an order, that line item status will be changed to "Ordered". When the service order is invoiced, the status
                will be changed to invoiced. When the invoice for the service is paid, the status will be changed to paid.
                <br />
                <br />
                Service Item Statuses: <br />
                &bull; When a service is first created, the status will be defaulted to "Open". <br />
                &bull; When the technician uncovers the problem, the technician will change the status to "Diagnosed". <br />
                &bull; If parts are ordered, the technician will change the status to "Parts Ordered". <br />
                &bull; When the service is being worked on, the technician will change the status to "On Bench". <br />
                &bull; If the customer decides to cancel the service, but the item does not have to be returned, the technician will change the status
                to "Cancelled" if the item does need to be turned, the technician will change the status to "Cancelled/Return". <br />
                When the service is completed and ready for the customer, the technician will change the status to "Invoiced". <br />
                <br />
                Service Order Statuses: <br />
                &bull; When the Invoice button is clicked, the status at the line item and Service Order level will change to "Invoiced". <br />
                &bull; When the Invoice for the service is paid, the status at the line item and Service Order level will change to "Paid".
                <br /> <br />
                Line Item Statuses: <br />
                &bull; If a line item is copied to an order, and then a purchase order was created, the line item status on the Service Order and on
                the Customer Order will change to "PO Generated". <br />
                &bull; When the purchase order is sent to the supplier, the line item status on the Customer Order and on the Service Order will
                change to "Ordered". <br />
              </React.Fragment>
            ),
          },
          {
            question: "Can I manually mark a Service as invoiced?",
            answer: (
              <React.Fragment>
                Sure can. Click the status at the top and select "Invoiced". This will mark the service and all line items as invoiced. This is
                especially useful when you have multiple Service Orders, but only plan to send the customer a single invoice.
              </React.Fragment>
            ),
          },
        ],
      },
      {
        title: "Quotes",
        anchor: "quotes",
        questions: [
          {
            question: "What is the Quotes view?",
            answer: (
              <React.Fragment>
                Quotes are your records of items that you have quoted for your customers.
                <br /> <br />
                If you select the Quote icon{" "}
                <span className="faqMenuIcon">
                  ( <QuotesIcon key={Constants.QUOTE} /> )
                </span>{" "}
                on the navigation menu, you will see a list of Quotes that you have entered into the system. There are three tabs at the top of the
                screen: Open, Processed, and All. The "Open" tab shows all Quotes that have not been sent to the customer (the sent status is changed
                to sent when the quote is email or printed). The "Processed" tab shows all quotes that have been sent. The "All" tab shows all of the
                Quotes regardless of status. The Quotes list is sortable by clicking on the column headers. It is also searchable by typing in the
                search bar at the top of the screen.
              </React.Fragment>
            ),
          },
        ],
      },
      {
        title: "Suppliers",
        anchor: "suppliers",
        questions: [
          {
            question: "What is the Suppliers view?",
            answer: (
              <React.Fragment>
                Suppliers are your records of all of the companies that you buy products from.
                <br /> <br />
                If you select the Supplier icon{" "}
                <span className="faqMenuIcon">
                  ( <SuppliersIcon key={Constants.SUPPLIER} /> )
                </span>{" "}
                on the navigation menu, you will see a list of Suppliers that you have entered into the system. The Supplier list is sortable by
                clicking on the column headers. It is also searchable by typing in the search bar at the top of the screen.
              </React.Fragment>
            ),
          },
          {
            question: "What is the minimum information I need to add a new supplier?",
            answer: (
              <React.Fragment>
                To add a new supplier, you must enter the Supplier Company name along with at least one additional detail such as the Salesperson's
                first and/or last name, the company's phone number or website. All other fields are optional.
              </React.Fragment>
            ),
          },
          {
            question: "Why does the supplier need to be added before their product?",
            answer: <React.Fragment>To add a supplier to the product, the supplier must already be saved in the system.</React.Fragment>,
          },
          {
            question: "Why do I care to add suppliers?  Is it really necessary?",
            answer: (
              <React.Fragment>
                Remember - we're called ClerkHOUND for a reason. We wanna help you find everything you need and give you all the data that you need
                about your store at your fingertips! Before you know it, you're going to be more organized than those big whigs that walk around trade
                shows wearing SUITS! The difference is that you're gonna be nice and comfortable in your jeans while doing it!
              </React.Fragment>
            ),
          },
        ],
      },
      {
        title: "Purchases",
        anchor: "purchases",
        questions: [
          {
            question: "What is the Purchases view?",
            answer: (
              <React.Fragment>
                Purchases are your records of purchase orders that you have created for a supplier.
                <br /> <br />
                If you select the Purchases icon{" "}
                <span className="faqMenuIcon">
                  ( <PurchasesIcon key={Constants.PURCHASE} /> )
                </span>{" "}
                on the navigation menu, you will see a list of Purchases that you have entered into the system. There are two tabs at the top of the
                screen: "Open" and "Processed". The Open tab shows all Purchases that have not been sent to the supplier. The Processed tab shows all
                purchases that have been sent to the supplier and their disposition.
                <br /> <br />
                The purchases list is sortable by clicking on the column headers. It is also searchable by typing in the search bar at the top of the
                screen.
              </React.Fragment>
            ),
          },

          {
            question: "What are the life cycle stages of a Purchases order?",
            answer: (
              <React.Fragment>
                The purchase order progresses through various stages, each documented via the status field on the list screen. Initially, when a
                purchase order is created, its status is set to "PO Generated." Once the purchase order is sent to the supplier, either by printing or
                emailing, the system updates the status to "Ordered." Upon arrival of the ordered products, users manually mark items as received,
                indicating whether they're fully received or partially received. If any items are placed on backorder, users denote them accordingly.
                This systematic approach helps track the lifecycle of each purchase order efficiently.
              </React.Fragment>
            ),
          },
          {
            question: "Why does it mark a purchase order as “sent” when I click print?  I'm not gonna mail them a PO!",
            answer: (
              <React.Fragment>
                We sure hope you don't! Here's the scenario: You call your supplier and place a phone order because their BTB website says “call for
                inventory”. So you speak to the supplier on the phone (while looking at your PO) to check inventory. They have everything and they
                literally just took your order over the phone. Now you click “print” as an extra way to know that you sent it to them. You don't even
                have to print it. Just click print and cancel. It will mark it as sent.
              </React.Fragment>
            ),
          },
        ],
      },
      {
        title: "Messages",
        anchor: "messages",
        questions: [
          {
            question: "Can ClerkHound email a customer a receipt?",
            answer: (
              <React.Fragment>
                We sure can. When you are on the invoice detail screen, click the email button. This will open a popup screen that will allow you
                brief message. Once you click send, the system will send the receipt along with your message to the customer.
              </React.Fragment>
            ),
          },
          {
            question: "Where in ClerkHound can I see all of the email messages I have sent to a customer?",
            answer: (
              <React.Fragment>
                There are two places in ClerkHound that display email messages. The first is the Customer Detail screen. When you click on a customer
                name in the customer list, you will see a list of emails that have been sent to the customer in the Emails folder. The second place is
                Messages icon{" "}
                <span className="faqMenuIcon">
                  ( <MessagesIcon key={Constants.MESSAGES} /> )
                </span>{" "}
                on the navigation menu. This will provide a list off all the customers that you have sent email messages to.
              </React.Fragment>
            ),
          },
          {
            question: "Why doesn't ClerkHound handle return emails?",
            answer: (
              <React.Fragment>
                ClerkHound is not intended to replace your email program. Our software is primarily designed to facilitate the sending of outgoing
                emails on behalf of our customers using their personal email accounts. In addition, ClerkHound does not include inbox functionality to
                handle return emails. This design choice was made to focus on providing a streamlined solution for outbound communication, rather than
                developing and maintaining the complex features required for managing incoming emails which your email program already handles
                beautifully.
              </React.Fragment>
            ),
          },
          {
            question: "Does ClerkHound send text messages?",
            answer: (
              <React.Fragment>
                We sure do! With a ClerkHound Pro subscription, you gain the ability to send text messages to your customers. Simply navigate to the
                customer detail screen and click the designated text button. If the customer has already opted in, a convenient popup screen will
                appear, allowing you to compose a brief message. Upon clicking send, the system promptly delivers the text message to the customer.
                However, if the customer hasn't yet opted in, you can secure their verbal agreement by following an opt-in script and recording their
                response. Once confirmed, you'll be able to proceed with sending them a text message hassle-free.
              </React.Fragment>
            ),
          },
          {
            question: "Why doesn't my subscription display the text button?",
            answer: (
              <React.Fragment>
                It is likely that you do not have a ClerkHound Pro subscription. To access the text messaging feature, you must upgrade to a Pro
                subscription. This upgrade will enable you to send text messages to your customers seamlessly. Contact our support team at
                912-766-5888 for further assistance. We're here to help!
              </React.Fragment>
            ),
          },
        ],
      },
      {
        title: "Timesheets",
        anchor: "timesheets",
        questions: [
          {
            question: "How do my employees clock in?",
            answer: (
              <React.Fragment>
                ClerkHound has a super easy way for your employees to clock in and out. When they arrive at work, they can click the Timesheet icon{" "}
                <span className="faqMenuIcon">
                  ( <TimesheetsIcon key={Constants.TIMESHEET} /> )
                </span>
                , click the "Clock In" button, and they are on the clock. When they leave, they click the "Clock Out" button. It's that easy.
              </React.Fragment>
            ),
          },
          {
            question: "What happens if my employee forget to clock out?",
            answer: (
              <React.Fragment>
                If you are a manager or owner, you can clock out for those slackers. Just click the clock out for the employee in question. The system
                will ask for authorization and then you can clock them out. You can also adjust the time if you need to for both the clock-in and
                clock-out times. Each time adjustment is logged by the system, ensuring transparency regarding who made the change. If you are an
                employee, you will need to ask your manager or owner to make the adjustment for you.
              </React.Fragment>
            ),
          },
          {
            question: "I need to do payroll, how do I get the total time for my employees?",
            answer: (
              <React.Fragment>
                The best way to get the totals for payroll is to use the Timesheet Totals report. Select the begin and end date of the time period you
                want to see. This will provide a list of employees and the total hours they worked for the time period, no math involved. However, if
                you want to see the individual clock in and clock out times for each employee, select the Completed tab on the Timesheet list screen.
                You can filter by date and employee.
              </React.Fragment>
            ),
          },
        ],
      },
      {
        title: "Reports",
        anchor: "reports",
        questions: [
          {
            question: "What is the Reports view?",
            answer: (
              <React.Fragment>
                ClerkHound offers a variety of reports to help you understand your business better. These reports include Sales, Timesheet totals, Key
                Metrics and Line item detail.
                <br /> <br />
                If you select the Reports icon{" "}
                <span className="faqMenuIcon">
                  ( <ReportsIcon key={Constants.REPORT} /> )
                </span>{" "}
                on the navigation menu, you will see a list of Reports that you can run. Double click on the report you need and select the date
                filter you would like to use or choose a custom date range and press the Run Report button to see the information. For the Line Item
                Detail report, ClerkHound has provided a convenient way to export the report to a csv file for further analysis.
              </React.Fragment>
            ),
          },
          {
            question: "What is Gross Margin?",
            answer: (
              <React.Fragment>
                Gross margin helps you measure the profitability of your business. It helps you identify areas of improvement in operations related to
                sales and pricing. The higher the gross margin, the more profitable your business is. The Key Metrics report provides a detailed
                breakdown of your gross margin, breaking down each component of the calculation. Keep in mind that this calculation only includes the
                cost of goods sold and revenue, it does not include other expenses like rent/mortgage, utilities, payroll, etc. However, it is a great
                start to understanding your business's profitability.
              </React.Fragment>
            ),
          },
          {
            question: "How is Gross Margin calculated?",
            answer: (
              <React.Fragment>
                Gross Margin is calculated by subtracting the cost of goods sold from the revenue (Net Revenue) and dividing the result by the revenue
                minus discounts (Net Sales).
              </React.Fragment>
            ),
          },
          {
            question: "What is Gross Margarine?",
            answer: <React.Fragment>Gross margarine is that butter substitute that you left in your fridge too long.</React.Fragment>,
          },
          {
            question: "How do I find the amount of sales taxes I collected so that I can report it to the government?",
            answer: (
              <React.Fragment>
                The sales report is the one you are looking for. This report provides you the key metrics to understand your taxable and non taxable
                sales. It shows you how much sales tax you've collected. It also describes all of the payments and payment types you have received.
                Also, for you guys that use recurring billing, a section will show you the amount of revenue collected for those subscriptions. One
                stop shopping for all things sales.
              </React.Fragment>
            ),
          },
          {
            question: "How do I find the amount of Reverb online sales I shipped this month?",
            answer: (
              <React.Fragment>
                The sales report has this information. It will show the total amount of Reverb online sales (minus fees deducted) that have been
                marked as shipped. Since Reverb collects and remits taxes on your behalf, you will not see the sales tax collected on those sales.
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>Why are my Reverb orders showing up as non-taxable on my sales report?</React.Fragment>,
            answer: (
              <React.Fragment>
                Reverb collects and remits sales tax on your behalf. Therefore, the orders are marked as non-taxable in ClerkHound. For more
                information on Reverb's tax policy, please visit their website at{" "}
                <a href="https://help.reverb.com/hc/en-us/articles/360019132973-How-state-sales-tax-applies-to-Reverb-orders">
                  How state sales tax applies to Reverb orders.
                </a>
              </React.Fragment>
            ),
          },
          {
            question: "Is there a way to see all the invoices sent to my billing customers?",
            answer: (
              <React.Fragment>
                Sure, there a multiple ways to see the invoices sent to your billing customes. The Invoices tab in Billing will show you all of the
                invoices that have been sent to your billing customers. You can filter that information to a selected time period. You can double
                click on the invoice and you can see the detail information of the invoice. You can also see the billing invoice on the Invoices Menu
                Item, however, you must select to see those invoices in Settings under Display. You can also see the Invoices under the subscription
                for a single customer by clicking the Invoices tab in Folders. Finally you can see the invoices under the Customer menu item by
                clicking Invoices tab in folders. They will also display in the Unpaid folder if the invoice is unpaid.
              </React.Fragment>
            ),
          },
          {
            question: "Can I see all the charges that have been made by my customers for their subscriptions?",
            answer: (
              <React.Fragment>
                Sure, payment for billing can be seen in the invoice under the Authorizations tab in Folders. You can also see last approval/decline
                on the Invoices tab in Folders for each authorization.
              </React.Fragment>
            ),
          },
          {
            question: "I need to do payroll, how do I get the total time for my employees?",
            answer: (
              <React.Fragment>
                You can get your employee's total time by selecting the Timesheet Totals report. Choose the begin and end date of the time period you
                want to see. This will provide a list of employees and the total hours they worked for the time period, no math involved.
              </React.Fragment>
            ),
          },
        ],
      },
      {
        title: "Billing",
        anchor: "billing",
        questions: [
          {
            question: "Does ClerkHound support recurring billing of my customers?",
            answer: <React.Fragment>Yes, ClerkHound fully supports recurring billing.</React.Fragment>,
          },
          {
            question: "What is a Billing template?",
            answer: (
              <React.Fragment>
                A billing template serves as a prearranged framework for structuring subscriptions for customers. It specifies the pricing, frequency,
                and duration for billing of your services. This helps streamline the process of creating subscriptions for your customers. At least
                one template must be created before any subscriptions can be created. Once a template is chosen for a subscription, it can be taylored
                for each customer. The Subscription name, frequency, duration and amount can be indivualized for each customer.
              </React.Fragment>
            ),
          },
          {
            question: "How do I create a Billing template?",
            answer: (
              <React.Fragment>
                To create a billing template, click the Billing Icon{" "}
                <span className="faqMenuIcon">
                  ( <BillingsIcon key={Constants.BILLINGS} /> )
                </span>{" "}
                on the navigation bar and select the "Templates" tab. From there, click the "New template" button. You will be prompted to enter the
                subscription's template name, amount, frequency, duration. Once you have entered the required fields, click Save. This template only
                serves as a pattern for creating future subscriptions. It does not actually create a subscription.
              </React.Fragment>
            ),
          },
          {
            question: "What is a subscription?",
            answer: (
              <React.Fragment>
                A subscription is a customer's agreement to make recurring payments toward a service or product. In ClerkHound, a subscription is
                created by applying a billing subscription template to a customer. The template specifies the name, amount, frequency, and duration of
                the subscription. The subscription can be paused, resumed, or canceled at any time.
              </React.Fragment>
            ),
          },
          {
            question: "How do I create a subscription?",
            answer: (
              <React.Fragment>
                To create a subscription for a customer, click the Billing Icon{" "}
                <span className="faqMenuIcon">
                  ( <BillingsIcon key={Constants.BILLINGS} /> )
                </span>{" "}
                on the navigation bar. From there, click the "New" button. You will be prompted to choose a customer, define their payment method, and
                choose a template to base the subscription on. Once you have entered the required fields, click Save.
                <br />
                <br />
                You can also create a subscription from the customer detail view. Click the "Subscribe" button. This will navigate you to the
                Subscription Detail screen.
                <br />
                <br />
                Finally you can create a subscription directly from the Template definition screen. Click the "Subscribe" button. This will navigate
                you to the Subscription Detail screen.
              </React.Fragment>
            ),
          },
          {
            question: "Can I pause a subscription?",
            answer: (
              <React.Fragment>
                Subscriptions in ClerkHound can easily be paused double clicking a customer's subscription or by selecting the pencil icon next to the
                subscription you want to pause. Click on the status drop down box and choose pause. This subscription will stay paused until you
                manually modify it back to active.
              </React.Fragment>
            ),
          },
          {
            question: "Can I cancel a subscription?",
            answer: (
              <React.Fragment>
                Subscriptions in ClerkHound can easily be cancelled double clicking a customer's subscription or by selecting the pencil icon next to
                the subscription you want to cancel.This is a permanent change. If this customer wants another subscription, a new one will have to be
                created.
              </React.Fragment>
            ),
          },
          {
            question: "Can I delete a subscription?",
            answer: (
              <React.Fragment>
                Subscriptions in ClerkHound can be deleted before they have been started. Once a subscription has been started, it cannot be deleted.
                They will need to be cancelled instead.
              </React.Fragment>
            ),
          },
          {
            question: "I have a customer that wants to purchase an item over the next 3 months, can I use a Billing Subscription for a payment plan?",
            answer: (
              <React.Fragment>
                Yes absolutely, you can use a Billing Subscription for that. First, divide the purchase into three equal payments. Create a new
                subscription with a 3 month duration. Add their payment method and save the subscription. This will automatically bill the customer's
                card for the next three months starting tomorrow. (All new subscriptions must be defined for the future.) If you prefer you can take
                the first payment through normal channels and then create a subscription for the remaining two payments.
              </React.Fragment>
            ),
          },
          {
            question:
              "What if I don't have Billing as part of my subscription, can a customer still purchase item over several months, like a Layaway?",
            answer: (
              <React.Fragment>
                Yes, ClerkHound is very versatile. First you must create an invoice for the product and taking the customer's first payment. The
                customer can call or return for the subsequent payments. The invoice can easily be found under the Customer's list of "Unpaid"
                invoices in the folder section. Or you can find the invoice under the "Open" tab in the Invoice List view as "partially paid".
                Additionally, you can put a tag on the invoice to easily find it later by creating a Dashboard widget for "Layaway" invoices.
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>When does a billing customer becoming "Owing" (past due)?</React.Fragment>,
            answer: (
              <React.Fragment>
                If a customer's payment is declined for a subscription they are immediately considered past due or "Owing". An invoice will be sent to
                their email so they can pay online. They can also call the store to make a payment. You can find this "Owing" customer on the Active
                Tab with a Overdue Balance or the Owing tab with the other customers that have an overdue balance.
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>A billing invoice was created by mistake, how can I delete it?</React.Fragment>,
            answer: (
              <React.Fragment>
                If an billing invoice was created by mistake, you can delete the invoice. Double click on the invoice you want to delete and click the
                delete button. A note will be written in the customer's notes that the invoice was deleted.
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>How do I add, modify or delete a subscriber's Payment Method</React.Fragment>,
            answer: (
              <React.Fragment>
                Add a New Payment Method: Click the Add button next to "Customer Payment Methods." Then enter the card details in the "Enter Payment
                Details" pop-up, then click the save button.
                <br /> <br />
                Modify an Existing Payment Method: Click the pencil icon next to the payment method you want to update. In the "Edit payment details"
                pop-up, make your changes (e.g., name, ZIP code, or expiration date) and click the Save button.
                <br /> <br />
                Delete and Re-add a Payment Method: Pause all subscriptions linked to the payment method, delete the payment method, then add the new
                payment method, then resume subscriptions, ensuring the new method is selected.
                <br /> <br />
                Permanently Delete a Payment Method: Add a new payment method first, move all subscriptions to the new payment method, then delete the
                old payment method.
              </React.Fragment>
            ),
          },
        ],
      },
      {
        title: "Campaigns",
        anchor: "campaigns",
        questions: [
          {
            question:
              "Frequently, my store meets with large groups of people to discuss renting products. How can I easily and securely get their contact and billing information to create a subscription?",
            answer: (
              <React.Fragment>
                <div className="marginBottomhalfem">
                  In the past, you might have asked them to fill out this information on paper forms and then manually entered the details into your
                  billing system. Now, you can capture all the necessary data online by creating a campaign in ClerkHound.
                </div>
                <div className="marginBottomhalfem">
                  <ol>
                    <li>
                      Create a campaign
                      <ul>
                        <li>Design a campaign with a form that collects and securely stores customer and billing information.</li>
                      </ul>
                    </li>
                    <li>
                      Meet with your potential customers
                      <ul>
                        <li>Provide them with a link to the campaign as a web address or a QR Code they can scan.</li>
                        <li>Ask them to fill out the form.</li>
                        <li>
                          Assure them that this information is confidential and any payment information they provide will be securely held in a
                          digital card vault with our payment processor.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Submit the form
                      <ul>
                        <li>
                          Once they submit their information, all of their details will be available on ClerkHound in the Prospects List under the
                          Customers tab.
                        </li>
                        <li>These are not customers yet, but they are potential customers called Prospects.</li>
                      </ul>
                    </li>
                    <li>
                      Match the information
                      <ul>
                        <li>
                          Using the data from their submissions, either match each prospect to an existing customer or create a new customer record.
                        </li>
                        <li>
                          Once the customer record is established, the prospect form will be tied to the customer as an agreement and any additional
                          information provided will be documented in a customer note.
                        </li>
                        <li>
                          The orignal form submission and its agreements can be viewed in the customer detail screen within the agreements folder.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Set up a subscription
                      <ul>
                        <li>
                          Once the customer record has been updated with the agreement, you can then set up a subscription for them to pay the monthly
                          rental fee as you would normally would.
                        </li>
                        <li>Any information provided on the prospect form can be transferred to the subscription's custom fields. </li>
                      </ul>
                    </li>
                  </ol>
                </div>
              </React.Fragment>
            ),
          },
          {
            question: "What are campaigns?",
            answer: (
              <React.Fragment>
                Campaigns are an effective way to gather information from your customers. You can create a campaign with a form to collect billing
                information, contact details, product requests, or any other data you need. Additionally, you can design a campaign with a survey to
                receive customer feedback, or offer a coupon campaign to encourage repeat visits to your store. The possibilities are endless.
              </React.Fragment>
            ),
          },
          {
            question: "How do I create a campaign?",
            answer: (
              <React.Fragment>
                <div className="marginBottomhalfem">
                  To create a campaign, click the Campaigns Icon{" "}
                  <span className="faqMenuIcon">
                    ( <MegaphoneIcon key={Constants.CAMPAIGN} /> )
                  </span>{" "}
                  on the navigation bar. From there, click the "New" button.{" "}
                </div>
                <div className="marginBottomhalfem">
                  You will be prompted to enter the campaign name and a description. The description will be the first thing your prospective customer
                  sees on the invitation email as well as the form they will fill out.
                </div>
                <div className="marginBottomhalfem">
                  Next, decide which customer information is required on the form such as credit card details, address, and phone numbers. You can
                  also can include a series of questions for the customer to answer in the "Campaign Questions" section, like "Student's name?". You
                  don't need to request First Name, Last Name, or Email as these fields are automatically included in the form.
                </div>
                <div className="marginBottomhalfem">
                  Additionally, you can define an agreement for the customer to accept before submitting the form, including terms and conditions,
                  privacy policy, consent to contact them via text or email, etc. Once you have completed the campaign, you can preview it to see what
                  the customer will see.
                </div>
                <div className="marginBottomhalfem">
                  When you are satisfied, click the "Publish" button to make the campaign available to your prospective customers.
                </div>
              </React.Fragment>
            ),
          },
          {
            question: "How do I send the campaign to potential customers?",
            answer: (
              <React.Fragment>
                You can send the campaign to your customers in two ways, by web address or QR Code.
                <ul>
                  <li>
                    To get the web address, click the copy button next to the "Campaign Link" label. You can send the link in an email or text message
                    to your customer, you can post the link on your website or social media platforms for wider reach, or you can use a bulk email
                    service like MailChimp or Constant Contact to send the link to a large group of customers.{" "}
                  </li>
                  <li>
                    If you want to use the QR code, click the QR Code icon to download a copy of the campaign’s QR Code. Print the QR Code on flyers,
                    business cards, or any promotional materials for customers to scan. You can also use the QR Code on your website or social media.
                  </li>
                </ul>
              </React.Fragment>
            ),
          },
          {
            question: "How do I send a campaign to current customers?",
            answer: (
              <React.Fragment>
                <div className="marginBottomhalfem">
                  Navigate to the Customer List screen.
                  <ul>
                    <li>Select the customer you wish to send the campaign to. </li>
                    <li>Click the "Email" button.</li>
                    <li>In the popup window, check the box that allows you to select the campaign.</li>
                    <li>Choose the campaign you want to send to the customer and click "Send." </li>
                    <li>The customer will receive an email with a link to the campaign.</li>
                    <li>The customer can then fill out the form from the link provided in the email.</li>
                  </ul>
                  This is a great way to get feedback from your customers or to offer them a special promotion. You will not need to match the
                  customer to the prospect as the system will automatically match them for you. Once the customer has completed the form, you will see
                  the customer's information in the Agreements folder on the customer detail screen. You can also find the customer's information in
                  the Prospects tab under the "Processed" filter.
                </div>
              </React.Fragment>
            ),
          },
          {
            question: "When I preview a campaign, can I put in my own email address to see what it looks like?",
            answer: (
              <React.Fragment>
                Yes, you will be emailed a link to the campaign, however, the campaign must be in a "Published" status for the link in the email to
                work. Otherwise you will see an error indicating the campaign is not available.
              </React.Fragment>
            ),
          },
          {
            question: "How do I know if a customer has completed/submitted a campaign form?",
            answer: (
              <React.Fragment>
                <div className="marginBottomhalfem">
                  Under the Customer List screen you will see a tab called "Prospects". This is where you will find all of the customers that have
                  provided their email for a campaign. Once they have completed the form, the prospect information will be updated and the status will
                  change to "Pending".
                </div>
                <div className="marginBottomhalfem">
                  If a customer has not completed the form yet, you will only see the email address on the list screen and the prospect status will be
                  "New".
                </div>
              </React.Fragment>
            ),
          },
          {
            question: "How do I convert a prospect to a customer?",
            answer: (
              <React.Fragment>
                <div className="marginBottomhalfem">
                  Under the Customer List screen you will see a tab called "Prospects". Select a prospect to view their details. On the detail view,
                  you will have two options:
                </div>
                <ul>
                  <li>Convert the prospect to a new customer.</li>
                  or
                  <li>Match the prospect to an existing customer.</li>
                </ul>
                <div className="marginBottomhalfem">
                  If you choose to convert the prospect to a new customer, the prospect details will be automatically copied to a new customer record.
                  If you choose to match the prospect to an existing customer, the prospect details will be recorded as an "agreement" on the
                  customer. You can see any and all agreements/campaigns on the customer detail screen in the agreements folder.
                </div>
              </React.Fragment>
            ),
          },
          {
            question: "Can I create a billing subscription for a prospect?",
            answer: (
              <React.Fragment>
                <div className="marginBottomhalfem">
                  No. The prospect must be matched with an existing customer or be used to create a new customer record before a billing subscription
                  may be created for them.
                </div>
              </React.Fragment>
            ),
          },
          {
            question: "What if a customer has lost the email that had the link to the campaign?",
            answer: (
              <React.Fragment>
                <div className="marginBottomhalfem">
                  If the prospect has already followed the original link and provided their email address, you can resend the link to them. You can do
                  this by clicking the Customer icon and selecting the prospects tab. Select the customer in the Prospect list and locate their
                  personalized link on the right side of the screen below the campaign name. Click the "Resend" button to send the link to the
                  customer.{" "}
                </div>

                <div>
                  However, if you cannot find a record for them on the Prospects tab, you must resend the campaign link. The campaign link can be
                  found on the campaign detail page.
                </div>
              </React.Fragment>
            ),
          },
          {
            question: "What should I do if a potential customer does not have an email address?",
            answer: (
              <React.Fragment>
                <div className="marginBottomhalfem">
                  If a potential customer does not have an email address, you can still collect their information by creating a customer record for
                  them. Once you have manually enter the information into the customer record, you will be able to create a subscription for them. You
                  can copy the terms for the campaign into the customer notes for future reference.
                </div>
              </React.Fragment>
            ),
          },
          {
            question: "How do I create a question on my campaign form where the answer to the question should only be one or two words?",
            answer: (
              <React.Fragment>
                <div className="marginBottomhalfem">
                  That's easy, you would use the text box field in your campaign. Let's use an example to illustrate. Assume you want to ask for a
                  student's name and you definitely need this information; here's how you do it: enter {"{{text|Student|Student's Name|Required}}"}{" "}
                  for the field definition in the "Campaign Questions" section.
                </div>
                <div className="marginBottomhalfem">
                  Let's break down the syntax for you, there are four components to the text box form field: type, Fieldname, Placeholder, and
                  Required.
                  <ol>
                    <li>The "type" defines the kind of field you want to appear on your form, in this case, "text". </li>
                    <li>
                      The "Fieldname" is a word that will be used to identify the field when displayed on the prospect detail screen, for example,
                      "Student".
                    </li>
                    <li>
                      The "Placeholder" is the text or instructions that displays in the text box before the customer types in their answer:
                      "Student's Name".
                    </li>
                    <li>
                      "Required" indicates the customer must answer this question before submitting the form. If the information is not essential, you
                      can make it optional by changing "Required" to "Optional."
                    </li>
                  </ol>
                </div>

                <div className="marginBottomhalfem">
                  This information will be available for you to review later on the prospect detail screen. On the "Prospects" tab in the Customer
                  List screen, select an individual prospect. On the detail screen, you will see a section labeled "Additional Data". The answers to
                  the campaign form questions will appear there. The "fieldname" attribute used in the field definition followed by the answer will be
                  displayed. For example, "Student: John Doe"
                </div>
              </React.Fragment>
            ),
          },
          {
            question: "How do I create a question in my campaign form where the answer is one or more sentences?",
            answer: (
              <React.Fragment>
                <div className="marginBottomhalfem">
                  It's simple, you would use the "textarea" field in your campaign. For example, let's assume you want a comments section on your form
                  but you want to make it optional; here's how you do it: enter {"{{textarea|Comments|Add any additional comments here|Optional}}"} in
                  the "Campaign Questions" section.
                </div>
                <div className="marginBottomhalfem">
                  Let's break down the syntax for you, there are four components to the textarea form field: type, Fieldname, Placeholder, and
                  Optional
                  <ol>
                    <li>The "type" defines the kind of field you want to appear on your form, in this case, "textarea". </li>
                    <li>
                      The "Fieldname" is a word that will be used to identify the field when displayed on the prospect detail screen: "Comments".
                    </li>
                    <li>
                      The "Placeholder" is the text or instructions that displays in the text box before the customer types in their answer: "Add any
                      additional comments here".
                    </li>
                    <li>
                      "Optional" indicates the customer does not have to answer this question before submitting the form. If the information is
                      essential, you can make it required by changing "Optional" to "Required."
                    </li>
                  </ol>
                </div>

                <div className="marginBottomhalfem">
                  This information will be available on the "Prospects" tab in the Customer List screen. After you select a prospect, you will then
                  see a section labeled "Additional Data". The answers to the campaign form questions will appear there. For example, "Comments: I
                  love your store!"
                </div>
              </React.Fragment>
            ),
          },
          {
            question: "How do I create a question where the customer can choose between a short list of options?",
            answer: (
              <React.Fragment>
                <div className="marginBottomhalfem">
                  It's straightforward, you would use the Radio button field in your campaign. For example, let's assume you want a customer to choose
                  between a list of colors, and you really need to know this information to process their request; here's how you do it: enter{" "}
                  {"{{radio|Color|red,dark blue,light blue,green|Required}}"} in the "Campaign Questions" section.
                </div>
                <div className="marginBottomhalfem">
                  Let's break down the syntax for you: There are four components to the radio button field: type, Fieldname, a list of comma-separated
                  options and Required.
                  <ol>
                    <li>The "type" defines the kind of field you want to appear on your form, in this case, "radio". </li>
                    <li>The "Fieldname" is a word that will be used to identify the field when displayed on the prospect detail screen: "Color".</li>
                    <li>
                      The "list of options" is the text that will appear next to the radio button, in this case, "red,dark blue,light blue,green".
                      This list must be separated by commas. The result is a radio button on your form with the options red, dark blue, light blue,
                      and green for the customer to choose from. The values in the list of options cannot contain a comma.
                    </li>
                    <li>
                      "Required" indicates the customer must answer this question before submitting the form. If the information is not essential, you
                      can make it optional by changing "Required" to "Optional."
                    </li>
                  </ol>
                </div>
                <div className="marginBottomhalfem">
                  This information will be available on the "Prospects" tab in the Customer List screen. After you select a prospect, you will then
                  see a section labeled "Additional Data". The answers to the campaign form will appear there. For example "Color: red"
                </div>
                <div className="marginBottomhalfem">
                  You may have noticed that the syntax for the drop down box is very similar to the radio button. The only difference is how the
                  options are presented. Radio buttons display all of the options to the user at once, while the drop down box doesn't reveal its
                  options until the user clicks on the box. Drop down boxes are very useful when you have a long list of options. Where radio buttons
                  are better when you have a short list of options. It all depends on your preference.
                </div>
              </React.Fragment>
            ),
          },
          {
            question: "How do I create a question where the customer can choose between a long list of options?",
            answer: (
              <React.Fragment>
                <div className="marginBottomhalfem">
                  It's straightforward, you would use the Select list field in your campaign. For example, let's assume you want a customer to choose
                  a U.S. state; here's how you do it: enter {"{{select|State|Alabama,Alaska,Arizona,Arkansas,California,Colorado|Optional}}"} in the
                  "Campaign Questions" section.
                </div>
                <div className="marginBottomhalfem">
                  Let's break down the syntax for you: There are four components to the select list field: type, Fieldname, a list of comma-separated
                  options, and Optional.
                  <ol>
                    <li>The "type" defines the kind of field you want to appear on your form, in this case, "select". </li>
                    <li>The "Fieldname" is a word that will be used to identify the field when displayed on the prospect detail screen: "State".</li>
                    <li>
                      The "list of options" is the text that will appear in the select list, in this case, "Alabama, Alaska, Arizona, Arkansas,
                      California, Colorado". This list must be separated by commas. The result is a select list on your form with the options Alabama,
                      Alaska, Arizona, Arkansas, California, Colorado for the customer to choose from. The values in the list of options cannot
                      contain a comma.
                    </li>
                    <li>
                      "Optional" indicates the customer does not have to answer this question before submitting the form. If the information is
                      essential, you can make it required by changing "Optional" to "Required."
                    </li>
                  </ol>
                </div>
                <div className="marginBottomhalfem">
                  This information will be available on the "Prospects" tab in the Customer List screen. After you select a prospect, you will then
                  see a section labeled "Additional Data". The answers to the campaign form will appear there. For example "State: Alaska"
                </div>
                <div className="marginBottomhalfem">
                  You may have noticed that the syntax for the drop down box is very similar to the radio button. The only difference is how the
                  options are presented. Radio buttons display all of the options to the user at once, while the drop down box doesn't reveal its
                  options until the user clicks on the box. Drop down boxes are very useful when you have a long list of options. Where radio buttons
                  are better when you have a short list of options. It all depends on your preference.
                </div>
              </React.Fragment>
            ),
          },
          {
            question: "Can I add a checkbox to my form?",
            answer: (
              <React.Fragment>
                <div className="marginBottomhalfem">
                  Certainly, checkboxes are used to let a customer answer a yes or no question. For example, let's assume you want to know if the
                  customer would like to include a gift bag with their purchase; here's how you do it: enter{" "}
                  {"{{checkbox|Gift bag|I would like to include a gift bag with my purchase|Required}}"} You will need to repeat this for every
                  checkbox you want to include on the form.
                </div>
                <div className="marginBottomhalfem">
                  Let's break down the syntax for you: type, Fieldname, the yes/no question, and Required.
                  <ol>
                    <li>The "type" defines the kind of field you want to appear on your form, in this case, "checkbox". </li>
                    <li>
                      The "Fieldname" is a word that will be used to identify the field when displayed on the prospect detail screen: "Gift bag".
                    </li>
                    <li>
                      The "yes/no question" is the text that will appear next to the checkbox, in this case, "I would like to include a gift bag with
                      my purchase". This will create a checkbox on your form for the customer to select if they are interested.
                    </li>
                    <li>
                      "Required" indicates the customer must answer this question before submitting the form. If the information is not essential, you
                      can make it optional by changing "Required" to "Optional."
                    </li>
                  </ol>
                </div>

                <div className="marginBottomhalfem">
                  This information will be available on the "Prospects" tab in the Customer List screen. After you select a prospect, you will then
                  see a section labeled "Additional Data". The answers to the campaign form will appear there. For example, "Gift bag: Yes"
                </div>
              </React.Fragment>
            ),
          },
          {
            question: "How do I create a question where the customer can choose a date?",
            answer: (
              <React.Fragment>
                <div className="marginBottomhalfem">
                  You would use the date field in your campaign. For example, let's assume you want the customer to select a pickup date; here's how
                  you do it: enter {"{{date|Pickup date|Optional}}"} in the "Campaign Questions" section.
                </div>
                <div className="marginBottomhalfem">
                  Let's break down the syntax for you: There are only three components for a date field: type, Fieldname, and Optional.
                  <ol>
                    <li>The "type" defines the kind of field you want to appear on your form, in this case, "date". </li>
                    <li>
                      The "Fieldname" is a word that will be used to identify the field when displayed on the prospect detail screen: "Pickup date".
                    </li>
                    <li>
                      "Optional" indicates the customer does not have to answer this question before submitting the form. If the information is
                      essential, you can make it required by changing "Optional" to "Required."
                    </li>
                  </ol>
                </div>
                <div className="marginBottomhalfem">
                  You will need to provide a label for the date field to help the prospect know what you are asking for. For example, the text "Please
                  select a pickup date" followed by the field definition.
                </div>
                <div className="marginBottomhalfem">
                  This information will be available on the "Prospects" tab in the Customer List screen. After you select a prospect, you will then
                  see a section labeled "Additional Data". The answers to the campaign form will appear there. For example, "Pickup date: 01/01/2099".
                </div>
              </React.Fragment>
            ),
          },
          {
            question: "How do I create a question where the customer can choose a time?",
            answer: (
              <React.Fragment>
                <div className="marginBottomhalfem">
                  You would use the time field in your campaign. For example, let's assume you want the customer to select a pickup time; here's how
                  you do it: enter {"{{time|Pickup time|Required}}"} in the "Campaign Questions" section.
                </div>
                <div className="marginBottomhalfem">
                  Let's break down the syntax for you: There are only three components for a time field: type, Fieldname and Required.
                  <ol>
                    <li>The "type" in this case is "time". </li>
                    <li>
                      The "Fieldname" is a word that will be used to identify the field when displayed on the prospect detail screen: "Pickup time".
                    </li>
                    <li>
                      "Required" indicates the customer must answer this question before submitting the form. If the information is not essential, you
                      can make it optional by changing "Required" to "Optional."
                    </li>
                  </ol>
                </div>
                <div className="marginBottomhalfem">
                  You will need to provide a label for the time field to help the prospect know what you are asking for. For example, the text "Please
                  select a pickup time" followed by the field definition.
                </div>
                <div className="marginBottomhalfem">
                  This information will be available on the "Prospects" tab in the Customer List screen. After you select a prospect, you will then
                  see a section labeled "Additional Data". The answers to the campaign form will appear there. For example, "Pickup time: 11:05 AM".
                </div>
              </React.Fragment>
            ),
          },
        ],
      },
      {
        title: "Gift Cards",
        anchor: "giftcards",
        questions: [
          {
            question: "Does ClerkHound support gift cards?",
            answer: (
              <React.Fragment>
                Yes, ClerkHound supports gift cards. A $0 gift card product has already been created for you and the associated gift card SKU is
                automatically set up for you in settings.
              </React.Fragment>
            ),
          },
          {
            question: "How do I sell gift card?",
            answer: (
              <React.Fragment>
                To sell a gift card, simply add that gift card product to the invoice. You will be prompted for the gift card number and the amount to
                be stored. (The gift card number can normally be found on the back of the physical card.)
              </React.Fragment>
            ),
          },
          {
            question: "How do I add more funds to a gift card?",
            answer: (
              <React.Fragment>
                To add more funds to a gift card, simply add that gift card product to the invoice. You will be prompted for the existing gift card
                number and the amount to be stored. The system will add the new amount to the existing amount.
              </React.Fragment>
            ),
          },
          {
            question: "How do my customers redeem a gift card?",
            answer: <React.Fragment>To redeem a gift card, use the gift card payment option on the pay screen at check out time.</React.Fragment>,
          },
          {
            question: "How do I check the balance on a customer's gift card?",
            answer: (
              <React.Fragment>
                You can check the balance of a gift card by clicking on the gear menu ( <FontAwesomeIcon icon={faGear} className="highlight" /> ) and
                selecting the "Check Gift Card Balance" option. From there you can enter the gift card number and see the balance.
              </React.Fragment>
            ),
          },
          {
            question: "Can I print my own gift cards and/or certificates?",
            answer: (
              <React.Fragment>
                Sure, you can print your own gift cards/gift certificates. The ID number is the only thing that is important because it must be unique
                and cannot be duplicated. You will enter this number at the time of the sale and the customer will use it to redeem the gift card. You
                can print the gift card number on a paper card or certificate.
              </React.Fragment>
            ),
          },
          {
            question: "Can I buy plastic, credit card-style gift cards?",
            answer: (
              <React.Fragment>
                Sure, you can buy plastic gift cards. You can buy them from any office supply store or online. Many of our customers have had success
                using K12Print.com. They have a variety of gift card styles and can print your gift card number and product UPC on the card for you.
              </React.Fragment>
            ),
          },
          {
            question: "Why is ClerkHound not prompting for the gift card number and amount of my gift card?",
            answer: (
              <React.Fragment>
                Gift Card products must be identified by their sku in settings. Ensure that the sku on your gift card product matches the sku defined
                in settings under the "Gift Card" category.
              </React.Fragment>
            ),
          },
        ],
      },
      {
        title: "Credit Cards",
        anchor: "creditcards",
        questions: [
          {
            question: "Does ClerkHound allow me to take credit card payments?",
            answer: (
              <React.Fragment>If you have a merchant account with our payment processor, then you can take credit card payments.</React.Fragment>
            ),
          },
          {
            question: "What is the difference between a manual entry credit transaction and a swipe/tap/chip transaction?",
            answer: (
              <React.Fragment>
                For Manual entry credit card transactions, the ClerkHound user manually types in the Card number, expiration date, and cvv. This type
                of transaction costs the merchant more money per transaction because it is considered a card not present transaction. A swipe/tap/chip
                transaction is when the card is physically swiped, tapped, or inserted into the card reader. This type of transaction costs the
                merchant less money per transaction because it is considered a card present transaction..
              </React.Fragment>
            ),
          },
          {
            question: "Can I enable and disable my card reader myself?",
            answer: (
              <React.Fragment>
                Yes, you can disable your card reader in settings under Cards. By doing this, manual card entry will default on the pay screen.
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>Can I rename my card readers to something like "Front Counter" and "Service Reader"?</React.Fragment>,
            answer: (
              <React.Fragment>
                Yes, in Settings under Credit Card, locate HandPoint Card Reader section. Enter a terminal name next to the card reader and press tab.
                The terminal name will now be displayed on the pay screen.
              </React.Fragment>
            ),
          },
          {
            question: "What is a partial approval for a credit card transaction?",
            answer: (
              <React.Fragment>
                If partial approvals are allowed for an invoice and the available balance on a customer's card is less than the amount due, the
                available balance will be applied to the amount due. The remaining balance is expected to be paid using another method. This situation
                often arises when a customer uses a Visa or MasterCard gift card and the balance is less than the amount due.
              </React.Fragment>
            ),
          },
          {
            question: "Can I disable partial approvals for payments on Invoices?",
            answer: (
              <React.Fragment>
                Yes, on the manual credit card entry page on the pay screen there is a checkbox that you can check or uncheck to accept partial
                approvals.
              </React.Fragment>
            ),
          },
          {
            question: "Can I make the pay screen default to declining partial approvals?",
            answer: (
              <React.Fragment>
                Yes, you can set the default value for partial approvals to approve or decline in settings under the Credit Card tab. The Partial
                Approval Defaults section allows you to define the default behavior for accepting partial payments for invoices. This value can be
                changed on the pay screen for each transaction.
              </React.Fragment>
            ),
          },
          {
            question: "Can I disable partial approvals for payments on Subscriptions?",
            answer: (
              <React.Fragment>
                Yes, you can disable partial approvals for subscriptions in Settings on the Credit Card tab. The Partial Approval Defaults section
                allows you to define the default behavior for accepting partial payments for subsciptions using Vaulted Payments.
              </React.Fragment>
            ),
          },
          {
            question: "What is a vaulted payment method?",
            answer: (
              <React.Fragment>
                A vaulted payment method is when you store a customer's card details in the digital Card Vault with our payment processor for later
                use. Our payment processor is authorized by the card associations to securely store credit card information. We transmit credit card
                details through secure channels to our payment processor, who then stores this information in their digital "Card Vault". In return,
                our payment processor returns us a unique id for that customer/card. ClerkHound is then able to use this id to make card-not-present
                payments and recurring billing transactions.
              </React.Fragment>
            ),
          },
          {
            question: "Can I add a Customer's credit card information to the vault?",
            answer: (
              <React.Fragment>
                Yes, on the Customer tab, under the "Card Vault" folder, press the "Add Customer to the Vault" button to add the card details. This
                will show a popup screen where you can enter the card details. Once you have entered the card details, press the "Save" button. The
                card details will be stored in the vault.
              </React.Fragment>
            ),
          },
          {
            question: "Can a Customer add their own credit card information to the vault?",
            answer: (
              <React.Fragment>
                Yes, on the Customer tab, under the "Card Vault" folder, press the "Request a Link to the vault" button to create a link to send to
                the customer. Copy the link and either email or text it to the customer. The customer will access the link on their computer or phone
                and add their card details. Once the customer has entered the card details, the card will be stored in the vault.
              </React.Fragment>
            ),
          },
        ],
      },
      {
        title: "Importing/Exporting",
        anchor: "importing",
        questions: [
          {
            question: "How do I import new data from a different system?",
            answer: (
              <React.Fragment>
                Before you import data into ClerkHound, make sure you have it organized in a spreadsheet from your previous system. Check for any
                errors like strange characters or missing information. ClerkHound requires the data to be in a CSV format. You can use Excel or Google
                Sheets to create a CSV file.
                <br />
                <br />
                If you are importing customers, at a minimum, you must have the "First Name" and "Last Name" of the customer in the spreadsheet. You
                can also include other optional fields like email address, mobile number, address, etc.
                <br />
                <br />
                If you are importing suppliers, at a minimum, you must have the "Supplier Name" and at least one other optional field like email
                address, mobile number, address, etc.
                <br />
                <br />
                If you are importing products, you must have the "Product Name" and the "Sell Price" at a minimum. All other fields are optional;
                however, if you want to track profits, include the supplier and cost. Remember to import suppliers first if you're including this
                info.
                <br />
                <br />
                When you have your spreadsheet ready, click the gear menu ( <FontAwesomeIcon icon={faGear} className="highlight" /> ) and select
                "Import." From there, follow the instructions to map the columns in your spreadsheet to the fields in ClerkHound. Detailed directions
                are provided on the import screen. Please read them carefully.
                <br />
                <br />
                After importing, you'll see stats on the import results. If any records failed, you can download a spreadsheet of those records to fix
                and try importing again.
              </React.Fragment>
            ),
          },
          {
            question: "How do I mass update my customers, suppliers and products?",
            answer: (
              <React.Fragment>
                Start by exporting your data from ClerkHound. Click the gear menu ( <FontAwesomeIcon icon={faGear} className="highlight" /> ) and
                select "Export." Follow the prompts to export your data. <br />
                <br />
                Next, make changes to the fields you want to update in the spreadsheet, without deleting any other fields. Then click the gear menu ({" "}
                <FontAwesomeIcon icon={faGear} className="highlight" /> ) and select "Import." ClerkHound will recognize the file as a mass update. It
                will ask if you want to update your existing customers, suppliers or products. Follow the prompts to update your data, specifying
                which fields you want to update. Detailed instructions are provided on the import screen. Make sure to read them carefully.
                <br />
                <br />
                After importing, you'll see stats on the update results. If any records failed, you can download a spreadsheet of those records to fix
                and try updating again.
              </React.Fragment>
            ),
          },
          {
            question: "Can I import the invoices, orders, repairs from my old system?",
            answer: (
              <React.Fragment>
                No, the complex relationships within an invoice, order, or service make it impossible to import them from another system.
              </React.Fragment>
            ),
          },
        ],
      },
      {
        title: "Settings",
        anchor: "settings",
        questions: [
          {
            question: "How do I access Settings?",
            answer: (
              <React.Fragment>
                You can access settings by clicking on the gear menu ( <FontAwesomeIcon icon={faGear} className="highlight" /> ) and selecting the
                "Settings" option.
              </React.Fragment>
            ),
          },
          {
            question: "How do I change my store settings, like name, address, and phone number?",
            answer: (
              <React.Fragment>
                Client information can be accessed under the "Client Information" category in settings. It is the first category to display. This
                information displays on your receipts and other printer material. You can update any of the fields found here. When tab out of the
                field, the information is automatically saved.
              </React.Fragment>
            ),
          },
          {
            question: "Can I upload a new logo for my store?",
            answer: (
              <React.Fragment>
                Yes, the client logo can be found under the "Client Information" category in Settings. The Client logo must be an SVG with an aspect
                ratio of 1:1. A viewbox size of 500x500 is recommended. Drag and drop the SVG file in the indicated area. The new logo will now
                display on your receipts and other printer material. The only type of file that can be uploaded is an SVG. If you don't have an SVG
                file, you can contact support@clerkhound.com and we can help you create one.
              </React.Fragment>
            ),
          },
          {
            question: "How do I add a new employee?",
            answer: (
              <React.Fragment>
                You can add a new employee under the "Users" category in Settings. Click the Add button and enter the Employees Full Name, their email
                address, and choose their employee type (Employee or Manager). As you tab out of each field, the system will automatically save. An
                email will be sent to the new employee with a link to set their password. This email link will expire in 24 hours. If the user does
                not set their password within that time, they can access the login screen and click the "Need help login in?" link to set their
                password.
              </React.Fragment>
            ),
          },
          {
            question: "What is the difference between an employee, manager, and owner?",
            answer: (
              <React.Fragment>
                Employees only have access to the basic point-of-sale features. <br />
                <br />
                Managers have access to all the point-of-sale features plus editing order dates, editing timesheets of other employees, adding payment
                methods to the card vault, paying invoices using vaulted payment methods, updating product listings from invoices, and viewing/editing
                recurring billing information. <br />
                <br />
                The Owner has access to all features listed above, plus editing settings, deleting closed invoices, updating closed invoice statuses,
                and importing/exporting data.
              </React.Fragment>
            ),
          },
          {
            question: <React.Fragment>What happens when I turn on "Reply-To Allowed" for an employee?</React.Fragment>,
            answer: (
              <React.Fragment>
                When "Reply-To Allowed" is turned on for an employee, the employee's email address is used as the reply-to address instead of the
                store email address defined in Client Information. <br />
                <br />
                When ClerkHound sends out emails, the reply-to email address is the store's email address. However, there are circumstances where the
                response should go directly to the employee. For instance, the service technician is corresponding with the customer about a repair.
                The technician sends the first email from ClerkHound, but when the customer responds, you would want it to go to the technican's email
                instead of the store's email address to facilitate the conversation.
              </React.Fragment>
            ),
          },
          {
            question: "Where do I enter my sales tax rate(s)",
            answer: (
              <React.Fragment>
                Sales tax rates are defined under the "Sales Tax" category in settings. If a new tax is created for your area, you can click the add
                button and enter the tax name and rate. Be sure to enter the tax amount as a decimal. You can add as many tax rates as you need.
                ClerkHound uses these tax rates to determine the tax amount on invoices. You can see your total tax rate above the add button. As soon
                as you tab out of the field, the system will automatically save the changes.
              </React.Fragment>
            ),
          },
          {
            question: "Can I customize the name and email address shown on messages sent from ClerkHound?",
            answer: (
              <React.Fragment>
                Yes, the name and email address used for emails sent from ClerkHound can be found under the "Message" category in settings. The Name
                entered here will be the "friendly" name associated with the email address. The Email Address entered here will be the address used to
                send the emails from ClerkHound. This ensures that all communications look like they are coming from your store. The email address
                must be a valid email address and will be validated by our provider, Amazon. The BCC (blind carbon copy) address is used to send a
                blind copy of all emails sent from ClerkHound. This is useful for keeping a record of all emails sent from all of your employees.
              </React.Fragment>
            ),
          },
          {
            question: "Can I send myself a copy of every email sent by ClerkHound?",
            answer: (
              <React.Fragment>
                Yes, you can set up a BCC (blind carbon copy) email address that will cause a copy of every email to be sent to a specified address.
                This is useful for keeping a record of all emails sent from all of your employees that use ClerkHound to send emails.
              </React.Fragment>
            ),
          },
          {
            question: "Can I change the number of customer/product/supplier rows shown per page in the list views?",
            answer: (
              <React.Fragment>
                You KNOW IT!, under the "Display" category in settings, you can define just the right number of records to display for your screen
                size.
              </React.Fragment>
            ),
          },
          {
            question:
              "Can I change the number of customer/product/supplier rows shown in search results, such as when adding a product to an invoice?",
            answer: (
              <React.Fragment>
                Sure, you can adjust the number of results that show when you look up a product or customer on an invoice/order etc., screen. This
                setting is found under the "Display" category in settings. This setting refers to the number of results that show when you look up a
                product on an Invoice, Order, etc., screen.
              </React.Fragment>
            ),
          },
          {
            question: "How do I designate an employee as a service technician?",
            answer: (
              <React.Fragment>
                On the Service detail view, you can assign a service order to an employee using the Technician drop down list. This drop down contains
                a list of the employees you have identified as technicians under the "Service" category in settings. To assign an employee as a
                technician they must first be defined as a user (under the "Users" category). Once they are a user, you can click the "Add" button
                under Technicians and select the employee from the drop down list. This will create a list of eligible technicians for the repair.
              </React.Fragment>
            ),
          },
          {
            question: "How do I add/edit/remove a service family?",
            answer: (
              <React.Fragment>
                A service family is a list of categories that you use for your services. You can define a list of service families under the "Service"
                category in settings. To add a new service family, click the "Add" button and enter the name of the service family. When you tab out
                of the field it will automatically save. To edit a service family, just change the value displayed. To delete a service family, click
                the trash can ( <FontAwesomeIcon icon={faTrashCan} /> ) icon next to the service family name.
              </React.Fragment>
            ),
          },
          {
            question: "How do I add my Reverb API key to ClerkHound?",
            answer: (
              <React.Fragment>
                If you have an account with Reverb, Clerkhound can publish your product information programmatically and update the inventory every
                time you sell an item that also is on Reverb. The Reverb API key is entered under the "Third Party" category in settings. Enter the
                API key in the field provided. The information will be saved when you tab out of the field.
              </React.Fragment>
            ),
          },
          {
            question: "How do I get my Reverb API key for ClerkHound?",
            answer: (
              <React.Fragment>
                1 - You must have a Reverb account. If you don't, you can sign up at{" "}
                <a href="https://reverb.com/" target="_blank" rel="noreferrer" className="inlineLink">
                  Reverb.com.
                </a>
                <br />2 - In your Reverb account, you must define one or more shipping profiles. You can access shipping profiles with the following
                link:{" "}
                <a href="https://reverb.com/my/shop/edit" target="_blank" rel="noreferrer" className="inlineLink">
                  Shop Settings.
                </a>
                <br />3 - You must "Generate New Token" with the necessary OAuth scopes: public, read_listings, write_listings, read_profile,
                read_orders and write_orders. You can access Personal Access Tokens with the following link:{" "}
                <a href="https://reverb.com/my/api_settings" target="_blank" rel="noreferrer" className="inlineLink">
                  Reverb API key.
                </a>
              </React.Fragment>
            ),
          },
        ],
      },
    ];
    let category = 0;

    // Get the url param "category"
    const urlParams = new URLSearchParams(window.location.search);
    const urlCategory = urlParams.get("category");
    if (urlCategory) {
      // Find the index of the category in the categories list
      category = this.categories.findIndex(cat => cat.anchor === urlCategory);
      if (category === -1) {
        category = 0;
      }
    }

    this.state = {
      selectedCategory: category,
      categories: this.categories,
      searchkey: "",
    };
  }

  render() {
    let categories = this.state.categories.map((category, index) => {
      return (
        <div
          className={"faqCategory" + (this.state.selectedCategory === index ? " selected" : "")}
          key={"category" + index}
          data-key={"category" + index}
          onClick={() => {
            this.setState({ selectedCategory: index });
          }}
        >
          {category.title}
        </div>
      );
    });
    if (categories?.length === 0) {
      categories = (
        <div className="span2 italic centerAligned topMargin2em">
          <FontAwesomeIcon icon={faPaw} /> Capo couldn't fetch that! Try again. <FontAwesomeIcon icon={faPaw} />
        </div>
      );
    } else {
      categories = <div>{categories}</div>;
    }

    return (
      <div className="areaFAQContainer">
        <div className="faqLogo">
          <Capo />
          <span className="faqTitle"> ask Capo . . .</span>
          <span className="faqSearch">
            <SearchBar
              appState={{}}
              handleSearchChange={this.handleChangeSearch}
              searchkey={this.state.searchkey}
              handleClearSearch={event => {
                this.setState({ searchkey: "", categories: this.categories, selectedCategory: 0 });
              }}
              searchPlaceholder={"Search FAQ"}
              fieldref={this.searchInputRef}
              autoFocus={true}
            />
          </span>
        </div>
        <div className="faqContent">
          {categories}
          <FaqSection category={this.state.categories[this.state.selectedCategory]} />
        </div>
      </div>
    );
  }

  handleChangeSearch = (id, value) => {
    // Filter the categories
    let cats = this.categories.filter(category => {
      return (
        Helper.containsString(category.title, value) ||
        category.questions.some(question => {
          const qres = Helper.containsString(question.question, value.toLowerCase());
          const ares = Helper.containsString(question.answer, value.toLowerCase());
          return qres || ares;
        })
      );
    });
    // Filter the questions within the categories
    cats = cats.map(cat => {
      return {
        title: cat.title,
        questions: cat.questions.filter(
          q => Helper.containsString(q.question, value.toLowerCase()) || Helper.containsString(q.answer, value.toLowerCase())
        ),
      };
    });
    // Filter out empty or undefined categories
    cats = cats.filter(cat => cat?.questions?.length > 0);
    this.setState({
      searchkey: value,
      categories: cats,
      selectedCategory: 0,
    });
  };
}

export default Faq;
