import React from "react";

// Components
import Switch from "./Switch.jsx";

// Style
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudBolt, faEnvelope, faLock, faPaw } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import * as Constants from "./Constants.jsx";
import * as Helper from "./Helper.jsx";
import Capo from "./img/Capo.js";
import LogoIcon from "./img/LogoIcon";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      pass: "",
      remember: false,
      type: "password",
      recover: false,
      recoverSent: false,
    };
    this.usernameInput = React.createRef();
    this.passwordInput = React.createRef();
  }

  componentDidMount() {
    const username = localStorage.getItem(Constants.LOCAL_STORAGE_REMEMBER_ME);
    if (username) {
      this.setState({ user: username, remember: true });
      this.passwordInput.current.focus();
    } else {
      this.usernameInput.current.focus();
    }
    // Load the recaptcha script
    const script = document.createElement("script");
    script.id = "chrecaptcha";
    script.async = true;
    script.src = "https://www.google.com/recaptcha/api.js?render=6LfKkocpAAAAADZU8RE6ZR0A2qME3z3I2ZqrRMOW";
    document.getElementById("chrecaptchaElement").appendChild(script);
  }

  componentWillUnmount() {
    // Remove the recaptcha script
    const script = document.getElementById("chrecaptcha");
    if (script) {
      script.remove();
    }
    // Remove class=grecaptcha-badge elements
    const elements = document.getElementsByClassName("grecaptcha-badge");
    if (elements.length > 0) {
      if (elements[0].parentNode.id !== "chrecaptchaElement" && elements[0].parentNode.tagName !== "html") {
        elements[0].parentNode.parentNode.removeChild(elements[0].parentNode);
      }
    }
  }

  render() {
    const buttontext = this.props.waiting ? <FontAwesomeIcon icon={faCloudBolt} /> : this.state.recover ? "Recover Password" : "Log In";
    const buttonclass = this.isReadyToSubmit() && !this.props.waiting ? "action-button" : "action-button save-disabled";
    let loginError = "";
    if (this.props.error) {
      loginError = <p className="error">{this.props.errorText || Constants.MESSAGE_LOGIN_ERROR}</p>;
    }
    const title = this.state.recover ? "Recover Password" : "Account Login";
    const instructions = this.state.recover ? (
      <div className="instructions">Enter your email address below and we will send you a link to reset your password.</div>
    ) : (
      ""
    );
    let eyeball = <FontAwesomeIcon icon={faEyeSlash} />;
    if (this.state.type === "password") {
      eyeball = <FontAwesomeIcon icon={faEye} />;
    }
    const passwordInput = this.state.recover ? (
      ""
    ) : (
      <div className="input-container svgGridCenter">
        <FontAwesomeIcon icon={faLock} />
        <input
          type={this.state.type}
          name="pass"
          id="pass"
          placeholder="password"
          maxLength={50}
          autoComplete="off"
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          required
          value={this.state.pass}
          data-private
          ref={this.passwordInput}
        />
        <span className="eyeball gridCenter" onClick={this.handleTogglePassword}>
          {eyeball}
        </span>
      </div>
    );
    const rememberMe = this.state.recover ? (
      ""
    ) : (
      <div className="remember-container gridCenter">
        <Switch
          label="Remember me?"
          fieldname="remember"
          handleChange={this.handleChange}
          checked={this.state.remember}
          elementid="switch-remember-me"
        />
      </div>
    );
    const recoverText = this.state.recover ? "Return to Login" : "Need help logging in?";
    const button = this.state.recoverSent ? (
      <span>An email has been sent to the specified address. The link to reset your password is good for the next 15 minutes.</span>
    ) : (
      <span id="login-button" className={buttonclass} onClick={this.handleSubmit}>
        {buttontext}
      </span>
    );
    let createAccountUrl = window.location.origin + "?action=" + Constants.CREATE_ACCOUNT;
    return (
      <div className="app-body">
        <div className="login">
          <div className="login-logo">
            <Capo />
          </div>
          <div className="login-wrapper gridCenter">
            <h3>{title}</h3>
            <div className="login-container">
              {instructions}
              <div className="input-container svgGridCenter">
                <FontAwesomeIcon icon={faEnvelope} />
                <input
                  type="text"
                  name="user"
                  id="user"
                  maxLength={100}
                  placeholder="email"
                  autoComplete="off"
                  onChange={this.handleChange}
                  onKeyDown={this.handleKeyDown}
                  required
                  value={this.state.user}
                  ref={this.usernameInput}
                />
              </div>
              {passwordInput}
              {rememberMe}
              {loginError}
              {button}
              <div className="forgotPassword">
                <span onClick={this.handleClickForgotPassword}>{recoverText}</span>
              </div>
              <div className="createAccount">
                <div>
                  <FontAwesomeIcon icon={faPaw} />
                </div>
                <a href={createAccountUrl}>Create a merchant account</a>
              </div>
              <div className="recaptchaDisclaimer">
                This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
              </div>
            </div>
          </div>
          <div className="login-title">
            <h1 className="title gridCenter">
              <LogoIcon />
            </h1>
          </div>
        </div>
      </div>
    );
  }

  handleClickForgotPassword = () => {
    this.setState(prevState => ({
      recover: !prevState.recover,
      recoverSent: false,
    }));
  };

  handleTogglePassword = () => {
    this.setState(prevState => ({ type: prevState.type === "password" ? "text" : "password" }));
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: Helper.getTargetValue(event),
    });
  };

  handleSubmit = () => {
    if (this.isReadyToSubmit() && !this.props.waiting) {
      if (this.state.recover) {
        this.props.handleRecover(this.state.user, result => {
          this.setState({ recoverSent: true });
        });
      } else {
        this.setState({ recoverSent: false, type: "password" }, () => {
          this.props.handleLogin(this.state.user, this.state.pass, this.state.remember);
        });
      }
    }
  };

  handleKeyDown = event => {
    if (event.keyCode === 13) {
      this.handleSubmit();
    }
  };

  isReadyToSubmit = () => {
    if (this.state.recover) {
      return this.state.user.trim().length > 0;
    } else {
      return this.state.user.trim().length > 0 && this.state.pass.trim().length > 0;
    }
  };
}

export default Login;
