import React from "react";

import { animated, useSpring } from "@react-spring/web";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown, faTruckFast } from "@fortawesome/free-solid-svg-icons";
import Capo from "../img/Capo.js";

function NewsWidget(props) {
  const [expanded, setExpanded] = React.useState(true);
  const openAnimation = useSpring({
    from: { maxHeight: "6em" },
    to: { maxHeight: expanded ? "100em" : "6em" },
    config: { duration: "400" },
  });
  let title = "Latest Updates";
  if (props.appState?.clientSettings?.NAME) {
    title = "Updates for " + props.appState?.clientSettings?.NAME;
  }
  const expanderIcon = expanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />;
  const expander = (
    <div
      className="dbwExpander"
      onClick={() => {
        setExpanded(!expanded);
      }}
    >
      {expanderIcon}
    </div>
  );
  return (
    <animated.div
      className="dashboardWidget dbwNews"
      onClick={() => {
        setExpanded(!expanded);
      }}
      style={openAnimation}
    >
      <div className="dbwTitle">
        <span className="newsLogo">
          <Capo />
        </span>
        <span data-testid="Updates for">{title}</span>
      </div>
      <hr />
      <h3 className="white">Latest Enhancements</h3>
      <ul>
        <li>
          <strong className="white">Coming soon!</strong> ClerkHound now handles subscription management directly! You can update subscription
          details, modify payment information, manage scheduling, and handle failed payments—all in one place. For these new, in-house subscriptions,
          ClerkHound will generate and email invoices for each payment—whether successful or not—giving you full control over your receivables. We’ll
          be migrating individual merchants to the new system over the next few weeks--and you won't see many of these changes until then--so keep an
          eye out for a call from Chris! Check out our{" "}
          <a href="https://clerkhound.tawk.help/category/billing" className="inlineLink">
            Knowledge Base articles
          </a>{" "}
          for a deep dive into the details.
        </li>
        <li>
          <strong className="white">Terms and Conditions:</strong> We have modified Section 11 of the terms and conditions to stating we may "access
          your environment, with your standing authorization, for purposes of resolving technical issues, performing system maintenance, researching
          and answering questions, implementing updates or fixes, investigating security concerns, or for any other purpose necessary to support and
          enhance the functionality of the Services". To see the full changes, see our{" "}
          <a href="https://clerkhound.com/terms/" className="inlineLink">
            terms and conditions
          </a>
          .
        </li>
        <li>
          <strong className="white">Built-in Support Options:</strong> We've added new support options directly within the application! Click the{" "}
          <span className="highlight">chat icon</span> in the bottom left corner to connect with Chris, Russell, or Laura. You can also access our
          Knowledge Base right from the app for quick answers and helpful resources.
        </li>
        <li>
          <strong className="white">Diagnostics:</strong> We have incorporated a system to capture your steps when encountering software issues,
          allowing us to assist you more efficiently. This feature can be found in the gear menu under "Activate Logging". For more information, visit
          the{" "}
          <a href="https://clerkhound.tawk.help/category/logrocket" className="inlineLink">
            LogRocket section
          </a>{" "}
          of our Knowledge Base.
        </li>
        <li className="white">
          <b> New Security Features:</b>
        </li>
        <ul>
          <li>Session locking after 15 minutes of inactivity.</li>
          <li>Multi-factor authentication that will take effect in 90 days (5/10/2025) otherwise regular password changes will be required.</li>
          <li>Passwords now require 12 characters/numbers.</li>
          <li>Prevention of password reuse for last 4 passwords.</li>
          <li>Lockout after 6 login failures.</li>
          <li>All sessions must be fully re-authenticated 12 hours after login.</li>
        </ul>
      </ul>
      <h3 className="white">Bug Fixes</h3>
      <ul>
        <li>
          Shipped online orders now display with a unique badge color and a cute little truck. <FontAwesomeIcon icon={faTruckFast} />
        </li>
        <li>Uploaded images may now contain special characters in the file name, which we will strip out. Hey, Britni!</li>
        <li>List views and folder lists now include a secondary sort field to keep items in a consistent order across pages.</li>
        <li>Deleting a cash-like payment from an invoice will now correctly recalculate the invoice totals.</li>
        <li>Subscriptions are now sorted by subscriber name by default.</li>
      </ul>
      {expander}
    </animated.div>
  );
}

export default NewsWidget;
