import React from "react";

// Constants
import * as Constants from "./Constants.jsx";

// Functions
import * as Helper from "./Helper.jsx";

// Style
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudBolt, faLock } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import Capo from "./img/Capo.js";
import LogoIcon from "./img/LogoIcon";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.passwordRef = React.createRef();
    this.state = {
      pass: "",
      confirm: "",
      passtype: "password",
      confirmtype: "password",
      message: "",
      messageReset: false,
    };
  }

  componentDidMount() {
    this.passwordRef.current.focus();
  }

  render() {
    const buttontext = this.props.waiting ? <FontAwesomeIcon icon={faCloudBolt} /> : "Reset Password";
    const buttonclass = this.isReadyToSubmit() && !this.props.waiting ? "action-button" : "action-button save-disabled";

    let eyeball = <FontAwesomeIcon icon={faEyeSlash} />;
    if (this.state.overlay?.passtype === "password") {
      eyeball = <FontAwesomeIcon icon={faEye} />;
    }
    return (
      <div className="app-body">
        <div className="login">
          <div className="login-logo">
            <Capo />
          </div>
          <div className="login-wrapper gridCenter">
            <h3>Reset Password</h3>
            <div className="login-container">
              <div>Enter your new password and in each of the boxes below.</div>
              <div className="input-container svgGridCenter">
                <FontAwesomeIcon icon={faLock} />
                <input
                  type={this.state.passtype}
                  name="pass"
                  id="pass"
                  placeholder="password"
                  maxLength={50}
                  autoComplete="off"
                  onChange={this.handleChange}
                  onKeyDown={this.handleKeyDown}
                  required
                  value={this.state.pass}
                  data-private
                  ref={this.passwordRef}
                />
                <span className="eyeball gridCenter" onClick={this.handleTogglePassword}>
                  {eyeball}
                </span>
              </div>
              <div className="input-container svgGridCenter">
                <FontAwesomeIcon icon={faLock} />
                <input
                  type={this.state.confirmtype}
                  name="confirm"
                  id="confirm"
                  placeholder="confirm"
                  maxLength={50}
                  autoComplete="off"
                  onChange={this.handleChange}
                  onKeyDown={this.handleKeyDown}
                  required
                  value={this.state.confirm}
                  data-private
                />
                <span className="eyeball gridCenter" onClick={this.handleToggleConfirm}>
                  {eyeball}
                </span>
              </div>
              <div className="minHeight3em marginTop1em">{this.state.message}</div>
              <div className="specialdesc">{Constants.MESSAGE_PASSWORD_REQUIREMENTS}</div>
              <span id="login-button" className={buttonclass} onClick={this.handleSubmit}>
                {buttontext}
              </span>
            </div>
          </div>
          <div className="login-title">
            <h1 className="title gridCenter">
              <LogoIcon />
            </h1>
          </div>
        </div>
      </div>
    );
  }

  handleTogglePassword = () => {
    this.setState(prevState => ({
      passtype: prevState.passtype === "password" ? "text" : "password",
    }));
  };

  handleToggleConfirm = () => {
    this.setState(prevState => ({
      confirmtype: prevState.confirmtype === "password" ? "text" : "password",
    }));
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: Helper.getTargetValue(event),
    });
  };

  handleSubmit = () => {
    if (this.isReadyToSubmit() && !this.props.waiting) {
      this.setState({ messageReset: false }, () => {
        this.props.handlePasswordChange(this.props.resetuuid, this.state.pass, result => {
          if (result) {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "Password changed successfully. Please log in with your new password.",
              callback: () => {
                // Redirect to home/login page
                window.location.href = "/";
              },
            });
          } else {
            this.setState({ messageReset: true, message: "" });
          }
        });
        // After 5 seconds, change the message to "Comparing old passwords..."
        this.setState({ message: "Validating tokens..." }, () => {
          setTimeout(() => {
            if (!this.state.messageReset) {
              this.setState({ message: "Checking old passwords..." }, () => {
                setTimeout(() => {
                  if (!this.state.messageReset) {
                    this.setState({ message: "Still checking old passwords..." }, () => {
                      setTimeout(() => {
                        if (!this.state.messageReset) {
                          this.setState({ message: "Almost there..." }, () => {
                            setTimeout(() => {
                              if (!this.state.messageReset) {
                                this.setState({ message: "Thanks for your patience..." });
                              } else {
                                this.setState({ message: "" });
                              }
                            }, 5000);
                          });
                        } else {
                          this.setState({ message: "" });
                        }
                      }, 5000);
                    });
                  } else {
                    this.setState({ message: "" });
                  }
                }, 5000);
              });
            } else {
              this.setState({ message: "" });
            }
          }, 5000);
        });
      });
    }
  };

  handleKeyDown = event => {
    if (event.keyCode === 13) {
      this.handleSubmit();
    }
  };

  isReadyToSubmit = () => {
    return this.state.pass.trim().length > 9 && this.state.confirm.trim().length > 9 && this.state.pass === this.state.confirm;
  };
}

export default ChangePassword;
