import React from "react";
import Switch from "./Switch";
import * as Constants from "./Constants";
import * as Helper from "./Helper";
import numeral from "numeral";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as ApiBase from "./ApiBase";
import CountdownSpinner from "./CountdownSpinner";

class CreditCardEntry extends React.Component {
  constructor(props) {
    super(props);
    this.submitted = false;
    this.formRef = React.createRef();

    let parentpaymentuuid = "";
    let availableToRefund = "";
    let cards = [];
    let processors = [];
    let displaycardreaderstate = "";
    let loadingStoredPayments = false;
    let partialpayment = false;

    // Determine the balance due or the allowable refund amount
    let amount = props.refund ? numeral(props.order.balancedue).format(Constants.CURRENCY) : props.order.balancedue;

    // If handpoint is not configured, then switch automatically to maast
    // Check settings for each processor
    const handpointEnabled = Helper.isTrue(this.props.appState?.handpoint?.enabled);
    const valorEnabled = Helper.isTrue(this.props.appState?.valor?.enabled);

    // If this is a stored payment action, then prepare to render cards on file
    if (this.props.paymentView === Constants.STORED_PAYMENT) {
      loadingStoredPayments = true;
      partialpayment = Helper.isTrue(this.props.appState?.maast?.partialPaymentVault);
    } else {
      // Set the partial payment flag based on the settings
      partialpayment = Helper.isTrue(this.props.appState?.maast?.partialPaymentManual);
      // Build a list of cards we can refund against
      if (props.refund) {
        cards = this.props.order.parentPayments
          .filter(item => item.paymenttype === Constants.CREDIT)
          .map(item => {
            const authResponse = JSON.parse(item.authresponse);
            // Make a unique list of processors
            if (processors.indexOf(authResponse.processor) === -1) {
              processors.push(authResponse.processor);
            }
            const available = numeral(item.amount).subtract(numeral(item.amountRefunded).value()).format(Constants.CURRENCY_WITH_SYMBOL);
            return {
              paymentuuid: item.paymentuuid,
              card: authResponse.cardNumber,
              available: available,
              uniqueRef: authResponse.uniqueRef,
            };
          });
        // If only one card is available to refund to and that amount is less than the refund amount
        // then reduce the refund amount to what is available
        if (cards.length === 1 && numeral(cards[0].available).value() < Math.abs(numeral(amount).value())) {
          amount = numeral(cards[0].available).format(Constants.CURRENCY);
        }
        // If there is only one card, then select it
        if (cards.length === 1) {
          parentpaymentuuid = cards[0].paymentuuid;
          availableToRefund = cards[0].available;
        }
      }

      if (Helper.isReturn(this.props.order)) {
        displaycardreaderstate = cards.length > 1 ? "Select a card to refund" : "Confirm amount to refund. Select Process";
        // Check for a single card to refund against with a payment gateway mismatch
        if (cards.length === 1 && this.props.appState.clientSettings.PAYMENT_GATEWAY !== this.props.order?.parentPayments[0].processor) {
          displaycardreaderstate = Constants.MESSAGE_PAYMENT_GATEWAY_MISMATCH;
        }
      } else {
        displaycardreaderstate = handpointEnabled || valorEnabled ? "Initializing card reader..." : "Initializing credit card inputs";
      }
    }

    this.state = {
      amount: amount,
      authuuid: null,
      availableToRefund: availableToRefund,
      avsZip: "",
      cardreaderstate: Constants.CARD_READER_UNINITIALIZED,
      displaycardreaderstate: displaycardreaderstate,
      embeddedFieldCardNumber: false,
      embeddedFieldCVV: false,
      embeddedFieldExpiration: false,
      flagconfig: false,
      flagdevice: false,
      flaginit: false,
      handpointEnabled: handpointEnabled,
      loadingStoredPayments: loadingStoredPayments,
      manualentry: false,
      parentpaymentuuid: parentpaymentuuid,
      partialpayment: partialpayment,
      paymentCard: null,
      pollingCount: 0,
      processors: processors,
      refundRequest: null,
      storedPaymentMethods: [],
      terminal: null,
      terminals: [],
      transientkey: null,
      validation: {
        zip: false,
        ccnumber: false,
        ccexp: false,
        cvv: false,
      },
      valorEnabled: valorEnabled,
    };
  }

  componentDidMount() {
    if (this.props.paymentView === Constants.STORED_PAYMENT) {
      // Load the customer's stored payment methods
      this.props.getCustomerFromVault(this.props.order?.company?.companyuuid, foundCustomer => {
        if (foundCustomer) {
          let paymentCard = null;
          let avsZip = null;
          if (this.props.maastCustomer?.billing_cards?.length === 1) {
            paymentCard = this.props.maastCustomer?.billing_cards[0];
            avsZip = paymentCard.billing_zip;
          }
          this.setState({
            storedPaymentMethods: this.props.maastCustomer?.billing_cards,
            loadingStoredPayments: false,
            paymentCard: paymentCard,
            avsZip: avsZip,
          });
        }
      });
    } else if (!Helper.isReturn(this.props.order) && !this.state.handpointEnabled && !this.state.valorEnabled) {
      // If this is not a return transaction, then enable embedded fields for Maast credit processing
      this.handleChangeManual();
    } else if (this.state.handpointEnabled || this.state.valorEnabled) {
      // For a SALE, if handpoint is enabled, then initialize the card reader
      // For a REFUND, only enable the card reader if the original payment was made through Handpoint
      if (!this.props.refund || (this.props.refund && Helper.inList(this.state.processors, Constants.HANDPOINT))) {
        this.initializeCardReader();
      } else if (this.props.refund && !Helper.inList(this.state.processors, Constants.HANDPOINT)) {
        this.setState({ cardreaderstate: Constants.CARD_READER_READY });
      }
    }
  }

  render() {
    if (this.props.paymentView === Constants.STORED_PAYMENT) {
      return this.renderStoredPayments();
    } else if (this.props.refund) {
      return this.renderCreditRefund();
    } else if (this.state.manualentry) {
      return this.renderManualEntry();
    } else {
      return this.renderCardReaderEntry();
    }
  }

  renderCreditRefund() {
    let waiting = "";
    if (this.state.cardreaderstate === Constants.CARD_READER_MANUAL_RETRIEVAL && this.state.authuuid) {
      waiting = (
        <span
          className="action-button brown-button manualCardStatus"
          onClick={() => {
            this.retrieveAuthResult(true);
          }}
        >
          Retrieve result
        </span>
      );
    }
    let processors = [];
    const cardList = this.props.order.parentPayments
      .filter(item => item.paymenttype === Constants.CREDIT)
      .map(item => {
        const authResponse = JSON.parse(item.authresponse);
        // Make a unique list of processors
        if (processors.indexOf(authResponse.processor) === -1) {
          processors.push(authResponse.processor);
        }
        const cardNumber =
          authResponse.tokenization_response?.card?.number ??
          authResponse.tokenization_response?.card_number ??
          authResponse.maskedCardNumber ??
          authResponse.MASKED_PAN;
        // Calculate amount available to refund against each card
        const available = numeral(item.amount).subtract(numeral(item.amountRefunded).value()).format(Constants.CURRENCY_WITH_SYMBOL);

        // Check if this card is selected in the UI
        const selected = item.paymentuuid === this.state.parentpaymentuuid;
        const classNames = "creditCardList " + (selected ? " selected" : "");

        // Render the card and available balance
        return (
          <div className={classNames} key={item.paymentuuid} onClick={() => this.handleSelectRefundCard(item)}>
            {cardNumber} ({available})
          </div>
        );
      });
    return (
      <div className="refundCredit">
        <h1 className="paymentheader">Refund to Card</h1>
        {/* -------------------------------------------- */}
        <div className="areaInputItem inputfields">
          {cardList}
          <div className="cardreaderstatus">{this.state.displaycardreaderstate}</div>
          <label htmlFor="amount" className="amount-label">
            Amount to Refund:
          </label>
          <input
            type="text"
            name="amount"
            id="amount"
            maxLength={13}
            className="refundamount"
            autoComplete="off"
            autoFocus={true}
            onChange={this.handleChangeAmount}
            onFocus={event => {
              Helper.handleFocus(event);
              event.target.select();
            }}
            onBlur={() => {}}
            value={this.state.amount}
          />
        </div>
        {waiting}
        {this.renderCardReaderEntryButtons()}
      </div>
    );
  }

  renderStoredPayments() {
    let cardList = "";
    if (this.state.loadingStoredPayments) {
      cardList = (
        <div className="creditCardList span5">
          Loading payment methods... <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      );
    } else {
      cardList = this.state.storedPaymentMethods.map(item => {
        // Check if this card is selected in the UI
        const selected = item.card_id === this.state.paymentCard?.card_id;
        const classNames = "creditCardList " + (selected ? " selected" : "");
        const exp_date = item.exp_date.slice(0, 2) + "|" + item.exp_date.slice(2, 4);

        // Render the card and available balance
        return (
          <React.Fragment key={item.card_id}>
            <div>&nbsp;</div>
            <div className={classNames + " customerName"} onClick={() => this.handleSelectStoredPayment(item)}>
              {item.billing_first_name} {item.billing_last_name}
            </div>
            <div className={classNames + " card_id"} onClick={() => this.handleSelectStoredPayment(item)}>
              {item.card_number}
            </div>
            <div className={classNames + " expiration"} onClick={() => this.handleSelectStoredPayment(item)}>
              {exp_date}
            </div>
            <div>&nbsp;</div>
          </React.Fragment>
        );
      });
    }
    const message = this.state.paymentCard ? "Click Process to continue" : "Select a card to charge";
    return (
      <div className="storedPayments">
        <h1 className="paymentheader">Stored Payment Methods</h1>
        {/* -------------------------------------------- */}
        <div className="areaInputItem inputfields">
          <div className="cardListContainer">{cardList}</div>
          <div className="cardreaderstatus">{message}</div>
          <label htmlFor="amount" className="amount-label">
            Amount to Charge:
          </label>
          <input
            type="text"
            name="amount"
            id="amount"
            maxLength={13}
            className="refundamount"
            autoComplete="off"
            autoFocus={true}
            onChange={this.handleChangeAmount}
            onFocus={event => {
              Helper.handleFocus(event);
              event.target.select();
            }}
            onBlur={() => {}}
            value={this.state.amount}
          />
        </div>
        {this.renderPartialPaymentSwitch()}
        {this.renderStoredPaymentButtons()}
      </div>
    );
  }

  renderCardReaderEntry() {
    let waiting = "";
    if (this.state.cardreaderstate === Constants.CARD_READER_MANUAL_RETRIEVAL && this.state.authuuid) {
      waiting = (
        <span
          className="action-button brown-button manualCardStatus"
          onClick={() => {
            this.retrieveAuthResult(true);
          }}
        >
          Retrieve result
        </span>
      );
    }
    // 09/22/2023 - Removed amount edit blocking (before reader was available)
    // const handleChangeAmount = this.state.cardreaderstate === Constants.CARD_READER_READY ? this.handleChangeAmount : () => {};
    return (
      <div>
        <h1 className="paymentheader">Credit Card Payment</h1>
        {this.renderPayToggle(waiting)}
        <div className="cardreaderstatus">{this.state.displaycardreaderstate}</div>
        {waiting}
        <div className="amtchargegrid">
          <div></div>
          <div>
            <div className="areaInputItem inputfields ">
              <label htmlFor="amtcharge" className="amtcharge-label">
                Amount to Charge:
              </label>
              <input
                type="text"
                name="amtcharge"
                id="amtcharge"
                maxLength={13}
                className="amtcharge"
                autoComplete="off"
                autoFocus={true}
                value={this.state.amount}
                onChange={this.handleChangeAmount}
                onFocus={event => {
                  event.target.select();
                }}
                onBlur={() => {}}
              />
            </div>
          </div>
        </div>
        {this.renderCardReaderEntryButtons()}
      </div>
    );
  }

  renderPayToggle(waiting) {
    if (
      Helper.isFalse(this.props.maast.enabled) ||
      Helper.isFalse(this.props.handpoint.enabled) ||
      Helper.isFalse(this.props.appState?.nmi?.manualEntryEnabled)
    ) {
      return "";
    }
    return (
      <div className="pay-toggles">
        <Switch
          label="Enter Credit Card Information Manually?"
          fieldname="manualentry"
          handleChange={this.handleChangeManual}
          checked={this.state.manualentry}
          elementid="switch-manualentry"
          fielddisabled={waiting}
        />
      </div>
    );
  }

  renderManualEntry() {
    const paymentGateway = this.props.appState?.clientSettings?.PAYMENT_GATEWAY;
    if (paymentGateway === Constants.MAAST) {
      return this.renderManualEntryMaast();
    } else if (paymentGateway === Constants.NMI) {
      return this.renderManualEntryNMI();
    } else {
      console.log(Helper.clTimestamp(), "Unknown payment gateway", paymentGateway);
    }
  }

  renderManualEntryNMI() {
    return (
      <div>
        <h1 className="paymentheader">Credit Card Payment</h1>
        {this.renderPayToggle(false)}
        <div className="cardreaderstatus">{this.state.displaycardreaderstate}</div>
        <div id="collectJSElement"></div>
        <form id="paymentform" method="post" action="/" ref={this.formRef}>
          <div className="creditcarddesktop">
            <div className="areaInputItem inputfields">
              <label htmlFor="card_number" className="card-number-label">
                Card #
              </label>
              <div id="ccnumber" data-private></div>
            </div>
            <div className="areaInputItem inputfields">
              <label htmlFor="exp_date" className="exp-date-label">
                Exp
              </label>
              <div id="ccexp" data-private></div>
            </div>
            <div className="areaInputItem inputfields">
              <label htmlFor="securitycode" className="securitycode-label">
                CVV
              </label>
              <div id="cvv" data-private></div>
            </div>
            <div className="areaInputItem inputfields amtchargecontainer">
              <label htmlFor="amtcharge" className="amtcharge-label">
                Amount to Charge:
              </label>
              <input
                type="text"
                name="amtcharge"
                id="amtcharge"
                maxLength={13}
                className="amtcharge"
                autoComplete="off"
                value={this.state.amount}
                onChange={this.handleChangeAmount}
                onFocus={() => {}}
                onBlur={() => {}}
              />
            </div>
            <div></div>

            <div className="areaInputItem inputfields">
              <label htmlFor="zip" className="zip-label">
                Zip Code:
              </label>
              <input
                type="text"
                name="zip"
                id="zip"
                maxLength={5}
                className="zip"
                autoComplete="off"
                value={this.state.avsZip}
                data-private
                onChange={this.handleChangeAvsZip}
                onFocus={() => {}}
                onBlur={() => {}}
              />
            </div>
          </div>
          {this.renderPartialPaymentSwitch()}
          {this.renderManualEntryButtons()}
        </form>
      </div>
    );
  }

  renderManualEntryMaast() {
    return (
      <div>
        <h1 className="paymentheader">Credit Card Payment</h1>
        {this.renderPayToggle(false)}
        <div className="cardreaderstatus">{this.state.displaycardreaderstate}</div>
        <form id="paymentform" method="post" action="/" ref={this.formRef}>
          <div className="creditcarddesktop">
            <div className="areaInputItem inputfields">
              <label htmlFor="card_number" className="card-number-label">
                Card #
              </label>
              <div id="card_number" className={this.state.embeddedFieldCardNumber ? "" : "errorBorder"}></div>
            </div>
            <div className="areaInputItem inputfields">
              <label htmlFor="exp_date" className="exp-date-label">
                Exp
              </label>
              <div id="exp_date" className={this.state.embeddedFieldExpiration ? "" : "errorBorder"}></div>
            </div>
            <div className="areaInputItem inputfields">
              <label htmlFor="securitycode" className="securitycode-label">
                CVV
              </label>
              <div id="cvv2" className={this.state.embeddedFieldCVV ? "" : "errorBorder"}></div>
            </div>
            <div className="areaInputItem inputfields amtchargecontainer">
              <label htmlFor="amtcharge" className="amtcharge-label">
                Amount to Charge:
              </label>
              <input
                type="text"
                name="amtcharge"
                id="amtcharge"
                maxLength={13}
                className="amtcharge"
                autoComplete="off"
                value={this.state.amount}
                onChange={this.handleChangeAmount}
                onFocus={() => {}}
                onBlur={() => {}}
              />
            </div>
            <div></div>

            <div className="areaInputItem inputfields">
              <label htmlFor="avs-zip" className="avs-zip-label">
                Zip Code:
              </label>
              <input
                type="text"
                name="avs-zip"
                id="avs-zip"
                maxLength={5}
                className="avs-zip"
                autoComplete="off"
                value={this.state.avsZip}
                onChange={this.handleChangeAvsZip}
                onFocus={() => {}}
                onBlur={() => {}}
              />
            </div>
          </div>
          {this.renderPartialPaymentSwitch()}
          {this.renderManualEntryButtons()}
        </form>
      </div>
    );
  }

  renderPartialPaymentSwitch = () => {
    // Do not render for NMI, who doesn't support partial payments
    if (this.props.appState?.clientSettings?.PAYMENT_GATEWAY === Constants.NMI) {
      return "";
    }
    return (
      <div className="partial-toggle">
        <Switch
          label="Accept partial payment?"
          fieldname="partialpayment"
          handleChange={() => {
            this.setState({ partialpayment: !this.state.partialpayment });
          }}
          checked={this.state.partialpayment}
          elementid="switch-partialpayment"
        />
      </div>
    );
  };

  renderManualEntryButtons = () => {
    let processClassName = "payment-action-button green-button save-disabled";
    if (this.isReadyToSubmit()) {
      processClassName = "payment-action-button green-button";
    }

    return (
      <div className="processButtons">
        <div className="payment-action-buttons cancelbutton ">
          <div className="payment-action-button-wrapper">
            <span className="payment-action-button-cell gridCenter">
              <span title="Cancel Credit Payment" onClick={this.props.handleCancel} className="payment-action-button red-button">
                <span className="cancel">Cancel</span>
              </span>
            </span>
          </div>
        </div>

        <div className="payment-action-buttons processbutton ">
          <div className="payment-action-button-wrapper">
            <span className="payment-action-button-cell gridCenter">
              <span
                onClick={event => {
                  this.handleSubmitManualEntry(event);
                }}
                className={processClassName}
                title="Process Credit Payment"
              >
                <span className="process">Process</span>
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderCardReaderEntryButtons = () => {
    let processClassName = "payment-action-button green-button save-disabled";
    if (this.isReadyToSubmit()) {
      processClassName = "payment-action-button green-button";
    }
    let cancelClassName = "payment-action-button red-button";
    if (
      this.state.cardreaderstate === Constants.CARD_READER_CANCELLING ||
      (this.state.cardreaderstate === Constants.CARD_READER_REQUEST_SALE_AUTH && this.state.terminal.vendor === Constants.VALOR)
    ) {
      cancelClassName += " save-disabled";
    }
    const handler = this.props.refund ? this.handleRefundCard : this.handleAcceptCard;
    return (
      <div className="processButtons">
        <div className="payment-action-buttons cancelbutton ">
          <div className="payment-action-button-wrapper">
            <span className="payment-action-button-cell gridCenter">
              <span onClick={this.handleCancelCardReader} className={cancelClassName}>
                <span className="cancel">Cancel</span>
              </span>
            </span>
          </div>
        </div>

        <div className="payment-action-buttons processbutton ">
          <div className="payment-action-button-wrapper">
            <span className="payment-action-button-cell gridCenter">
              <span onClick={handler} className={processClassName} data-testid="Payment Process Button">
                <span className="process">Process</span>
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderStoredPaymentButtons = () => {
    let processClassName = "payment-action-button green-button save-disabled";
    if (this.isReadyToSubmit()) {
      processClassName = "payment-action-button green-button";
    }

    return (
      <div className="processButtons">
        <div className="payment-action-buttons cancelbutton ">
          <div className="payment-action-button-wrapper">
            <span className="payment-action-button-cell gridCenter">
              <span onClick={this.props.handleCancel} className="payment-action-button red-button">
                <span className="cancel">Cancel</span>
              </span>
            </span>
          </div>
        </div>

        <div className="payment-action-buttons processbutton ">
          <div className="payment-action-button-wrapper">
            <span className="payment-action-button-cell gridCenter">
              <span
                title="Proceed with payment process"
                onClick={event => {
                  this.handleSubmitStoredPayment(event);
                }}
                className={processClassName}
              >
                <span className="process">Process</span>
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  handleCancelCardReader = () => {
    // Cancel is disabled if we are in the middle of a VALOR transaction
    if (this.state.cardreaderstate === Constants.CARD_READER_REQUEST_SALE_AUTH && this.state.terminal.vendor === Constants.VALOR) {
      return;
    }
    // See what's going on with the card reader
    const nothingToCancel = Helper.inList(
      [Constants.CARD_READER_UNINITIALIZED, Constants.CARD_READER_INITIALIZING, Constants.CARD_READER_READY],
      this.state.cardreaderstate
    );

    // If we haven't started a transaction yet, just close the Credit Card Entry view
    if (nothingToCancel) {
      this.props.handleCancel();
    } else {
      // Try to cancel the transaction on the card reader
      this.setState({ displaycardreaderstate: "Cancelling transaction..." });
      this.stopCurrentTransaction();
    }
  };

  handleChangeManual = () => {
    this.setState({ manualentry: !this.state.manualentry, displaycardreaderstate: "Initializing..." }, () => {
      if (this.state.manualentry) {
        const paymentGateway = this.props.appState?.clientSettings?.PAYMENT_GATEWAY;
        if (paymentGateway === Constants.MAAST) {
          if (!this.submitted) {
            this.submitted = true;
            this.getTransientToken();
          }
        } else if (paymentGateway === Constants.NMI) {
          this.handleInitializeNMI();
        } else {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Manual card entry is not configured. Please contact support.",
          });
        }
      } else {
        if (this.state.handpointEnabled || this.state.valorEnabled) {
          // For a SALE, if handpoint is enabled, then initialize the card reader
          if (!this.props.refund || (this.props.refund && Helper.inList(this.state.processors, Constants.HANDPOINT))) {
            this.initializeCardReader();
          }
        }
      }
    });
  };

  handleInitializeNMI = () => {
    if (this.props.collectApiKey && !document.getElementById("collectJS")) {
      const script = document.createElement("script");
      script.id = "collectJS";
      script.async = true;
      script.src = "https://secure.nmi.com/token/Collect.js";
      script.setAttribute("data-tokenization-key", this.props.collectApiKey);
      script.setAttribute("data-variant", "inline");
      // Add an event listener to handle script load completion
      const setState = this.setState.bind(this);
      script.onload = () => {
        if (window.CollectJS) {
          window.CollectJS.configure({
            variant: "inline",
            styleSniffer: true,
            callback: this.handleTokenizationResponse,
            validationCallback: function (field, status, message) {
              setState(prevState => ({ validation: { ...prevState.validation, [field]: status } }));
            },
            fieldsAvailableCallback: function () {
              setState({ displaycardreaderstate: "Enter payment information" });
            },
            fields: {
              ccnumber: {
                // placeholder: "Card Number",
                selector: "#ccnumber",
              },
              ccexp: {
                // placeholder: "Expires [MM|YY]",
                selector: "#ccexp",
              },
              cvv: {
                // placeholder: "CVV",
                selector: "#cvv",
              },
            },
          });
        } else {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "An unexpected error occurred (collect).\nPlease refresh the page and try again.",
          });
        }
      };
      // Handle script load errors
      script.onerror = () => {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "An unexpected error occurred (load).\nPlease refresh the page and try again.",
        });
      };
      document.getElementById("collectJSElement").appendChild(script);
    }
  };

  handleTokenizationResponse = tokenizationResponse => {
    this.setState({ displaycardreaderstate: "Authorizing..." });
    this.authorizeTokenizedPayment(tokenizationResponse, Constants.AUTHORIZE_SALE);
  };

  handleManualEntry = () => {
    const fontSize = Helper.getFontSize("amtcharge");
    const settings = {
      formId: "paymentform",
      mode: this.props.maast.mode,
      fields: {
        card_number: {
          id: "card_number",
          onblur: this.handleBlur,
          attributes: { required: true },
        },
        exp_date: {
          id: "exp_date",
          onblur: this.handleBlur,
          attributes: { required: true },
        },
        cvv2: {
          id: "cvv2",
          onblur: this.handleBlur,
          attributes: { required: true },
        },
      },
      transientKey: this.state.transientkey,
      tokenize: false,
      onSuccess: data => {
        // Make payment from server using the token
        if (!this.submitted) {
          this.submitted = true;
          this.authorizeTokenizedPayment(data, Constants.AUTHORIZE_SALE);
        } else {
          console.log(Helper.clTimestamp(), "ALERT! Received multiple onSuccess() messages!");
        }
      },
      onError: error => {
        if (error.detail) {
          for (let key in error.detail) {
            const message = error.detail[key] + " Press Cancel and try again. If the problem persists, contact support.";
            this.setState({ displaycardreaderstate: message });
          }
        }
      },
      cardConfig: {
        enabled: true,
      },
      achConfig: {
        enabled: true,
        onPaymentTypeChange: function (data) {
          // console.log(Helper.clTimestamp(), "Display", data);
        },
      },
      formFields: {
        cvv2: {
          required: true,
        },
      },
      style: Helper.getMaastEmbeddedStyle(Constants.PAY, this.props.appState?.colorMode, fontSize),
    };

    if (window.qpEmbeddedForm?.loadFrame) {
      window.qpEmbeddedForm.loadFrame(this.props.maast.merchant_id, settings);
    } else {
      console.log(Helper.clTimestamp(), "Error loading embedded fields");
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Manual card entry is not available at this time. Please try again later.",
      });
    }
  };

  handleChangeAmount = event => {
    // Prevent duplicate decimal points
    if (event.target.value.split(".").length > 2) {
      return;
    }
    // Allow only numeric characters, decimal points, and negative signs
    if (!/^-?\d*\.?\d*$/.test(event.target.value)) {
      return;
    }

    this.setState({ amount: event.target.value });
  };

  handleChangeAvsZip = event => {
    // Zip code must be exactly 5 digits, all numeric characters
    const isValid = Helper.isValidZip(event.target.value);
    this.setState(prevState => ({
      avsZip: event.target.value,
      validation: { ...prevState.validation, [event.target.name]: isValid },
    }));
  };

  // Handle credit payment types
  isReadyToSubmit = () => {
    const amount = numeral(this.state.amount ?? 0).value();
    const balanceDue = numeral(Math.abs(numeral(this.props.order.balancedue).value()));
    // Check for card reader ready unless this is a manual transaction or vaulted card
    if (
      this.props.paymentView !== Constants.STORED_PAYMENT &&
      this.state.cardreaderstate !== Constants.CARD_READER_READY &&
      (this.state.handpointEnabled || this.state.valorEnabled) &&
      !this.state.manualentry
    ) {
      return false;
    }

    // ***************************
    // Handle all the RETURN edits
    // ***************************
    if (Helper.isReturn(this.props.order)) {
      // Make sure a card is selected
      if (!this.state.parentpaymentuuid || !this.state.availableToRefund) {
        return false;
      }

      // Refund payment should be positive for calcuations
      let credit_refund_amount = Math.abs(amount);

      // Amount available to return to the credit card used in the SALE transaction
      const diff_available = numeral(`${this.state.availableToRefund}`).subtract(credit_refund_amount).value();
      if (diff_available < 0.0) {
        return false;
      }
      // Check for refund amount larger than balance due/amount available to refund
      const diff_balance_due = balanceDue.subtract(credit_refund_amount).value();
      if (diff_balance_due < 0.0) {
        return false;
      }
      // Check for payment gateway mismatch
      if (
        this.props.appState.clientSettings.PAYMENT_GATEWAY !==
        this.props.order?.parentPayments.find(item => item.paymentuuid === this.state.parentpaymentuuid).processor
      ) {
        return false;
      }
    }
    // *************************
    // Handle all the SALE edits
    // *************************
    else {
      // Disable process while loading stored payment list or while no stored payment option is selected
      if (this.props.paymentView === Constants.STORED_PAYMENT) {
        if (this.state.loadingStoredPayments || !this.state.paymentCard?.card_id) {
          return false;
        }
      }
      // Check for zero/missing amount
      if (amount <= 0 || amount === null) {
        return false;
      }
      // Check for overage (we know amount is positive at this point)
      if (amount > balanceDue.value()) {
        return false;
      }
      // Check for manual entry required fields
      if (this.state.manualentry) {
        // Maast logic
        if (this.props.appState?.clientSettings?.PAYMENT_GATEWAY === Constants.MAAST) {
          if (
            !this.state.avsZip ||
            Helper.stripNonNumerics(this.state.avsZip).length < 5 ||
            !this.state.embeddedFieldCVV ||
            !this.state.embeddedFieldCardNumber ||
            !this.state.embeddedFieldExpiration
          ) {
            return false;
          }
        } else if (this.props.appState?.clientSettings?.PAYMENT_GATEWAY === Constants.NMI) {
          // NMI Logic
          if (!this.state.validation.zip || !this.state.validation.ccnumber || !this.state.validation.ccexp || !this.state.validation.cvv) {
            return false;
          }
        } else {
          console.log(Helper.clTimestamp(), "Unknown payment gateway");
          return false;
        }
      }
    }

    return true;
  };

  handleSubmitManualEntry = event => {
    if (this.isReadyToSubmit()) {
      // Check for Maast or NMI
      const paymentGateway = this.props.appState?.clientSettings?.PAYMENT_GATEWAY;
      console.log(Helper.clTimestamp(), "Payment Gateway", paymentGateway);
      if (paymentGateway === Constants.MAAST) {
        // Make sure amount entered does not exceed balance due
        this.formRef.current.requestSubmit();
      } else if (paymentGateway === Constants.NMI) {
        // Ask NMI to tokenize the card data and return the token (to finishSubmit)
        window.CollectJS.startPaymentRequest();
        this.props.showOverlay();
      }
      // TODO: Change message on screen to "Submitted"
      this.setState({ displaycardreaderstate: "Encrypting payment information..." });
    }
  };

  handleSubmitStoredPayment = event => {
    if (this.isReadyToSubmit()) {
      // Make sure amount entered does not exceed balance due
      this.props.showOverlay({
        type: Constants.OVERLAY_AUTH_PROMPT,
        prompt:
          "Manager authorization required.\n\n-\n" +
          "By clicking Auth, you are confirming that\n" +
          "the customer has granted explicit written consent\n" +
          "for the utilization of this vaulted payment method\n" +
          "for the purposes of this specific transaction.",
        user: "",
        hideOnSuccess: false,
        progressMessage: "Authorizing payment...",
        callback: (response, authtoken = null) => {
          if (response === Constants.OVERLAY_RESPONSE_YES) {
            this.props.showOverlay({
              type: Constants.OVERLAY_PROGRESS,
              text: "Authorizing payment...",
            });
            // Build a vaulted card tokenization request and call maast auth function
            const data = {
              payment_sub_type: Constants.VAULT,
              processor: this.props.appState.clientSettings.PAYMENT_GATEWAY,
              authtoken: authtoken,
              ...this.state.paymentCard,
            };
            this.authorizeTokenizedPayment(data, Constants.AUTHORIZE_SALE_VAULT, authtoken);
          }
        },
      });
    }
  };

  handleSelectStoredPayment = item => {
    this.setState({
      paymentCard: item,
      avsZip: item.billing_zip,
    });
  };

  handleSelectRefundCard = item => {
    if (item.paymentuuid) {
      // Take the absolute value of the refund amount and format it for display
      let refundAmount = Math.abs(numeral(this.props.amount).value());

      // Compare the amount available on a card against the total refund amount
      let cardAmount = Math.abs(numeral(item.amount).value()) - Math.abs(numeral(item.amountRefunded).value());
      // Make sure there is available balance to refund
      if (cardAmount > 0) {
        if (cardAmount < refundAmount) {
          refundAmount = numeral(cardAmount);
        } else {
          refundAmount = numeral(refundAmount);
        }
        // Put a negative amount on the screen (even though we fix the sign later, if positive)
        refundAmount = refundAmount.multiply(-1).format(Constants.CURRENCY);
        let message = "Select Process to continue";
        // Check for a single card to refund against with a payment gateway mismatch
        if (this.props.appState.clientSettings.PAYMENT_GATEWAY !== item.processor) {
          message = Constants.MESSAGE_PAYMENT_GATEWAY_MISMATCH;
        }
        this.setState({
          parentpaymentuuid: item.paymentuuid,
          amount: refundAmount,
          availableToRefund: cardAmount,
          displaycardreaderstate: message,
        });
        // Do not allow selection of card with $0 available
      } else {
        this.setState({
          displaycardreaderstate: "Select a different card to refund (no balance available).",
        });
      }
    }
  };

  handleRefundCard = () => {
    // Handle refunding money back to a parent payment card
    if (this.isReadyToSubmit()) {
      if (!this.submitted) {
        this.submitted = true;
        let uniqueRef = "";
        let processor = "";
        const cards = this.props.order.parentPayments
          .filter(item => item.paymenttype === Constants.CREDIT)
          .map(item => {
            const authResponse = JSON.parse(item.authresponse);
            const processor = item.processor || authResponse.processor;
            const available = numeral(item.amount).subtract(numeral(item.amountRefunded).value()).format(Constants.CURRENCY_WITH_SYMBOL);
            return {
              paymentuuid: item.paymentuuid,
              available: available,
              uniqueRef: Helper.getUniqueRef(authResponse),
              processor: processor,
            };
          });
        if (cards.length === 1) {
          uniqueRef = cards[0].uniqueRef;
          processor = cards[0].processor;
          this.setState({ parentpaymentuuid: cards[0].paymentuuid });
        } else {
          // Find the selected card to refund to and maybe do some math.min()
          const cardToRefund = cards.filter(item => item.paymentuuid === this.state.parentpaymentuuid);
          if (cardToRefund.length === 1) {
            uniqueRef = cardToRefund[0].uniqueRef;
            processor = cardToRefund[0].processor;
          }
        }
        if (!uniqueRef) {
          return;
        }
        this.setState(
          {
            cardreaderstate: Constants.AUTH_PENDING,
            displaycardreaderstate: "Refund Request Sent",
          },
          () => {
            if ([Constants.MAAST, Constants.HANDPOINT, Constants.NMI].includes(processor)) {
              // All credit processor require a postive value in the request even though it's a refund amount
              const amount = Math.abs(numeral(this.state.amount).value());
              this.authorizeCreditRefund(uniqueRef, amount, processor);
            } else {
              console.log(Helper.clTimestamp(), "Unknown processor");
            }
          }
        );
      }
    }
  };

  handleAcceptCard = () => {
    if (this.isReadyToSubmit()) {
      if (!this.submitted) {
        this.submitted = true;
        this.setState(
          {
            cardreaderstate: Constants.CARD_READER_REQUEST_SALE_AUTH,
            displaycardreaderstate: "Sending request to device...",
          },
          () => {
            if (this.state.terminal.vendor === Constants.HANDPOINT) {
              this.startHandPointSale();
            } else if (this.state.terminal.vendor === Constants.VALOR) {
              this.startValorSale();
            }
          }
        );
      }
    }
  };

  handleBlur = event => {
    let displaycardreaderstate = "Enter card information";

    // Check for Maast-specific field errors
    if (!event?.isValid && event.errors?.length > 0) {
      displaycardreaderstate = event.errors[0];
    }

    // Note which fields, if any, passed/failed validation
    if (event.fieldName === "card_number") {
      this.setState({
        embeddedFieldCardNumber: event.isValid,
      });
    } else if (event.fieldName === "exp_date") {
      this.setState({
        embeddedFieldExpiration: event.isValid,
      });
    } else if (event.fieldName === "cvv2") {
      this.setState({
        embeddedFieldCVV: event.isValid,
      });
    }

    this.setState({
      displaycardreaderstate: displaycardreaderstate,
    });
  };

  handleSelectTerminal = () => {
    const items = this.state.terminals.map(item => {
      item.id = item.terminal_type + "-" + item.serial_number;
      if (item.name) {
        item.text = item.name;
      } else {
        item.text = item.terminal_type + " - " + item.serial_number;
      }
      return item;
    });
    this.props.showOverlay({
      type: Constants.OVERLAY_PICKER,
      items: items,
      text: "Pick a terminal",
      id: items[0].id,
      event: {},
      hideCreate: true,
      callback: this.handlePickTerminal,
    });
  };

  handlePickTerminal = (response, item, event) => {
    if (response === Constants.OVERLAY_RESPONSE_SELECT) {
      localStorage.setItem(Constants.HANDPOINT_SELECTED_TERMINAL, item.terminal_type + "-" + item.serial_number);
      this.initializeCardReader();
    }
  };

  initializeCardReader = () => {
    // If handpoint is enabled, then go get the list of terminals for them
    const handpointEnabled = Helper.isTrue(this.props.appState?.handpoint?.enabled);

    // If there are Valor card readers, get a list to be injected into the terminals list from Handpoint
    const valorEnabled = Helper.isTrue(this.props.appState?.valor?.enabled);

    let valorTerminals = [];
    if (valorEnabled) {
      valorTerminals = this.getValorTerminals();
    }
    if (handpointEnabled) {
      // If handpoint is enabled, then go get the list of terminals for them (async call)
      // and then combine the list with the Valor terminals
      this.getHandpointTerminals(handpointTerminals => {
        this.handleSetTerminals(valorTerminals, handpointTerminals);
      });
    } else {
      // If only Valor is enabled, then just use the Valor terminals
      this.handleSetTerminals(valorTerminals, []);
    }
  };

  pingDevice = () => {
    const url = Constants.URL_PAYMENTS;
    const params = {
      action: Constants.PING_DEVICE,
      source: Constants.HANDPOINT,
      terminaltype: this.state.terminal.terminal_type,
      serialnumber: this.state.terminal.serial_number,
    };
    Helper.getData(url, params).then(response => {
      if (this.state.manualentry === true) {
        return;
      }
      if (response.status >= 200 && response.status < 300 && response.body?.message) {
        // Check for error response
        if (response.body.message?.error) {
          let status = "Error connecting to card reader";
          if (this.state.terminals?.length > 1) {
            // Add a clickable span to show the terminal selection dialog
            status = (
              <span>
                Error connecting to card reader{" "}
                <span className="selectTerminal" onClick={this.handleSelectTerminal}>
                  {this.state.terminal.name}
                </span>
              </span>
            );
          }
          this.setState({
            displaycardreaderstate: status,
          });
        } else {
          let cardPrompt = "Click Process to continue.";
          if (this.props.refund && Helper.inList(this.state.processors, Constants.HANDPOINT)) {
            cardPrompt = "Select a card to refund";
          }
          let status = (
            <span>
              Card reader ready.
              <br />
              {cardPrompt}
            </span>
          );
          if (this.state.terminals?.length > 1) {
            // Add a clickable span to show the terminal selection dialog
            status = (
              <span>
                <span className="selectTerminal" onClick={this.handleSelectTerminal}>
                  {this.state.terminal.name}
                </span>{" "}
                card reader ready.
                <br />
                {cardPrompt}
              </span>
            );
          }
          //Update state with response, turn off downloading
          this.setState({
            error: null,
            downloading: false,
            cardreaderstate: Constants.CARD_READER_READY,
            displaycardreaderstate: status,
          });
        }
      } else {
        this.setState({
          displaycardreaderstate: "Error connecting to card reader",
        });
      }
    });
  };

  startValorSale = () => {
    console.log(Helper.clTimestamp(), "Opening websocket connection to Valor");
    // Get the auth token for access to the websocket
    this.setState({ displaycardreaderstate: "Connecting to server..." });
    this.getAuthToken(authtoken => {
      if (authtoken) {
        // Open a websocket connection
        const ws = new WebSocket(`${ApiBase.WEBSOCKET}?authtoken=${authtoken}`);
        ws.onopen = () => {
          console.log(Helper.clTimestamp(), "Valor websocket connection opened");
          this.setState({ displaycardreaderstate: "Connected..." });
        };
        ws.onmessage = event => {
          console.log(Helper.clTimestamp(), "Valor websocket message received");
          const data = JSON.parse(event.data);
          console.log(data);
          // Look for open connection acknowledgement
          if (data?.action === Constants.ACK_WEBSOCKET) {
            this.setState({ displaycardreaderstate: "Follow prompts on terminal" });
            console.log("Time to authorize the sale");
            // Time to authorize the sale
            // Send the sale request to the websocket
            const saleRequest = {
              action: Constants.AUTHORIZE_SALE,
              source: Constants.VALOR,
              amount: numeral(this.state.amount).value(),
              orderuuid: this.props.order?.orderuuid,
              epi: this.state.terminal.serial_number,
              session: localStorage.getItem(Constants.LOCAL_STORAGE_SESSION),
            };
            ws.send(JSON.stringify(saleRequest));
          } else if (data?.action === Constants.AUTHORIZE_RESPONSE) {
            this.setState({ displaycardreaderstate: "Receiving response..." });
            const response = JSON.parse(data.body);
            if (response.response_code === 200) {
              const response_body = response.response_body;
              const valorresponsecode = response_body.valorresponsecode;
              if (valorresponsecode === Constants.VALOR_STATE_SUCCESS) {
                this.props.handleCreditApproval(response_body.order);
              } else {
                this.props.handleDecline(response?.response_body, response_body?.error_msg ?? "Credit transaction failed");
              }
            } else {
              // Display error message
              this.props.handleDecline(response?.response_body, response?.response_body?.message ?? "Credit transaction failure");
            }
          }
        };
        ws.onclose = () => {
          console.log(Helper.clTimestamp(), "Valor websocket connection closed");
          // TODO: Check if still in auth transaction and show error message/warning/?
        };
        ws.onerror = error => {
          console.log(Helper.clTimestamp(), "Valor websocket error", error);
          this.setState({
            displaycardreaderstate: "Error connecting to server",
            cardreaderstate: Constants.CARD_READER_READY,
          });
        };
      } else {
        console.log(Helper.clTimestamp(), "Error getting auth token");
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Error processing credit card. Please try again.",
        });
      }
    });
  };

  startHandPointSale = () => {
    this.props.showOverlay();
    const url = Constants.URL_PAYMENTS;
    const params = {
      action: Constants.AUTHORIZE_SALE,
      amount: numeral(this.state.amount).value(),
      orderuuid: this.props.order?.orderuuid,
      serialnumber: this.state.terminal.serial_number,
      source: Constants.HANDPOINT,
      terminaltype: this.state.terminal.terminal_type,
    };
    Helper.postData(url, params).then(response => {
      this.props.hideOverlay();
      if (response.status === 200 && response.body?.status) {
        //Update state with response, turn off downloading
        this.setState(
          {
            error: null,
            downloading: false,
            cardreaderstate: Constants.CARD_READER_WAITING,
            displaycardreaderstate: "Follow instructions on terminal",
            authuuid: response.body.authuuid,
            pollingCount: 0,
          },
          () => {
            setTimeout(() => {
              this.retrieveAuthResult();
            }, Constants.HANDPOINT_POLLING_INTERVAL);
          }
        );
      } else {
        this.setState({
          displaycardreaderstate: "Error communicating with card reader",
        });
      }
    });
  };

  retrieveAuthResult = (userinitiated = false) => {
    // Only check for auth result if we are waiting for an auth results
    if (this.state.cardreaderstate !== Constants.CARD_READER_WAITING && this.state.cardreaderstate !== Constants.CARD_READER_MANUAL_RETRIEVAL) {
      return;
    }
    const url = Constants.URL_PAYMENTS;
    const params = {
      action: Constants.RETRIEVE_RESULT,
      source: Constants.HANDPOINT,
      authuuid: this.state.authuuid,
      userinitiated: userinitiated,
    };
    Helper.getData(url, params).then(response => {
      if (response.status === 200) {
        if (response.body?.status === Constants.AUTH_APPROVED || response.body?.status === Constants.AUTH_PARTIAL_APPROVAL) {
          // If the credit auth was approved, then clear the authuuid and refresh the order
          this.setState({ authuuid: null }, this.props.handleRefreshOrder);
        } else if (response.body?.status === Constants.AUTH_CANCELLED) {
          // User cancelled transaction - Return to pay screen
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Credit transaction cancelled",
            callback: this.props.handleCancel(response.body),
          });
        } else if (response.body?.status === Constants.AUTH_FAILED) {
          // User cancelled transaction - Return to pay screen
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Credit transaction failed",
            callback: this.props.handleCancel(response.body),
          });
        } else if (response.body?.status === Constants.AUTH_DECLINED) {
          // Declined - Show declined message
          const message = response.body?.statusMessage || "Transaction declined";
          this.props.handleDecline(response.body, message);
        } else if (response.body?.status === Constants.AUTH_NEW) {
          // Have not started transaction on card reader terminal - Check terminal
          this.setState({
            displaycardreaderstate: "Waiting for card reader terminal. Check connection and try again",
          });
        } else if (response.body?.status === Constants.AUTH_PENDING) {
          // Auth update not yet received from HandPoint - Check terminal
          const count = Math.max(0, Constants.HANDPOINT_POLLING_COUNT - this.state.pollingCount - 1);
          let prompt = (
            <div>
              Follow instructions on terminal
              <br />
              <CountdownSpinner count={count} />
            </div>
          );
          if (count === 0) {
            prompt = "Timeout. Click the button to check authorization result";
          }
          this.setState(
            prevState => ({
              displaycardreaderstate: prompt,
              pollingCount: prevState.pollingCount + 1,
            }),
            () => {
              if (this.state.pollingCount < Constants.HANDPOINT_POLLING_COUNT + 1) {
                setTimeout(() => {
                  this.retrieveAuthResult();
                }, Constants.HANDPOINT_POLLING_INTERVAL);
              } else {
                this.setState({ cardreaderstate: Constants.CARD_READER_MANUAL_RETRIEVAL });
                this.props.hideOverlay();
              }
            }
          );
        } else {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Unexpected error.",
            callback: this.props.handleCancel,
          });
        }
      } else {
        this.setState({
          displaycardreaderstate: "Error retrieving transaction result",
        });
      }
    });
  };

  stopCurrentTransaction = () => {
    // Only send a cancel request if the card reader is
    // in a state where it is waiting for an action/response and the vendor is HandPoint
    if (
      Helper.inList(
        [Constants.CARD_READER_REQUEST_SALE_AUTH, Constants.CARD_READER_WAITING, Constants.CARD_READER_MANUAL_RETRIEVAL],
        this.state.cardreaderstate
      )
    ) {
      if (this.state.terminal.vendor === Constants.HANDPOINT) {
        this.setState({ cardreaderstate: Constants.CARD_READER_CANCELLING }, this.doStopCurrentTransaction);
      } else {
        this.setState(
          {
            displaycardreaderstate: "Transaction cancelled",
            cardreaderstate: Constants.CARD_READER_READY,
          },
          () => {
            this.submitted = false;
          }
        );
      }
    }
  };

  doStopCurrentTransaction = () => {
    const url = Constants.URL_PAYMENTS;
    const params = {
      action: Constants.STOP_CURRENT_TRANSACTION,
      serialnumber: this.state.terminal.serial_number,
      source: Constants.HANDPOINT,
      terminaltype: this.state.terminal.terminal_type,
      authuuid: this.state.authuuid,
    };
    Helper.postData(url, params).then(response => {
      if (response.status === 200 && response.body.status) {
        // Check for success cancelling
        if (response.body.status === Constants.AUTH_CANCELLED) {
          // HandPoint accepted our cancel request
          // Assume it works and we do not need to take any additional action on this end
          // HandPoint will callback the web service to update the authorization record
          this.setState(
            {
              displaycardreaderstate: "Transaction cancelled",
              cardreaderstate: Constants.CARD_READER_READY,
            },
            () => {
              this.submitted = false;
              this.props.handleCancel(response.body);
            }
          );
        } else {
          let message = "Unable to cancel transaction.";
          this.setState({
            displaycardreaderstate: message,
            cardreaderstate: Constants.CARD_READER_READY,
          });
        }
      } else {
        this.setState({
          displaycardreaderstate: "Error communicating with card reader. Follow instructions on terminal.",
          cardreaderstate: Constants.CARD_READER_READY,
        });
      }
    });
  };

  handleSetTerminals = (handpointTerminals, valorTerminals) => {
    let terminal = null;
    const terminals = handpointTerminals.concat(valorTerminals);

    // COMMENT/UNCOMMENT THIS FOR PRODUCTION/TESTING
    // terminals = terminals.concat([
    //   {
    //     merchant_id_alpha: "tes20230216102324053",
    //     serial_number: "1851161502",
    //     ssk: "8D8F0A241AB099D82F73DA37B5A0F2C78ADA01F7AE221BD6303EF1B8D0612CB4",
    //     terminal_type: "TEST_DO_NOT_USE",
    //     name: "TEST_DO_NOT_USE",
    //   },
    // ]);

    // Check for more than one terminal
    if (terminals.length > 1) {
      // Look for the currently selected terminal in local storage
      // It will be stored as "terminal_type-serial_number"
      let selected_terminal = localStorage.getItem(Constants.HANDPOINT_SELECTED_TERMINAL);
      if (selected_terminal) {
        // Find the selected terminal in the response
        terminal = terminals.find(item => item.terminal_type + "-" + item.serial_number === selected_terminal);
      }
    }

    // If we didn't find the terminal, just use the first one
    if (!terminal && terminals.length > 0) {
      terminal = terminals[0];
    }

    // If we have a selected terminal, then note it in local storage
    if (terminal) {
      localStorage.setItem(Constants.HANDPOINT_SELECTED_TERMINAL, terminal.terminal_type + "-" + terminal.serial_number);
    }

    this.setState(
      {
        error: null,
        downloading: false,
        displaycardreaderstate: "Authorization service contacted",
        cardreaderstate: Constants.CARD_READER_INITIALIZING,
        terminals: terminals,
        terminal: terminal,
      },
      () => {
        if (terminal.vendor === Constants.HANDPOINT) {
          this.pingDevice();
        } else if (terminal.vendor === Constants.VALOR) {
          let cardPrompt = "Click Process to continue.";
          let status = (
            <span>
              Card reader ready.
              <br />
              {cardPrompt}
            </span>
          );
          if (this.state.terminals?.length > 1) {
            // Add a clickable span to show the terminal selection dialog
            status = (
              <span>
                <span className="selectTerminal" onClick={this.handleSelectTerminal}>
                  {this.state.terminal.name}
                </span>{" "}
                card reader ready.
                <br />
                {cardPrompt}
              </span>
            );
          }
          this.setState({ displaycardreaderstate: status, cardreaderstate: Constants.CARD_READER_READY });
        }
      }
    );
  };

  getHandpointTerminals = callback => {
    const url = Constants.URL_PAYMENTS;
    const params = {
      action: Constants.INITIALIZE,
      source: Constants.HANDPOINT,
    };
    Helper.getData(url, params).then(response => {
      if (this.state.manualentry === true) {
        return;
      }
      if (
        response.status >= 200 &&
        response.status < 300 &&
        response.body?.message &&
        Array.isArray(response.body.message) &&
        response.body.message.length > 0
      ) {
        let terminals = response.body.message;
        if (callback) {
          callback(terminals);
        }
      } else {
        if (callback) {
          callback([]);
        }
      }
    });
  };

  getAuthToken = callback => {
    this.setState({
      downloading: true,
    });
    let params = {
      session: localStorage.getItem(Constants.LOCAL_STORAGE_SESSION),
      action: Constants.GET_AUTH_TOKEN,
    };
    const url = Constants.URL_USERS;
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body.authtoken) {
        if (callback) {
          callback(response.body.authtoken);
        }
      } else {
        if (callback) {
          callback();
        }
      }
      this.setState({
        downloading: false,
      });
    });
  };

  getValorTerminals = () => {
    let terminals = this.props.appState?.valor?.epis.map(item => {
      return {
        serial_number: item.value,
        terminal_type: Constants.VALOR,
        name: item.fullname,
        vendor: Constants.VALOR,
      };
    });
    return terminals;
  };

  getTransientToken = () => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_PAYMENTS;
    const params = { action: Constants.REQUEST_TRANSIENT_KEY };
    Helper.getData(url, params).then(response => {
      this.submitted = false;
      if (response.status === 200 && response.body.message.data?.transient_key) {
        //Update state with response, turn off downloading
        this.setState(
          {
            transientkey: response.body.message.data.transient_key,
            error: null,
            downloading: false,
            isNew: false,
            displaycardreaderstate: "Enter card information",
          },
          () => {
            //Hide overlay after database action is complete
            this.props.hideOverlay();
            this.handleManualEntry();
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the data.",
          });
        });
      }
    });
  };

  authorizeCreditRefund = (uniqueRef, amount, processor) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_PAYMENTS;
    const payload = {
      action: Constants.AUTHORIZE_REFUND,
      amount: amount,
      processor: processor,
      orderuuid: this.props.order.orderuuid,
      parentpaymentuuid: this.state.parentpaymentuuid,
      terminaltype: this.state.terminal?.terminal_type ?? "",
      serialnumber: this.state.terminal?.serial_number ?? "",
    };
    // Valor has a multivalue unique identifier, whereas HandPoint/Maast has a single value
    if (processor === Constants.HANDPOINT || processor === Constants.MAAST) {
      payload.pgid = uniqueRef;
    } else if (processor === Constants.NMI) {
      payload.transactionid = uniqueRef;
    }
    Helper.postData(url, payload).then(response => {
      this.submitted = false;
      if (response.status === 200 && response.body) {
        // HandPoint response is different because we are polling for the result
        if (response.body.status === Constants.AUTH_PENDING) {
          this.setState(
            {
              error: null,
              downloading: false,
              cardreaderstate: Constants.CARD_READER_WAITING,
              displaycardreaderstate: "Processing refund on terminal",
              authuuid: response.body.authuuid,
              pollingCount: 0,
            },
            () => {
              setTimeout(() => {
                this.retrieveAuthResult();
              }, Constants.HANDPOINT_POLLING_INTERVAL);
            }
          );
        } else {
          // MAAST or Valor Response returns immediately because the cardreader is not involved
          // The entire order comes back with the credit response after a successful refund
          if (
            (processor === Constants.NMI && response.body.nmi_response_code === Constants.NMI_SALE_APPROVED) ||
            (processor === Constants.MAAST && response.body.rcode === Constants.QP_SALE_APPROVED)
          ) {
            // Approved
            const order = response.body.order;
            this.props.handleCreditApproval(order);
          } else {
            // Declined, order is unchanged
            const message = response.body.rmsg;
            this.props.handleDecline(response.body, message);
          }
        }
      } else if (response.status === 503) {
        this.setState({ error: null, downloading: false }, () => {
          const message = response.body?.message ? response.body.message : "Credit card processor unavailable.";
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: message,
          });
        });
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response?.body?.rmsg ?? "There was an error refunding the payment.",
          });
        });
      }
    });
  };

  authorizeTokenizedPayment = (tokenization_response, action, authtoken = null) => {
    //Protect screen during downloading data
    this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Authorizing payment..." });
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_PAYMENTS;
    let payload = {
      action: action,
      orderuuid: this.props.order.orderuuid,
      // Maast uses "card_id" while NMI uses "token"
      cardid: tokenization_response.card_id || tokenization_response.token,
      amount: numeral(this.state.amount).value(),
      avs_zip: this.state.avsZip,
      tokenization_response: JSON.stringify(tokenization_response),
      partialpayment: this.state.partialpayment,
    };
    if (authtoken) {
      payload.authtoken = authtoken;
    }
    Helper.postData(url, payload).then(response => {
      this.submitted = false;
      if (response.status === 200 && response.body) {
        // The entire order comes back with the credit response after a successful payment
        if (Constants.SALE_APPROVAL_CODES_STRICT.includes(response.body.nmi_response_code || response.body.rcode)) {
          // Approved
          const order = response.body.order;
          this.props.handleCreditApproval(order);
        } else if (response.body.rcode === Constants.QP_SALE_PARTIALLY_APPROVED) {
          // Partial Approval
          const order = response.body.order;
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Amount approved was less than the requested amount.",
            callback: () => {
              this.props.handleCreditApproval(order);
            },
          });
        } else if (response.body.rcode === "101") {
          this.setState({ error: null, downloading: false }, () => {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "Payment gateway configuration error. Contact support.",
            });
          });
        } else {
          // Declined, order is unchanged
          const message = response.body.response_text || response.body.rmsg;
          this.props.handleDecline(response.body, message);
        }
      } else if (response.status === 401) {
        this.setState({ error: null, downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Manager authorization is required.",
          });
        });
      } else if (response.status === 503) {
        this.setState({ error: null, downloading: false }, () => {
          const message = response.body?.response_text ?? response.body?.message ?? "Credit card processor unavailable.";
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: message,
          });
        });
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error authorizing the payment.",
          });
        });
      }
    });
  };
}

export default CreditCardEntry;
