import React from "react";

// Components
import BaseDetailViewComponent from "./BaseDetailViewComponent";
import * as Helper from "./Helper";
import ListHeaderView from "./ListHeaderView";
import ListItemView from "./ListItemView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Functions
import * as Constants from "./Constants";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import numeral from "numeral";
import Switch from "./Switch";
import SubscriptionCard from "./SubscriptionCard";

// View for editing a Billing Subscription.
class RecurringSubscription extends BaseDetailViewComponent {
  constructor(props) {
    super(props);

    let selectedSubscription = null;
    let company = null;
    let contactuuid = null;
    let plan = null;

    if (props.selectedItem?.type === Constants.COMPANY) {
      // New subscription for a customer company
      company = Helper.deepCopy(props.selectedItem);
      contactuuid = company.contacts[0].contactuuid;
    } else if (props.selectedItem?.type === Constants.CONTACT) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Not implemented.",
      });
    } else if (props.selectedItem?.type === Constants.BILLING_PLAN) {
      // New subscription for a plan
      plan = Helper.deepCopy(props.selectedItem);
      plan.status = Constants.CH_SUBSCRIPTION_ACTIVE;
      plan.editMode = true;
      plan.isNew = true;
      if (plan.plan_frequency === Constants.CH_RECURRING_PLAN_FREQUENCY_DAILY) {
        plan.date_start = Helper.tomorrow();
      } else {
        plan.date_start = Helper.tomorrowForBilling(plan.plan_frequency);
        plan.day_of_cycle = Helper.getDayOfCycle(plan.date_start, plan.plan_frequency);
        if (plan.plan_frequency === Constants.CH_RECURRING_PLAN_FREQUENCY_ANNUALLY) {
          plan.month_of_cycle = Helper.getMonthOfCycle(plan.date_start);
        }
      }
      plan.date_next_payments = [];
      plan.card_id = "";
    } else if (props.selectedItem?.type === Constants.SUBSCRIPTION) {
      // Opening an existing subscription from the list screen
      selectedSubscription = props.selectedItem;
      plan = Helper.deepCopy(selectedSubscription);
      // Create a skeletal company and contact until it can be loaded from our database
      let contact = Helper.getBlankContact(props.appState.salesperson, Constants.CUSTOMER_FAMILY);
      contact.firstname = selectedSubscription.firstname;
      contact.lastname = selectedSubscription.lastname;
      contact.contactuuid = selectedSubscription.contactuuid;
      contact.uuid = selectedSubscription.contactuuid;
      contact.mobilephone = selectedSubscription.mobilephone;
      contact.otherphone = selectedSubscription.otherphone;
      contact.editmode = false;
      company = Helper.getBlankCompany(Constants.CUSTOMER_FAMILY, [contact], props.appState.salesperson, true);
      company.companyuuid = selectedSubscription.companyuuid;
      company.companyname = selectedSubscription.companyname;
      company.uuid = selectedSubscription.companyuuid;
    }

    // Move the selected contact to front of list in company, if necessary
    //    to prevent the brief display of the "natural order"
    company = Helper.moveContactToFront(contactuuid, company);

    // If we have a plan, then we are creating a new subscription
    // and the only subscription is based on the plan passed in
    const subscriptions = plan ? [plan] : [];

    // Determine if we need to show inactive subscriptions by default
    // If the selected item is inactive, then show inactive by default
    let hideInactiveSubscriptions = true;
    if (selectedSubscription && !Constants.CH_SUBSCRIPTION_ACTIVE_STATUSES.includes(selectedSubscription.status)) {
      hideInactiveSubscriptions = false;
    }

    this.state = {
      ...this.state,
      company: company, // Full company and contact list - Used for most everything
      contactSearchKey: "",
      contactuuid: contactuuid, // Selected contactuuid from customer list
      subscriptions: subscriptions,
      selectedSubscription: selectedSubscription,
      viewName: Constants.RECURRING,
      maastInvoicesPagination: {
        totalPages: 0,
        pageNumber: 0,
        totalRecords: 0,
      },
      hideInactiveSubscriptions: hideInactiveSubscriptions,
    };
  }

  // Once component is loaded, fire GET request
  componentDidMount() {
    super.componentDidMount();
    if (this.state.company?.companyuuid) {
      this.getSubscriber(this.state.company.companyuuid);
    } else {
      this.setState({ maastCustomer: { vaulted: false }, loading: false }, () => {
        this.props.hideOverlay();
      });
    }
    if (this.state.isNew) {
      this.getBillingPlans();
    }
  }
  // #region Render functions
  renderContactSearchResultsToColumns = item => {
    let fullname = "";
    let mobileviewphone = "";
    let companyname = "";
    fullname = item.firstname + " " + item.lastname;
    // Display mobilephone, then otherphone (if no mobile), then "-" if neither
    mobileviewphone = item.mobilephone ? item.mobilephone : item.otherphone ? item.otherphone : "";
    mobileviewphone = mobileviewphone ? mobileviewphone : item.email;
    companyname = item.companyname ? item.companyname : "";
    fullname = <span data-testid={"Customer Search Results: " + fullname}>{fullname}</span>;
    return [{ rowvalue: fullname }, { rowvalue: mobileviewphone }, { rowvalue: companyname, classes: "desktop" }];
  };

  renderAreaSubscriptionStatus = () => {
    const active =
      this.state.subscriptions?.find(item => item.status === Constants.CH_SUBSCRIPTION_ACTIVE) ||
      this.props.selectedItem?.status === Constants.CH_SUBSCRIPTION_ACTIVE;
    const suspended =
      this.state.subscriptions?.find(item => item.status === Constants.CH_SUBSCRIPTION_SUSPENDED) ||
      this.props.selectedItem?.status === Constants.CH_SUBSCRIPTION_SUSPENDED;
    const paused =
      this.state.subscriptions?.find(item => item.status === Constants.CH_SUBSCRIPTION_PAUSED) ||
      this.props.selectedItem?.status === Constants.CH_SUBSCRIPTION_PAUSED;
    const cancelled =
      this.state.subscriptions?.find(item => item.status === Constants.CH_SUBSCRIPTION_CANCELLED) ||
      this.props.selectedItem?.status === Constants.CH_SUBSCRIPTION_CANCELLED;
    const vaulted = this.state.maastCustomer?.vaulted;
    let customerStatus = "";
    if (this.isMaastOnlyCustomer()) {
      customerStatus = " / Maast-only Customer";
    }
    let status = Helper.renderStatus(this.props.selectedItem?.status || Constants.CH_SUBSCRIPTION_LOADING, null, customerStatus);
    if (this.state.isNew) {
      status = Helper.renderStatus(Constants.CH_SUBSCRIPTION_NEW, null, customerStatus);
    } else if (suspended) {
      status = Helper.renderStatus(Constants.CH_SUBSCRIPTION_SUSPENDED, null, customerStatus);
    } else if (active) {
      status = Helper.renderStatus(Constants.CH_SUBSCRIPTION_ACTIVE, null, customerStatus);
    } else if (paused) {
      status = Helper.renderStatus(Constants.CH_SUBSCRIPTION_PAUSED, null, customerStatus);
    } else if (cancelled) {
      status = Helper.renderStatus(Constants.CH_SUBSCRIPTION_CANCELLED, null, customerStatus);
    } else if (vaulted) {
      status = Helper.renderStatus(Constants.CH_SUBSCRIPTION_VAULTED, null, customerStatus);
    } else {
      status = Helper.renderStatus(Constants.CH_SUBSCRIPTION_NEW, null, customerStatus);
    }

    return <div className="areaSubscriptionStatus">{status}</div>;
  };

  renderAreaStoredPayments = () => {
    const headers = [
      { classes: "header firstLeft", columnheading: "Name" },
      { classes: "header desktop", columnheading: "Card Number" },
      { classes: "header mobile", columnheading: "Card" },
      { classes: "header", columnheading: "Exp" },
      { classes: "header noPrint", columnheading: "" },
      { classes: "header noPrint lastRight", columnheading: "" },
    ];
    const validCompany =
      // Existing customer with a companyuuid
      (this.state.company?.companyuuid && this.state.company?.contacts?.length > 0 && this.state.company.contacts[0].email) ||
      // New customer with a valid contact ready to be saved
      (!this.state.company?.companyuuid &&
        this.state.company?.contacts?.length > 0 &&
        this.state.company.contacts[0].firstname &&
        this.state.company.contacts[0].lastname &&
        this.state.company.contacts[0].email);
    const addButtonClasses = "action-button green-button " + (!validCompany ? "save-disabled" : "");
    return (
      <React.Fragment>
        <div className="areaStoredPaymentsHeader">
          <span>Customer Payment Methods:</span>
          <span data-testid="Add Stored Payment" className={addButtonClasses} onClick={this.handleAddPaymentMethod}>
            Add
          </span>
        </div>
        <div className="areaStoredPayments">
          <ListHeaderView headerRowItems={headers} />
          <ListItemView
            listitems={this.state.maastCustomer?.billing_cards ?? []}
            expandedListItems={null}
            selectedListItems={[]}
            renderItemToColumns={this.renderStoredPaymentsToColumns}
            toggleCollapsed={() => {}}
            selectListItem={() => {}}
            handleEditItem={() => {}}
            handleTouchStart={() => {}}
            handleTouchEnd={() => {}}
          />
        </div>
      </React.Fragment>
    );
  };

  renderStoredPaymentsToColumns = card => {
    const name = (
      <span title="Cardholder Name" data-testid="Cardholder Name">
        {card?.billing_first_name + " " + card?.billing_last_name}
      </span>
    );

    let expirationDate = card?.exp_date ?? "N/A";
    if (expirationDate.length === 4) {
      expirationDate = expirationDate.slice(0, 2) + "/" + expirationDate.slice(2);
    }
    const expirationDateElement = (
      <span title="Expiration Date" data-testid="Expiration Date">
        {expirationDate}
      </span>
    );

    const subsForCard = this.state.subscriptions?.filter(
      sub => sub.card_id === card?.card_id && Constants.CH_SUBSCRIPTION_ACTIVE_STATUSES.includes(sub.status)
    );

    const trashcan =
      !this.state.subscriptions || subsForCard.length > 0 ? (
        <span
          title="Card is in use by an active subscription"
          data-testid="Disabled Card Delete Button"
          className="save-disabled paymentMethodDelete"
        >
          <FontAwesomeIcon icon={faTrash} />
        </span>
      ) : (
        <span
          title="Delete Payment Method"
          data-testid="Card Delete Button"
          className="paymentMethodDelete"
          onClick={() => {
            this.handleDeleteStoredPayment(card);
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </span>
      );
    const cardNumber = (
      <span data-testid="Card Number" title="Card Number">
        {card?.card_number}
      </span>
    );
    let mobileCardNumber = card?.card_number ?? "Unknown";
    if (mobileCardNumber.length > 4 && mobileCardNumber !== "Unknown") {
      mobileCardNumber = mobileCardNumber.slice(-5);
    }
    const mobileCardNumberElement = (
      <span data-testid="Mobile Card Number" title="Card Number">
        {mobileCardNumber}
      </span>
    );
    const editButton = (
      <span
        data-testid="Edit Card Button"
        title="Edit Card Details"
        onClick={() => {
          this.handleEditStoredPayment(card);
        }}
      >
        <FontAwesomeIcon icon={faPencil} />
      </span>
    );
    return [
      { rowvalue: name },
      { rowvalue: cardNumber, classes: "desktop" },
      { rowvalue: mobileCardNumberElement, classes: "mobile" },
      { rowvalue: expirationDateElement },
      { rowvalue: editButton, classes: "noPrint" },
      { rowvalue: trashcan, classes: "noPrint" },
    ];
  };

  renderAreaSubscriptions = () => {
    const headers = [
      { classes: "header desktop centerAligned firstLeft", columnheading: "#" },
      { classes: "header desktop", columnheading: "Subscription" },
      { classes: "header desktop", columnheading: "Frequency" },
      { classes: "header desktop right-aligned", columnheading: "Amount" },
      { classes: "header desktop right-aligned", columnheading: "Overdue" },
      { classes: "header desktop centerAligned", columnheading: "Start Date" },
      { classes: "header desktop centerAligned", columnheading: "Next Bill" },
      { classes: "header desktop centerAligned", columnheading: "Expiration" },
      {
        classes: "header desktop centerAligned",
        columnheading: "Status",
        eyeball: true,
        handleToggleEyeball: this.handleToggleHideInactive,
        eyeballSlashed: this.state.hideInactiveSubscriptions,
        eyeballTitle: "Click to " + (this.state.hideInactiveSubscriptions ? "show" : "hide") + " inactive subscriptions",
      },
      { classes: "header desktop", columnheading: "Payment" },
      { classes: "header desktop lastRight", columnheading: "" },
    ];
    return (
      <div className="areaRecurringSubscriptions desktop-grid">
        <ListHeaderView headerRowItems={headers} />
        <hr className="headerRule11 printOnly" />
        <ListItemView
          listitems={this.state.subscriptions}
          expandedListItems={null}
          selectedListItems={[]}
          renderItemToColumns={this.renderSubscriptionToColumns}
          toggleCollapsed={() => {}}
          selectListItem={() => {}}
          handleEditItem={() => {}}
          handleTouchStart={() => {}}
          handleTouchEnd={() => {}}
        />
      </div>
    );
  };

  renderSubscriptionToColumns = (subscription, index) => {
    if (subscription.editMode || subscription.isNew) {
      const card = (
        <SubscriptionCard
          checkSubscriptionValidationRules={this.checkSubscriptionValidationRules}
          handleBlurCustomField={this.handleBlurCustomField}
          handleBlurSubscription={this.handleBlurSubscription}
          handleCancelAddSubscription={this.handleCancelAddSubscription}
          handleCancelEditSubscription={this.handleCancelEditSubscription}
          handleChangeRecurAmt={this.handleChangeRecurAmt}
          handleChangeCustomField={this.handleChangeCustomField}
          handleChangeDateNext={this.handleChangeDateNext}
          handleChangeDuration={this.handleChangeDuration}
          handleChangePaymentOption={this.handleChangePaymentOption}
          handleChangePlanDescription={this.handleChangePlanDescription}
          handleChangeSubscription={this.handleChangeSubscription}
          handleChangeStartDate={this.handleChangeStartDate}
          handleChangeSubscriptionStatus={this.handleChangeSubscriptionStatus}
          handleChangeUnlimitedDuration={this.handleChangeUnlimitedDuration}
          handleDeleteSubscription={this.handleDeleteSubscription}
          handleExpandSubscription={this.handleExpandSubscription}
          handleSaveNewSubscription={this.handleSaveNewSubscription}
          handleSkipOxtPayment={this.handleSkipOxtPayment}
          handleToggleEditMode={this.handleToggleEditMode}
          index={index}
          isMaastOnlyCustomer={this.isMaastOnlyCustomer()}
          isNewSubscriber={this.state.isNew}
          isReadyToSubmitSubscription={this.isReadyToSubmitSubscription}
          key={subscription.uuid}
          maastCustomer={this.state.maastCustomer}
          renderCustomFields={this.renderCustomFields}
          renderCustomFieldsPrint={this.renderCustomFieldsPrint}
          subscription={subscription}
          subscriptions={this.state.subscriptions}
        />
      );
      return [
        {
          rowvalue: card,
          classes: "span11 noPrint",
        },
      ];
    } else {
      return Helper.renderSubscriptionToColumnsReadonly(
        subscription,
        index,
        this.state.hideInactiveSubscriptions,
        this.handleChangeCustomField,
        this.handleBlurCustomField,
        this.renderCustomFieldsPrint,
        this.props.appState,
        this.state.maastCustomer,
        this.state.subscriptions,
        this.handleToggleEditMode,
        this.isMaastOnlyCustomer(),
        this.handleExpandSubscription
      );
    }
  };

  renderAreaSubscriptionsNarrow = () => {
    const subs = this.state.subscriptions?.map((subscription, index) => {
      // Hide inactive subscriptions, if requested
      if (this.state.hideInactiveSubscriptions && Constants.CH_SUBSCRIPTION_INACTIVE_STATUSES.includes(subscription.status)) {
        return "";
      }
      return (
        <SubscriptionCard
          checkSubscriptionValidationRules={this.checkSubscriptionValidationRules}
          handleBlurCustomField={this.handleBlurCustomField}
          handleBlurSubscription={this.handleBlurSubscription}
          handleCancelAddSubscription={this.handleCancelAddSubscription}
          handleCancelEditSubscription={this.handleCancelEditSubscription}
          handleChangeRecurAmt={this.handleChangeRecurAmt}
          handleChangeDateNext={this.handleChangeDateNext}
          handleChangeDuration={this.handleChangeDuration}
          handleChangePaymentOption={this.handleChangePaymentOption}
          handleChangePlanDescription={this.handleChangePlanDescription}
          handleChangeStartDate={this.handleChangeStartDate}
          handleChangeSubscription={this.handleChangeSubscription}
          handleChangeSubscriptionStatus={this.handleChangeSubscriptionStatus}
          handleChangeUnlimitedDuration={this.handleChangeUnlimitedDuration}
          handleDeleteSubscription={this.handleDeleteSubscription}
          handleExpandSubscription={this.handleExpandSubscription}
          handleSaveNewSubscription={this.handleSaveNewSubscription}
          handleSkipOxtPayment={this.handleSkipOxtPayment}
          handleToggleEditMode={this.handleToggleEditMode}
          index={index}
          isMaastOnlyCustomer={this.isMaastOnlyCustomer()}
          isNewSubscriber={this.state.isNew}
          isReadyToSubmitSubscription={this.isReadyToSubmitSubscription}
          key={subscription.uuid + "Narrow"}
          maastCustomer={this.state.maastCustomer}
          renderCustomFields={this.renderCustomFields}
          renderCustomFieldsPrint={this.renderCustomFieldsPrint}
          subscription={subscription}
          subscriptions={this.state.subscriptions}
        />
      );
    });
    let inactiveSwitch = "";
    if (this.state.subscriptions?.find(sub => Constants.CH_SUBSCRIPTION_INACTIVE_STATUSES.includes(sub.status))) {
      inactiveSwitch = (
        <span className="noPrint">
          <Switch
            label="Show inactive subscriptions?"
            fieldname="showInactiveSubscriptions"
            handleChange={event => {
              this.setState(prevState => ({
                hideInactiveSubscriptions: !prevState.hideInactiveSubscriptions,
              }));
            }}
            checked={!this.state.hideInactiveSubscriptions}
            elementid="switch-inactive-subscriptions"
          />
        </span>
      );
    }
    return (
      <div className="areaSubscriptionsNarrow mobile-grid">
        {subs}
        {inactiveSwitch}
      </div>
    );
  };

  renderAreaOverdueTotal = () => {
    if (numeral(this.state.overdueTotalAmount).value() === 0) {
      return "";
    }

    return (
      <div className="areaOverdueTotal overdueTotalAmount" data-testid="Total Overdue Amount">
        Total Overdue Amount: &nbsp;&nbsp; {numeral(this.state.overdueTotalAmount).format(Constants.CURRENCY_WITH_SYMBOL)}
      </div>
    );
  };

  renderCustomFieldsPrint = (subscription, className) => {
    if (subscription?.custom_fields?.length > 0) {
      // return a list of printable custom fields values
      return subscription.custom_fields.map((field, index) => {
        // cross reference field.name with appState.customFields to get the label
        const customField = this.props.appState.customFields.find(cf => cf.uuid === field.name)?.description;
        const key = subscription.uuid + "-customField-" + index;
        if (customField) {
          return (
            <div key={key} className={className}>
              <span className="customFieldLabel">{customField + ":  "}</span>
              <span className="customFieldValue italic">{field.value}</span>
            </div>
          );
        } else return "";
      });
    } else {
      return "";
    }
  };
  // #endregion

  // #region Event Handlers
  handleRemoveCustomer = () => {
    this.setState({ isNew: true, company: null, contactuuid: null, maastCustomer: { vaulted: false } });
  };

  handleExpandSubscription = subscription => {
    this.setState(prevState => ({
      subscriptions: prevState.subscriptions.map(sub => {
        if (sub.subscription_id === subscription.subscription_id) {
          sub.expanded = !sub.expanded;
        }
        return sub;
      }),
    }));
  };

  handleToggleHideInactive = () => {
    this.setState(prevState => ({
      hideInactiveSubscriptions: !prevState.hideInactiveSubscriptions,
    }));
  };

  handleChangeUnlimitedDuration = (index, event) => {
    const checked = event.target.checked;
    this.setState(
      prevState => ({
        subscriptions: prevState.subscriptions.map((subscription, i) => {
          if (i === index) {
            if (checked) {
              subscription.plan_duration = -1;
            } else {
              subscription.plan_duration = "";
            }
            subscription.plan_duration_unlimited = checked;
            subscription.date_next_payments = [];
          }
          return subscription;
        }),
      }),
      () => {
        this.getRecurringPaymentSchedule(this.state.subscriptions[index], index);
      }
    );
  };

  handleChangeDuration = (index, event) => {
    const plan_duration = event.target.value;
    this.setState(
      prevState => ({
        subscriptions: prevState.subscriptions.map((subscription, i) => {
          if (i === index) {
            subscription.plan_duration = plan_duration;
            subscription.date_next_payments = [];
          }
          return subscription;
        }),
      }),
      () => {
        this.getRecurringPaymentSchedule(this.state.subscriptions[index], index);
      }
    );
  };

  handleChangeStartDate = (index, event) => {
    const date_start = event.target.value;
    this.setState(prevState => ({
      subscriptions: prevState.subscriptions.map((subscription, i) => {
        if (i === index) {
          subscription.date_start = date_start;
          subscription.date_next = date_start;
          this.handleSubscriptionChangeSideEffects(subscription, i, event);
        }
        return subscription;
      }),
    }));
  };

  handleChangeDateNext = (index, event) => {
    const date_next = event.target.value;
    this.setState(prevState => ({
      subscriptions: prevState.subscriptions.map((subscription, i) => {
        if (i === index) {
          subscription.date_next = date_next;
          this.handleSubscriptionChangeSideEffects(subscription, i, event);
        }
        return subscription;
      }),
    }));
  };

  handleChangePlanDescription = (index, event) => {
    const plan_name = event.target.value;
    this.setState(prevState => ({
      subscriptions: prevState.subscriptions.map((subscription, i) => {
        if (i === index) {
          subscription.plan_name = plan_name;
        }
        return subscription;
      }),
    }));
  };

  handleChangeRecurAmt = (index, event) => {
    const recur_amt = event.target.value;
    this.setState(prevState => ({
      subscriptions: prevState.subscriptions.map((subscription, i) => {
        if (i === index) {
          subscription.recur_amt = recur_amt;
        }
        return subscription;
      }),
    }));
  };

  handleChangePaymentOption = (event, index) => {
    const card_id = event.target.value;
    const subscription = this.state.subscriptions[index];
    const prev = subscription.card_id;
    if (prev === card_id) {
      return;
    }
    // Update the card_id in the subscription, then send a PUT request to the server
    const subscriptionuuid = subscription.subscriptionuuid;
    this.setState(
      prevState => ({
        subscriptions: prevState.subscriptions.map((subscription, i) => {
          if (i === index) {
            subscription.card_id = card_id;
          }
          return subscription;
        }),
      }),
      () => {
        // Ignore changes if the subscription is new and unsaved
        if (!subscription.isNew) {
          this.putSubscription(subscriptionuuid, "string", "card_id", card_id);
        }
      }
    );
  };

  handleChangeSubscriptionStatus = (event, index) => {
    const status = event.target.value;
    const prev = this.state.subscriptions[index].status;
    if (prev === status) {
      return;
    }
    this.maybeChangeSubscriptionStatus(index, status);
  };

  maybeChangeSubscriptionStatus = (index, status) => {
    // If there are no payment methods when reactivating a subscription, prompt for a new payment method
    if (this.state.maastCustomer.billing_cards.length === 0 && status === Constants.CH_SUBSCRIPTION_ACTIVE) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Please add a payment method before activating this subscription.",
      });
      return;
    }

    let text = "";
    if (status === Constants.CH_SUBSCRIPTION_CANCELLED) {
      text = "Are you sure you want to permanently cancel this subscription?";
    } else if (status === Constants.CH_SUBSCRIPTION_ACTIVE) {
      text = "Are you sure you want to activate this subscription?";
    } else if (status === Constants.CH_SUBSCRIPTION_PAUSED) {
      text = "Are you sure you want to pause this subscription?";
    } else {
      return;
    }

    // Default behavior is to just change the subscription status
    let handler = this.changeSubscriptionStatus;
    // If reactivating a subscription and there are multiple payment methods, ask the user to select one
    if (this.state.maastCustomer.billing_cards.length > 1 && status === Constants.CH_SUBSCRIPTION_ACTIVE) {
      handler = (response, status, index) => {
        if (response === Constants.OVERLAY_RESPONSE_YES) {
          this.handleChoosePaymentOption(card_id => {
            console.log("Selected card_id: ", card_id);
            this.doChangeSubscriptionStatus(this.state.subscriptions[index].subscriptionuuid, status, index, card_id);
          });
        }
      };
    }
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: text,
      callback: handler,
      key: status,
      value: index,
    });
  };

  handleChoosePaymentOption = callback => {
    this.props.showOverlay({
      type: Constants.OVERLAY_PICKER,
      text: "Select a payment method",
      items: this.state.maastCustomer.billing_cards.map(card => {
        return { id: card.card_id, text: card.card_number };
      }),
      callback: (response, item) => {
        if (response === Constants.OVERLAY_RESPONSE_SELECT) {
          callback(item.id);
        }
      },
    });
  };

  changeSubscriptionStatus = (response, status, index) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      const subscriptionuuid = this.state.subscriptions[index].subscriptionuuid;
      const cardid = this.state.maastCustomer.billing_cards[0].card_id;
      this.doChangeSubscriptionStatus(subscriptionuuid, status, index, cardid);
    }
  };

  doChangeSubscriptionStatus(subscriptionuuid, status, index, card_id = null) {
    const callback = () => {
      this.setState(prevState => ({
        subscriptions: prevState.subscriptions.map((subscription, i) => {
          if (i === index) {
            subscription.status = status;
          }
          return subscription;
        }),
      }));
    };
    this.putSubscription(subscriptionuuid, "string", "status", status, callback, card_id);
  }

  // The oxt checkbox is only visible for saved/existing subscriptions, so every change is immediately saved to the database
  handleSkipOxtPayment = (index, event) => {
    const skip_oxt_payment = event.target.checked;
    this.setState(
      prevState => ({
        subscriptions: prevState.subscriptions.map((subscription, i) => {
          if (i === index) {
            subscription.skip_oxt_payment = skip_oxt_payment;
          }
          return subscription;
        }),
      }),
      () => {
        this.putSubscription(this.state.subscriptions[index].subscriptionuuid, "boolean", "skip_oxt_payment", skip_oxt_payment);
      }
    );
  };

  handleDeleteSubscription = subscription => {
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Are you sure you want to permanently delete this subscription?",
      callback: response => {
        if (response === Constants.OVERLAY_RESPONSE_YES) {
          this.deleteSubscription(subscription);
        }
      },
    });
  };

  handleSaveNewSubscription = subscription => {
    if (!this.isReadyToSubmitSubscription(subscription)) {
      return;
    }
    this.postSubscription(subscription, this.state.company, this.state.maastCustomer);
  };

  handleCancelAddSubscription = subscription => {
    this.setState(prevState => ({
      subscriptions: prevState.subscriptions.filter(sub => sub.subscription_id !== subscription.subscription_id),
    }));
  };

  handleCancelEditSubscription = () => {
    this.setState(prevState => ({
      subscriptions: prevState.subscriptions
        .map(sub => {
          sub.editMode = false;
          return sub;
        })
        .filter(sub => !sub.isNew),
    }));
  };

  handleToggleEditMode = (subscription, index) => {
    // Request the payment schedule
    this.getRecurringPaymentSchedule(subscription, index);
    // Toggle edit mode flag
    this.setState(prevState => ({
      subscriptions: prevState.subscriptions.map(sub => {
        if (sub.subscription_id === subscription.subscription_id) {
          sub.editMode = !sub.editMode;
        }
        return sub;
      }),
    }));
  };

  handleAddNewCustomer = () => {
    const contact = Helper.getBlankContact(this.props.appState.salesperson, Constants.CUSTOMER_FAMILY);
    const company = Helper.getBlankCompany(Constants.CUSTOMER_FAMILY, [contact], this.props.appState.salesperson, true);
    this.setState({ company: company });
  };

  updateCompanyState = (id, value) => {
    this.setState(prevState => ({
      company: {
        ...prevState.company,
        [id]: value,
      },
    }));
  };

  // #endregion

  // Fires when a customer is selected in the search widget
  selectCustomerListItem = contact => {
    // Do not allow selection of protected customers
    if (Helper.isProtectedCustomer(contact, this.props.appState)) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Walk-in/stock order customers cannot be enrolled in recurring billing",
      });
      return;
    }
    const companyuuid = contact.companyuuid;
    this.setState({ contactuuid: contact.contactuuid }, () => {
      this.handleClearCustomerSearch();
      if (this.state.isNew) {
        this.getSubscriber(companyuuid);
      }
    });
  };

  // Are we ready to add a new subscription?
  isReadyToSubmitSubscription = subscription => {
    // Customer attributes
    if (
      !this.state.company ||
      !this.state.company.contacts ||
      !Array.isArray(this.state.company.contacts) ||
      this.state.company.contacts.length === 0 ||
      !this.state.company.contacts[0].firstname ||
      !this.state.company.contacts[0].lastname ||
      !this.state.company.contacts[0].email
    ) {
      return false;
    }
    // Maast customer attributes
    if (
      !this.state.maastCustomer ||
      !this.state.maastCustomer.billing_cards ||
      !Array.isArray(this.state.maastCustomer.billing_cards) ||
      this.state.maastCustomer.billing_cards.length === 0
    ) {
      return false;
    }
    // Common subscription attributes
    if (
      !subscription ||
      !subscription.plan_name ||
      !subscription.plan_frequency ||
      !subscription.recur_amt ||
      numeral(subscription.recur_amt).value() <= 0 ||
      !subscription.date_start ||
      !Helper.isValidDate(subscription.date_start) ||
      !subscription.plan_duration ||
      subscription.plan_duration < -1 ||
      subscription.plan_duration === 0 ||
      !subscription.card_id
    ) {
      return false;
    }

    const violations = this.checkSubscriptionValidationRules(subscription);
    // if (violations) {
    //   console.log("Violations:", violations);
    // }
    if (violations.length > 0) {
      return false;
    }

    return true;
  };

  checkSubscriptionValidationRules = subscription => {
    let violations = [];
    const date_start = new Date(subscription.date_start + "T00:00:00Z");
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    // For "New" subscriptions, make sure the start date is not in the past or today
    if (date_start <= now && subscription.isNew) {
      violations.push({ plan_frequency: subscription.plan_frequency, failure: "date_start", message: "Start date must be in the future." });
    }

    // Weekly subscriptions rules
    if (subscription.plan_frequency === Constants.CH_RECURRING_PLAN_FREQUENCY_WEEKLY) {
      // Weekly subscriptions cannot start on a day less than 0 (Sunday) or greater than 6 (Saturday)
      if (isNaN(subscription.day_of_cycle) || subscription.day_of_cycle < 0 || subscription.day_of_cycle > 6) {
        violations.push({ plan_frequency: Constants.CH_RECURRING_PLAN_FREQUENCY_WEEKLY, failure: "day_of_cycle", message: "Invalid day of week." });
      }
    }
    // Monthly subscriptions rules
    else if (subscription.plan_frequency === Constants.CH_RECURRING_PLAN_FREQUENCY_MONTHLY) {
      if (date_start.getUTCDate() > 28) {
        // Monthly subscriptions cannot start on the 29th, 30th, or 31st
        violations.push({
          plan_frequency: Constants.CH_RECURRING_PLAN_FREQUENCY_MONTHLY,
          failure: "date_start",
          message: "Monthly subscriptions must start between the 1st and 28th.",
        });
      }
    }
    // Annual subscriptions rules
    else if (subscription.plan_frequency === Constants.CH_RECURRING_PLAN_FREQUENCY_ANNUALLY) {
      // NEW: Annual subscriptions cannot start on the 29th of February
      if (
        !subscription.date_start ||
        (Helper.getMonthFromDateString(subscription.date_start) === Constants.JS_FEBRUARY &&
          Helper.getDateFromDateString(subscription.date_start) === 29)
      ) {
        violations.push({
          plan_frequency: Constants.CH_RECURRING_PLAN_FREQUENCY_ANNUALLY,
          failure: "date_start",
          message: "Annual subscriptions cannot start on February 29th.",
        });
      }
      // EXISTING: Annual subscriptions cannot start on the 29th of February
      if (!subscription.day_of_cycle || (subscription.month_of_cycle === Constants.JS_FEBRUARY && subscription.day_of_cycle === 29)) {
        violations.push({
          plan_frequency: Constants.CH_RECURRING_PLAN_FREQUENCY_ANNUALLY,
          failure: "day_of_cycle",
          message: "Annual subscriptions cannot start on February 29th.",
        });
      }
      // Annual subscriptions cannot start on a month less than 0 (January) or greater than 11 (December)
      if (
        subscription.month_of_cycle === null ||
        subscription.month_of_cycle === undefined ||
        subscription.month_of_cycle < 0 ||
        subscription.month_of_cycle > 11
      ) {
        violations.push({ plan_frequency: Constants.CH_RECURRING_PLAN_FREQUENCY_ANNUALLY, failure: "month_of_cycle", message: "Invalid month." });
      }
    }
    return violations;
  };

  isReadyToSubmit = () => {
    // Customer with no subscriptions adding a new subscription.
    if (this.state.subscriptions?.length === 0) {
      return false;
    }
    const subscription = this.state.subscriptions[0];
    const billingcards = this.state.maastCustomer?.billing_cards ?? [];
    const contactzero = this.state.company?.contacts?.length > 0 ? this.state.company?.contacts[0] : {};
    return (
      this.state.company &&
      contactzero.firstname &&
      contactzero.lastname &&
      contactzero.email &&
      subscription.plan_name &&
      subscription.card_id &&
      subscription.recur_amt &&
      numeral(subscription.recur_amt).value() > 0 &&
      subscription.date_start &&
      subscription.plan_frequency &&
      subscription.plan_duration &&
      billingcards.find(card => card.card_id === subscription.card_id) &&
      this.state.maastCustomer?.vaulted !== undefined
    );
  };

  getContactsContainerRef = () => {
    return this.state.company;
  };

  setStateContacts = (contacts, callback = null) => {
    this.setState(
      prevState => ({
        company: {
          ...prevState.company,
          contacts: contacts,
        },
      }),
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  save = () => {
    if (this.isReadyToSubmit()) {
      // Verify the customer has an email address
      if (!this.state.company.contacts[0].email) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Customer must have an email address to create a subscription.",
        });
        return;
      }
      this.postSubscription(this.state.subscriptions[0], this.state.company, this.state.maastCustomer); // this.state.maastCustomer contains billing cards
    }
  };

  // TODO: DRY the following functions. They are in Customer.jsx as well.
  postContact = (contact, callback) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_CONTACTS;
    const companyuuid = this.state.company?.companyuuid ? this.state.company.companyuuid : "";
    const newcompany = companyuuid === "";
    const companytype = contact.companyname ? Constants.CUSTOMER_COMPANY : Constants.CUSTOMER_FAMILY;
    if (this.state.isNew) {
      contact.taxable = this.state.company?.taxable;
    }
    contact = { ...contact, companyuuid: companyuuid, companytype: companytype };
    Helper.postData(url, contact).then(response => {
      if (response.status === 200 && response.body) {
        const companyuuid = response.body.companyuuid;
        if (newcompany) {
          this.getCompany(companyuuid, () => {
            if (callback) {
              callback();
            }
          });
        } else {
          // Set the edit mode to keep the new contact's card open
          const contacts = this.state.company.contacts.map(contact => {
            if (contact.contactuuid === "new") {
              response.body.editmode = true;
              return response.body;
            }
            return contact;
          });
          // Update state with response, turn off downloading and hide the overlay
          this.setStateContacts(contacts, () => {
            this.setState({ downloading: false });
            this.props.hideOverlay();
            if (callback) {
              callback();
            }
          });
        }
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the contact.",
          });
        });
      }
    });
  };

  postShipAddress = contactuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = "/addresses";
    const address = {
      ...Helper.getContactByUUID(this.state.company, contactuuid).shippingaddress,
      contactuuid: contactuuid,
    };
    Helper.postData(url, address).then(response => {
      if (response.status === 200 && response.body) {
        const contacts = this.state.company.contacts.map(contact => {
          if (contact.contactuuid === contactuuid) {
            contact.shippingaddress = response.body;
          }
          return contact;
        });
        // Update state with response, turn off downloading and hide the overlay
        this.setStateContacts(contacts, () => {
          this.setState({ downloading: false });
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ downloading: false });
        this.setState({ error: "POST" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the shipping address.",
          });
        });
      }
    });
  };

  putCompany = (companyuuid, fieldname, value) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_COMPANIES;
    let data = {
      companyuuid: companyuuid,
      [fieldname]: value,
    };
    // If the company name was added, changed, or deleted, then update the company type, as appropriate.
    if (fieldname === "companyname") {
      // Having a company name value means the company type is "2" (e.g. "corporate").
      // No company name value means a company type of Constants.CUSTOMER_FAMILY.
      data["companytype"] = value ? Constants.CUSTOMER_COMPANY : Constants.CUSTOMER_FAMILY;
    }
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        //Update state to turn off downloading
        this.setState({ downloading: false });
        //Hide overlay after database action is complete
        this.props.hideOverlay();
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the customer.",
          });
        });
      }
    });
  };

  putShipAddress = (addressuuid, fieldname, value) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });
    //set up to make database call
    const url = "/addresses";
    const data = {
      addressuuid: addressuuid,
      [fieldname]: value,
    };
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Replace contact with data from web service but keep editing flag
        const contacts = this.state.company.contacts.map(contact => {
          if (contact.shippingaddress.addressuuid === addressuuid) {
            contact.shippingaddress = response.body;
          }
          return contact;
        });
        // Update state with response, turn off downloading and hide the overlay
        this.setStateContacts(contacts, () => {
          this.setState({ downloading: false });
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ downloading: false });
        this.setState({ error: "PUT" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the shipping address.",
          });
        });
      }
    });
  };

  deleteContact = contactuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    // If new contact, just remove from list
    if (contactuuid === "new") {
      const contacts = this.state.company.contacts.filter(contact => contact.contactuuid !== "new");
      this.setStateContacts(contacts, () => {
        this.setState({ downloading: false });
        this.props.hideOverlay();
      });
    } else {
      //set up to make database call
      const url = Constants.URL_CONTACTS;
      const params = { contactuuid: contactuuid };
      Helper.deleteData(url, params).then(response => {
        if (response.status === 200 && response.body) {
          // Remove the deleted contact from the list and redisplay
          const contacts = this.state.company.contacts.filter(contact => contact.contactuuid !== contactuuid);
          // If this was the last contact, then the webservice will delete the company
          if (contacts.length === 0) {
            //Update state to turn off downloading
            this.setState({ downloading: false, error: null });
            //Hide overlay after database action is complete
            this.props.hideOverlay();
            //Return to the List screen
            this.props.followBreadcrumb();
          } else {
            // Update state with response, turn off downloading and hide the overlay
            this.setStateContacts(contacts, () => {
              this.setState({ downloading: false });
              this.props.hideOverlay();
            });
          }
        } else if (response.status === 409 || response.status === 202) {
          // A 202 indicates the contact was made inactive, not deleted
          if (response.status === 202) {
            // Update the contact to be inactive
            const contacts = this.state.company.contacts.map(contact => {
              if (contact.contactuuid === contactuuid) {
                contact.active = false;
              }
              return contact;
            });
            // Update state with updated contact(s), turn off downloading and hide the overlay
            this.setStateContacts(contacts, () => {
              this.setState({ error: null, downloading: false }, () => {
                this.props.showOverlay({
                  type: Constants.OVERLAY_MESSAGE,
                  text: response.body.message,
                });
              });
            });
          } else {
            // A 409 indicates the contact was not deleted because it is a protected contact
            // For example, the "walk-in" customer or "stock order" contact
            this.setState({ error: null, downloading: false }, () => {
              this.props.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: response.body.message,
              });
            });
          }
        } else if (response.status === 503) {
          this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
          this.props.hideOverlay();
        } else {
          this.setState({ error: "DELETE", downloading: false }, () => {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "There was an error deleting the contact.",
            });
          });
        }
      });
    }
  };

  deleteCompany = companyuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_COMPANIES;
    const params = { companyuuid: companyuuid };
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Update state to turn off downloading, hide the overlay, and follow breadcrumbs
        this.setState({ downloading: false, error: null }, () => {
          this.props.hideOverlay();
          this.props.followBreadcrumb();
        });
      } else if (response.status === 409 || response.status === 202) {
        // A 409 indicates the company was not deleted because one or more contacts have existing orders
        this.setState({ error: null, downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body.message,
          });
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting.",
          });
        });
      }
    });
  };

  deleteShipAddress = contactuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    const contact = Helper.getContactByUUID(this.state.company, contactuuid);
    const addressuuid = contact.shippingaddress.addressuuid;
    //set up to make database call
    const url = "/addresses";
    const params = { addressuuid: addressuuid };
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Turn off the shipping address switch
        const contacts = this.state.company.contacts.map(contact => {
          if (contact.contactuuid === contactuuid) {
            contact.hasshippingaddress = false;
            contact.shippingaddress = Helper.getBlankShippingAddress();
          }
          return contact;
        });
        // Update state with response, turn off downloading and hide the overlay
        this.setStateContacts(contacts, () => {
          this.setState({ downloading: false });
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ downloading: false });
        this.setState({ error: "DELETE" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting.",
          });
        });
      }
    });
  };

  getOrderType = () => {
    return Constants.RECURRING;
  };
}
export default RecurringSubscription;
