import React from "react";

import * as Helper from "./Helper";
import * as Constants from "./Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faTrash, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import Switch from "./Switch";

function SettingsItemList(props) {
  return (
    <div className="settingsListWrapper">
      <div>
        <span data-testid={props.type} className="action-button green-button addSetting" onClick={props.handleAddSetting}>
          Add
        </span>
      </div>
      {props.renderTable ? renderTable(props) : renderGrid(props)}
    </div>
  );
}

function renderTable(props) {
  const dragStart = props.enableRowReordering ? props.handleDragStart : () => {};

  const rows = props.items.map((item, rowindex) => {
    const id = item.isNew ? rowindex : item.uuid;
    const key = "tr-" + id;
    const columns = props.cols.map((col, colindex) => {
      if ((item.isNew || col.editableAfterSave) && col.select) {
        return renderUserSelectTable(props, col, item);
      } else if (col.type === "checkbox") {
        return renderCheckboxElement(props, col, item);
      } else {
        return renderTextInput(props, key, item, col, colindex, rowindex);
      }
    });
    return (
      <tr
        key={key}
        data-key={key}
        data-uuid={item.uuid}
        data-type={props.type}
        draggable={props.enableRowReordering}
        onDragStart={event => {
          dragStart(event, rowindex, props.type);
        }}
        onDragOver={props.handleDragOver}
        onDragEnd={props.handleDragEnd}
        onDrop={props.handleDrop}
      >
        {columns}
        <td className="trash">{renderTrashCan(props, item)}</td>
      </tr>
    );
  });
  return (
    <table className="settingsTable">
      <tbody>{rows}</tbody>
    </table>
  );
}

// 09/22/2023 - Autofocus not working in a grid for some reason, so we stopped using it.
function renderGrid(props) {
  const rows = props.items.map((item, rowindex) => {
    const key = "tr-" + item.isNew ? rowindex : item.uuid;
    const columns = props.cols.map((col, colindex) => {
      if (item.isNew && col.select) {
        return renderUserSelectGrid(props, col, item);
      } else if (col.select && col.type === "select") {
        return renderUserSelectGrid(props, col, item);
      } else if (col.type === "checkbox") {
        return renderCheckboxElement(props, col, item);
      } else if (col.type === "lock") {
        return renderLockElement(props, col, item);
      } else {
        return renderTextInput(props, key, item, col, colindex, rowindex);
      }
    });
    return (
      <div key={key} data-key={key} className="settingsListGrid">
        {columns}
        <div>{renderTrashCan(props, item)}</div>
      </div>
    );
  });
  return <div>{rows}</div>;
}

function renderUserSelectTable(props, col, item) {
  const key = "td-" + item?.uuid;
  return (
    <td key={key} data-key={key}>
      {renderUserSelectElement(col, item, props)}
    </td>
  );
}

function renderUserSelectGrid(props, col, item) {
  const key = "td-" + item?.uuid;
  return (
    <div key={key} data-key={key}>
      {renderUserSelectElement(col, item, props)}
    </div>
  );
}

function renderUserSelectElement(col, item, props) {
  if (item.isNew && col.editableAfterSave) {
    return (
      <select name="usertype" disabled={true}>
        <option value="" data-key="0">
          Please Select
        </option>
      </select>
    );
  }
  if (item?.usertype === Constants.USER_TYPE_ADMIN) {
    return (
      <select name="usertype" id={item?.uuid} disabled={true}>
        <option value="" key="0" data-key="0">
          Administrator
        </option>
      </select>
    );
  }
  const editable = (item.editable && item.editable()) ?? true;
  return (
    <select
      name="usertype"
      disabled={!editable}
      onFocus={Helper.handleFocus}
      onChange={event => {
        col.handleChange(event, item?.uuid);
      }}
      value={props.newItemDefault === null ? item?.usertype : props.newItemDefault}
    >
      {renderSelectOptions(props)}
    </select>
  );
}

function renderSelectOptions(props) {
  let newItemOptions = props.newItemOptions.map(option => {
    const key = "opt-" + option.uuid;
    return (
      <option value={option.uuid} key={key} data-key={key}>
        {option[props.newItemAttribute]}
      </option>
    );
  });
  newItemOptions = [
    <option value="" key="0" data-key="0">
      Please Select
    </option>,
  ].concat(newItemOptions);
  return newItemOptions;
}

function renderTextInput(props, key, item, col, colindex, rowindex) {
  const inputClasses = (col.classes ?? "") + " " + (item.isNew ? " dirty " : "");
  let handleChange = () => {};
  let handlBlur = () => {};
  if (!col.readonly) {
    handlBlur = (event, uuid) => {
      col.handleBlur(event, uuid, rowindex);
    };
    handleChange = (event, uuid) => {
      col.handleChange(event, uuid);
    };
  }
  let value = item[col.name];
  if (col.isUser && props.users) {
    const user = props.users.find(user => user.uuid === item.value);
    if (user) {
      value = user.fullname;
    }
  }
  const editable = (item.editable && item.editable()) ?? true;
  const autofocus = item.isNew && colindex === 0 ? true : false;
  const input = (
    <input
      type={col.type === "number" ? "number" : "text"}
      name={col.name}
      disabled={!editable}
      className={inputClasses}
      autoComplete="off"
      autoFocus={autofocus}
      maxLength={col.maxLength}
      size={col.size}
      step={col.type === "number" ? col.step : null}
      min={col.type === "number" ? col.min : null}
      max={col.type === "number" ? col.max : null}
      placeholder={col.placeholder}
      onFocus={Helper.handleFocus}
      onChange={event => {
        handleChange(event, item.uuid);
      }}
      onBlur={event => {
        handlBlur(event, item.uuid, rowindex);
      }}
      value={value ?? ""}
    />
  );
  if (props.renderTable) {
    return (
      <td key={key + "-" + col.name} data-key={key + "-" + col.name}>
        {input}
      </td>
    );
  } else {
    return (
      <div key={key + "-" + col.name} data-key={key + "-" + col.name}>
        {input}
      </div>
    );
  }
}

function renderLockElement(props, col, item) {
  const editable = col.editable ?? true;
  const locked = item.locked ?? false;
  if (!editable) {
    return "";
  }
  const icon = locked ? <FontAwesomeIcon icon={faLock} /> : <FontAwesomeIcon icon={faUnlockAlt} />;
  const classes = locked ? "highlight" : "";
  const buttonElement = (
    <span
      className={classes}
      onClick={event => {
        col.handleClick(event, item.uuid, item);
      }}
    >
      {icon}
    </span>
  );
  return buttonElement;
}

function renderCheckboxElement(props, col, item) {
  const editable = (item.editable && item.editable()) ?? true;
  const switchElement = (
    <Switch
      label="Reply-To allowed"
      key={item.uuid}
      fielddisabled={!editable}
      switchClass="replyTo"
      checked={item[col.name] ?? false}
      handleChange={event => {
        col.handleChange(event, item.uuid, item);
      }}
      name={col.name}
    />
  );
  if (props.renderTable) {
    return <td key={"container-" + item.uuid}>{switchElement}</td>;
  } else {
    return switchElement;
  }
}

function renderTrashCan(props, item) {
  const handler = item.editable && item.editable() ? () => props.handleDeleteSetting(item.uuid) : () => {};
  const classes = "deletesetting" + (item.editable && item.editable() ? "" : " save-disabled");
  return (
    <span className={classes} data-testid="Delete Setting" onClick={handler}>
      <FontAwesomeIcon icon={faTrash} />
    </span>
  );
}

export default SettingsItemList;
