import React from "react";

// Components
import BaseListViewComponent from "./BaseListViewComponent";

// Functions
import * as Constants from "./Constants";
import * as Helper from "./Helper";
import numeral from "numeral";

class RecurringList extends BaseListViewComponent {
  constructor(props) {
    super(props);
    // Override default state here
    this.state = {
      ...this.state,
      cronjobid: null,
      hideFilter: props.filtertype?.tab === Constants.TAB_INVOICES ? false : true,
      sortkey: "contactname",
      sortdir: "D",
    };
  }

  componentDidUpdate(prevProps) {
    // If we're switched from the cron tab to the cron log tab, then we need to reload the list
    if (
      prevProps.filtertype?.tab === Constants.TAB_CRON &&
      this.props.filtertype?.tab === Constants.TAB_CRON_LOG &&
      this.props.filtertype?.selectedItem?.type === Constants.CRON
    ) {
      this.setState({ cronjobid: this.props.filtertype.selectedItem.cronjobid }, this.getListItems);
    }
  }

  getHeaderRowItems = () => {
    if (
      this.props.filtertype?.tab === Constants.TAB_ACTIVE ||
      this.props.filtertype?.tab === Constants.TAB_INACTIVE ||
      this.props.filtertype?.tab === Constants.TAB_OWING
    ) {
      return this.getHeaderRowRecurring();
    } else if (this.props.filtertype?.tab === Constants.TAB_PLANS) {
      return this.getHeaderRowPlans();
    } else if (this.props.filtertype?.tab === Constants.TAB_INVOICES) {
      return this.getHeaderRowInvoices();
    } else if (this.props.filtertype?.tab === Constants.TAB_CRON) {
      return this.getHeaderRowCron();
    } else if (this.props.filtertype?.tab === Constants.TAB_CRON_LOG) {
      return this.getHeaderRowCronLog();
    }
  };

  renderItemToColumns = item => {
    if (
      this.props.filtertype?.tab === Constants.TAB_ACTIVE ||
      this.props.filtertype?.tab === Constants.TAB_INACTIVE ||
      this.props.filtertype?.tab === Constants.TAB_OWING
    ) {
      return this.renderItemToColumnsRecurring(item);
    } else if (this.props.filtertype?.tab === Constants.TAB_INVOICES) {
      return this.renderItemToColumnsInvoices(item);
    } else if (this.props.filtertype?.tab === Constants.TAB_PLANS) {
      return this.renderItemToColumnsPlans(item);
    } else if (this.props.filtertype?.tab === Constants.TAB_CRON) {
      return this.renderItemToColumnsCron(item);
    } else if (this.props.filtertype?.tab === Constants.TAB_CRON_LOG) {
      return this.renderItemToColumnsCronLog(item);
    } else {
      return [];
    }
  };

  getHeaderRowCronLog = () => {
    return [
      { classes: "header firstLeft", columnheading: "Date" },
      { classes: "header", columnheading: "creationdatetime" },
      { classes: "header", columnheading: "ID" },
      { classes: "header", columnheading: "Sub" },
      { classes: "header", columnheading: "Order" },
      { classes: "header", columnheading: "Type" },
      { classes: "header lastRight", columnheading: "Message" },
    ];
  };

  getHeaderRowCron = () => {
    return [
      { classes: "header firstLeft", columnheading: "Date" },
      { classes: "header centerAligned", columnheading: "Batch #" },
      { classes: "header centerAligned", columnheading: "Total" },
      { classes: "header centerAligned", columnheading: "Started" },
      { classes: "header centerAligned", columnheading: "Finished" },
      { classes: "header centerAligned", columnheading: "Invoices" },
      { classes: "header centerAligned", columnheading: "Paid" },
      { classes: "header centerAligned", columnheading: "Declines" },
      { classes: "header centerAligned", columnheading: "Suspended" },
      { classes: "header centerAligned", columnheading: "Invoices Emailed" },
      { classes: "header lastRight", columnheading: "Other Processing Notes" },
    ];
  };

  getHeaderRowInvoices = () => {
    return [
      { classes: "header firstLeft", sortable: true, columnheading: "Date", sortkey: "orders.creationdatetime" },
      { classes: "header desktop", sortable: true, columnheading: "Invoice", sortkey: "orders.ordernumber" },
      { classes: "header", sortable: true, columnheading: "Customer", sortkey: "orders.contactname" },
      { classes: "header desktop", sortable: true, columnheading: "Subscription", sortkey: "first_item.productname" },
      { classes: "header right-aligned", sortable: true, columnheading: "Total", sortkey: "totalpriceplustax" },
      { classes: "header desktop centerAligned lastRight", sortable: true, columnheading: "Status", sortkey: "orders.orderstatus" },
    ];
  };

  getHeaderRowRecurring = () => {
    return [
      {
        classes: "header firstLeft desktop",
        sortable: true,
        columnheading: "#",
        sortkey: "subscriptions.subscriptionnumber",
      },
      {
        classes: "header",
        sortable: true,
        columnheading: "Customer",
        sortkey: "contactname",
      },
      {
        classes: "header desktop",
        sortable: false,
        columnheading: "Phone Number",
      },
      {
        classes: "header mobile",
        sortable: false,
        columnheading: "Phone",
      },
      {
        classes: "header desktop",
        sortable: true,
        columnheading: "Subscription",
        sortkey: "subscriptions.plan_name",
      },
      {
        classes: "header centerAligned desktop",
        sortable: true,
        columnheading: "Status",
        sortkey: "subscriptions.status",
      },
      {
        classes: "header right-aligned desktop",
        sortable: true,
        columnheading: "Draft Amount",
        sortkey: "subscriptions.recur_amt",
      },
      {
        classes: "header right-aligned desktop",
        sortable: true,
        columnheading: "Overdue Balance",
        sortkey: "amt_overdue_total",
      },
      {
        classes: "header right-aligned mobile",
        sortable: true,
        columnheading: "Balance",
        sortkey: "amt_overdue_total",
      },
      {
        classes: "header right-aligned desktop lastRight",
        sortable: true,
        columnheading: "Next Draft",
        sortkey: "subscriptions.date_next",
      },
    ];
  };

  getHeaderRowPlans = () => {
    return [
      {
        classes: "header firstLeft",
        sortable: false,
        columnheading: "Template Name",
        sortkey: "plan_name",
      },
      {
        classes: "header right-aligned",
        sortable: false,
        columnheading: "Amount",
        sortkey: "recur_amt",
      },
      {
        classes: "header centerAligned",
        sortable: false,
        columnheading: "Frequency",
        sortkey: "plan_frequency",
      },
      {
        classes: "header centerAligned desktop",
        sortable: false,
        columnheading: "Duration",
        sortkey: "plan_duration",
      },

      {
        classes: "header desktop lastRight",
        sortable: false,
        columnheading: "Created",
        sortkey: "creationdatetime",
      },
    ];
  };

  renderItemToColumnsCronLog = item => {
    return [
      { rowvalue: Helper.formatDate(item.cron_date) },
      { rowvalue: Helper.formatDateTime(item.creationdatetime) },
      { rowvalue: item.cronjobid },
      { rowvalue: item.subscription_id },
      { rowvalue: item.orderid },
      { rowvalue: item.cronlogtype },
      { rowvalue: Helper.renderCronLogMessage(item.cronlogtype, item.cronlogmessage) },
    ];
  };

  renderItemToColumnsCron = item => {
    if (!item) {
      return [];
    }
    let notes = "";
    if (item.email_failed) {
      notes += `${item.email_failed} emails failed to send.`;
    }
    if (item.process_subscription_failed) {
      notes += `${item.process_subscription_failed} subscriptions failed to process.`;
    }
    if (item.invoice_failed) {
      notes += `${item.invoice_failed} invoices failed to create.`;
    }
    if (item.no_vaulted_payment) {
      notes += `${item.no_vaulted_payment} subscriptions had no vaulted payment.`;
    }
    if (item.payment_error) {
      notes += `${item.payment_error} payment errors.`;
    }
    if (item.payment_network_error) {
      notes += `${item.payment_network_error} payment network errors.`;
    }
    if (item.no_balance_due) {
      notes += `${item.no_balance_due} invoices had no balance due.`;
    }
    if (item.process_subscription_exception) {
      notes += `${item.process_subscription_exception} exceptions occurred during subscription processing.`;
    }
    if (item.suspend_failure) {
      notes += `${item.suspend_failure} subscriptions could not be suspended.`;
    }
    let suspends = item.already_suspended;
    if (item.suspend_success) {
      suspends += ` + ${item.suspend_success} new`;
    }
    return [
      { rowvalue: Helper.formatDateTime(item.cron_date_time), classes: "" },
      { rowvalue: item.cronjobid, classes: "centerAligned " },
      { rowvalue: item.subscription_count, classes: "centerAligned " },
      { rowvalue: item.process_subscription_start, classes: "centerAligned " },
      { rowvalue: item.process_subscription_end, classes: "centerAligned " },
      { rowvalue: item.invoice_created, classes: "centerAligned " },
      { rowvalue: item.payment_success, classes: "centerAligned " },
      { rowvalue: item.payment_failed, classes: "centerAligned " },
      { rowvalue: suspends, classes: "centerAligned " },
      { rowvalue: item.email_sent, classes: "centerAligned " },
      { rowvalue: notes, classes: "" },
    ];
  };

  renderItemToColumnsRecurring = item => {
    if (!item) {
      return [];
    }
    const recurAmount = <span data-testid="Recurring Amount from List"> {numeral(item.recur_amt).format(Constants.CURRENCY)}</span>;
    const overdueAmount = <span data-testid="Overdue Amount from List">{numeral(item.amt_overdue_total).format(Constants.CURRENCY)}</span>;
    const expandedName = item.firstname + " " + item.lastname;
    const subscriptionnumber = <span data-testid="Subscription Number from List">{item.subscriptionnumber}</span>;

    const customername = <span data-testid="Customer Name from List">{expandedName}</span>;
    const desktopBalanceClasses = "right-aligned desktop " + (numeral(item.amt_overdue_total).value() > 0 ? " highlight " : "");
    const mobileBalanceClasses =
      "right-aligned mobile balanceBadge " + item.status + " " + (numeral(item.amt_overdue_total).value() > 0 ? " highlight " : "");
    const mobileNameClasses = "mobile balanceBadge " + item.status;
    const determinedPhone = Helper.formatPhoneNumber(item.mobilephone || item.otherphone);
    const customerPhone = <span data-testid="Customer Phone from List">{determinedPhone}</span>;
    const subscriptionName = <span data-testid="Subscription Name from List">{item.plan_name}</span>;
    const status = <span data-testid="Subscription Status from List">{Helper.renderStatus(item.status)}</span>;
    const date_next = item.date_next ? Helper.formatDate(item.date_next, false) : "None Scheduled";
    // const date_end = item.date_end ? Helper.formatDate(item.date_end, false) : "None Scheduled";
    const nextBillDate = <span data-testid="Next Bill Date from List">{date_next}</span>;
    return [
      { rowvalue: subscriptionnumber, classes: "desktop" },
      { rowvalue: customername, classes: "desktop" },
      { rowvalue: customername, classes: mobileNameClasses },
      { rowvalue: customerPhone, classes: "desktop" },
      { rowvalue: customerPhone, classes: mobileNameClasses },
      { rowvalue: subscriptionName, classes: "desktop" },
      { rowvalue: status, classes: "centerAligned desktop" },
      { rowvalue: recurAmount, classes: "right-aligned desktop" },
      { rowvalue: overdueAmount, classes: desktopBalanceClasses },
      { rowvalue: overdueAmount, classes: mobileBalanceClasses },
      { rowvalue: nextBillDate, classes: "right-aligned desktop" },
    ];
  };

  renderItemToColumnsPlans = plan => {
    let duration = plan.plan_duration;
    if (plan.plan_duration === -1) {
      duration = "Until Cancelled";
    } else if (duration === 1) {
      duration = duration + " occurrence";
    } else {
      duration = duration + " occurrences";
    }
    const templateName = <span data-testid="Template Name from List">{plan.plan_name}</span>;
    const templateAmount = <span data-testid="Template Amount from List">{numeral(plan.recur_amt).format(Constants.CURRENCY)}</span>;
    const templateFrequency = <span data-testid="Template Frequency from List">{plan.plan_frequency}</span>;
    const templateDuration = <span data-testid="Template Duration from List">{duration}</span>;
    const templateCreation = <span data-testid="Template Creation from List">{Helper.formatDate(plan.creationdatetime, false)}</span>;
    return [
      { rowvalue: templateName, classes: "" },
      { rowvalue: templateAmount, classes: "right-aligned" },
      { rowvalue: templateFrequency, classes: "centerAligned" },
      { rowvalue: templateDuration, classes: "centerAligned desktop" },
      { rowvalue: templateCreation, classes: "desktop" },
    ];
  };

  renderItemToColumnsInvoices = item => {
    if (item.type === "Placeholder") {
      return [{ rowvalue: "No open invoices", classes: "span6 gridCenter" }];
    }
    // Each tab has the same columns to display, except for the online tab and it has two additional columns.
    const mobileCreationDate = <span data-testid="Mobile Creation Date"> {Helper.formatDate(item.creationdatetime, true)}</span>;
    const desktopCreationDate = <span data-testid="Desktop Creation Date"> {Helper.formatDate(item.creationdatetime, false)}</span>;
    const orderNumber = Helper.renderOrderNumber(item?.ordertype, item?.ordersubtype, item?.ordernumber, item?.externalid, this.props.filtertype);
    const salesperson = <span data-testid="Sales Person">{item.salesperson}</span>;
    const externalid = <span data-testid="External ID">{item.externalid}</span>;
    const customername = Helper.renderCustomerName(item.contactname, item.companyname);
    const productname = Helper.renderProductName(item.productname, item.itemcount);
    const totalPricePlusTax = Helper.renderTotalPricePlusTax(item.totalpriceplustax);
    const orderstatus = Helper.renderStatus(item.orderstatus, item.ordersubtype);

    // If filter type is not online, don't show salesperson and externalid
    if (this.props.filtertype.tab !== Constants.ORDER_STATUS_ONLINE_UNSHIPPED) {
      return [
        { rowvalue: mobileCreationDate, classes: "mobile" },
        { rowvalue: desktopCreationDate, classes: "desktop" },
        { rowvalue: orderNumber, classes: "desktop" },
        { rowvalue: customername },
        { rowvalue: productname, classes: "desktop" },
        { rowvalue: totalPricePlusTax, classes: "right-aligned" },
        { rowvalue: orderstatus, classes: "desktop right-aligned" },
      ];
    } else {
      return [
        { rowvalue: mobileCreationDate, classes: "mobile" },
        { rowvalue: desktopCreationDate, classes: "desktop" },
        { rowvalue: orderNumber, classes: "desktop" },
        { rowvalue: salesperson, classes: "desktop" },
        { rowvalue: externalid, classes: "desktop" },
        { rowvalue: customername },
        { rowvalue: productname, classes: "desktop" },
        { rowvalue: totalPricePlusTax, classes: "right-aligned" },
        { rowvalue: orderstatus, classes: "desktop right-aligned" },
      ];
    }
  };

  renderTopControlButtons = () => {
    let activeTabClassName = "action-tab";
    let inactiveTabClassName = "action-tab";
    let owingTabClassName = "action-tab";
    let plansTabClassName = "action-tab";
    let invoicesTabClassName = "action-tab";
    let cronTabClassName = "action-tab desktop";
    let cronLogTabClassName = "action-tab desktop";

    if (this.props.filtertype?.tab === Constants.TAB_ACTIVE) {
      activeTabClassName += " selected ";
    } else if (this.props.filtertype?.tab === Constants.TAB_INACTIVE) {
      inactiveTabClassName += " selected ";
    } else if (this.props.filtertype?.tab === Constants.TAB_OWING) {
      owingTabClassName += " selected ";
    } else if (this.props.filtertype?.tab === Constants.TAB_PLANS) {
      plansTabClassName += " selected ";
    } else if (this.props.filtertype?.tab === Constants.TAB_INVOICES) {
      invoicesTabClassName += " selected ";
    } else if (this.props.filtertype?.tab === Constants.TAB_CRON) {
      cronTabClassName += " selected ";
    } else if (this.props.filtertype?.tab === Constants.TAB_CRON_LOG) {
      cronLogTabClassName += " selected ";
    }

    let filter = "";
    if (this.props.filtertype?.tab === Constants.TAB_INVOICES) {
      filter = this.renderCalendarFilter();
    }
    const cronlog = Helper.authorize(Constants.ACTION_VIEW_CRON_LOGS, this.props.appState.usertype) ? (
      <span
        data-testid="Subscription Cron Log Tab"
        className={cronLogTabClassName}
        onClick={() => {
          this.setState({ hideFilter: true, showsearch: true, totals: null, cronjobid: null });
          this.props.handleChangeTabView({ tab: Constants.TAB_CRON_LOG, selectedItem: null });
          this.handleFilterList({ tab: Constants.TAB_CRON_LOG });
        }}
      >
        Cron Log
      </span>
    ) : (
      ""
    );

    return (
      <React.Fragment>
        <div className="recurringlisttopcontrols">
          <span
            data-testid="Subscription Active Tab"
            className={activeTabClassName}
            onClick={() => {
              this.setState({ hideFilter: true, showsearch: true, totals: null });
              this.props.handleChangeTabView({ tab: Constants.TAB_ACTIVE, selectedItem: null });
              this.handleFilterList({ tab: Constants.TAB_ACTIVE });
            }}
          >
            Active
          </span>
          <span
            data-testid="Subscription Inactive Tab"
            className={inactiveTabClassName}
            onClick={() => {
              this.setState({ hideFilter: true, showsearch: true, totals: null });
              this.props.handleChangeTabView({ tab: Constants.TAB_INACTIVE, selectedItem: null });
              this.handleFilterList({ tab: Constants.TAB_INACTIVE });
            }}
          >
            <span className="desktop">Inactive</span>
            <span className="mobile">Inact</span>
          </span>
          <span
            data-testid="Subscription Owing Tab"
            className={owingTabClassName}
            onClick={() => {
              this.setState({ hideFilter: true, showsearch: true, totals: null });
              this.props.handleChangeTabView({ tab: Constants.TAB_OWING, selectedItem: null });
              this.handleFilterList({ tab: Constants.TAB_OWING });
            }}
          >
            <span className="desktop">Owing</span>
            <span className="mobile">Owe</span>
          </span>

          <span
            data-testid="Subscription Invoices Tab"
            className={invoicesTabClassName}
            onClick={() => {
              this.setState({ hideFilter: false, showsearch: true, totals: null });
              this.props.handleChangeTabView({ tab: Constants.TAB_INVOICES, selectedItem: null, active: Constants.FILTER_ALL });
              this.handleFilterList({ tab: Constants.TAB_INVOICES });
            }}
          >
            <span className="desktop">Invoices</span>
            <span className="mobile">Inv</span>
          </span>
          <span
            data-testid="Subscription Cron Tab"
            className={cronTabClassName}
            onClick={() => {
              this.setState({ hideFilter: true, showsearch: false, totals: null });
              this.props.handleChangeTabView({ tab: Constants.TAB_CRON, selectedItem: null });
              this.handleFilterList({ tab: Constants.TAB_CRON });
            }}
          >
            Batches
          </span>
          <span
            data-testid="Subscription Templates Tab"
            className={plansTabClassName}
            onClick={() => {
              this.setState({ hideFilter: true, showsearch: true, totals: null });
              this.props.handleChangeTabView({ tab: Constants.TAB_PLANS, selectedItem: null });
              this.handleFilterList({ tab: Constants.TAB_PLANS });
            }}
          >
            <span className="desktop">Templates</span>
            <span className="mobile">Templ</span>
          </span>
          {cronlog}
        </div>
        <span className="desktop"></span>
        <span className="action-tab-balance">
          <span></span>
          {filter}
        </span>
      </React.Fragment>
    );
  };

  getListGridClassName = () => {
    if (this.props.filtertype?.tab === Constants.TAB_ACTIVE) {
      return "recurringlist active";
    } else if (this.props.filtertype?.tab === Constants.TAB_INACTIVE) {
      return "recurringlist inactive";
    } else if (this.props.filtertype?.tab === Constants.TAB_OWING) {
      return "recurringlist owing";
    } else if (this.props.filtertype?.tab === Constants.TAB_PLANS) {
      return "recurringlist plans";
    } else if (this.props.filtertype?.tab === Constants.TAB_INVOICES) {
      return "recurringlist invoices";
    } else if (this.props.filtertype?.tab === Constants.TAB_CRON) {
      return "recurringlist cron";
    } else if (this.props.filtertype?.tab === Constants.TAB_CRON_LOG) {
      return "recurringlist cronlog";
    }
  };

  getListItems = (callback = null, selectedListItems = null) => {
    super.getListItems(() => {
      if (callback) {
        callback();
      }
      // On the invoices tab, if no items are returned, then inject a message to the user
      if (this.props.filtertype?.tab === Constants.TAB_INVOICES) {
        if (this.state.listItems.length === 0) {
          this.setState({
            listItems: [
              {
                type: "Placeholder",
                label: "No items found",
              },
            ],
          });
        }
      }
    }, selectedListItems);
    // super.getListItems(callback, selectedListItems);
  };
}
export default RecurringList;
