import React from "react";

import * as Constants from "./Constants";

class NMICollectJS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      lname: "",
      zip: "",
      validation: {
        fname: false,
        lname: false,
        zip: false,
        ccnumber: false,
        ccexp: false,
        cvv: false,
      },
    };
  }

  componentDidMount() {
    if (this.props.collectApiKey && !document.getElementById("collectJS")) {
      const script = document.createElement("script");
      script.id = "collectJS";
      script.async = true;
      script.src = "https://secure.nmi.com/token/Collect.js";
      script.setAttribute("data-tokenization-key", this.props.collectApiKey);
      script.setAttribute("data-variant", "inline");
      // Add an event listener to handle script load completion
      const setState = this.setState.bind(this);
      script.onload = () => {
        if (window.CollectJS) {
          window.CollectJS.configure({
            variant: "inline",
            styleSniffer: true,
            callback: tokenizationResponse => {
              const maskedcardnumber = tokenizationResponse.card?.number || "";
              const expiration = tokenizationResponse.card?.exp || "";
              let token = tokenizationResponse.token;
              // We vault the card based on the props passed in
              // because vaulting changes the token to a different value (customer_vault_id)
              if (this.props.vault) {
                this.props.handleVaultNMIToken(
                  tokenizationResponse,
                  this.state.fname,
                  this.state.lname,
                  this.state.zip,
                  maskedcardnumber,
                  expiration
                );
                // If we vaulted the card, the temp token is no longer good
                // The "token" is now a uuid we generated and stored in our billing_cards table
              }
              if (this.props.callback) {
                this.props.callback(
                  Constants.OVERLAY_RESPONSE_OK,
                  this.state.fname,
                  this.state.lname,
                  this.state.zip,
                  token,
                  expiration,
                  maskedcardnumber,
                  tokenizationResponse
                );
                // Constants.OVERLAY_RESPONSE_OK, billingfirst, billinglast, billingzip, card_id, exp_date, card_number, tokenization_response
              }
            },
            validationCallback: function (field, status, message) {
              setState(prevState => ({ validation: { ...prevState.validation, [field]: status } }));
            },
            fields: {
              ccnumber: {
                placeholder: "Card Number",
                selector: "#ccnumber",
              },
              ccexp: {
                placeholder: "Expires [MM|YY]",
                selector: "#ccexp",
              },
              cvv: {
                placeholder: "CVV",
                selector: "#cvv",
              },
            },
          });
        } else {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "An unexpected error occurred (collect).\nPlease refresh the page and try again.",
          });
        }
      };
      // Handle script load errors
      script.onerror = () => {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "An unexpected error occurred (load).\nPlease refresh the page and try again.",
        });
      };
      document.getElementById("collectJSElement").appendChild(script);
    } else {
      const setState = this.setState.bind(this);
      window.CollectJS.configure({
        variant: "inline",
        styleSniffer: true,
        callback: tokenizationResponse => {
          const maskedcardnumber = tokenizationResponse.card?.number || "";
          const expiration = tokenizationResponse.card?.exp || "";
          this.props.handleVaultNMIToken(tokenizationResponse, this.state.fname, this.state.lname, this.state.zip, maskedcardnumber, expiration);
        },
        validationCallback: function (field, status, message) {
          setState(prevState => ({ validation: { ...prevState.validation, [field]: status } }));
        },
        fields: {
          ccnumber: {
            placeholder: "Card Number",
            selector: "#ccnumber",
          },
          ccexp: {
            placeholder: "Expires [MM|YY]",
            selector: "#ccexp",
          },
          cvv: {
            placeholder: "CVV",
            selector: "#cvv",
          },
        },
      });
    }
  }

  render() {
    let saveButtonClass = "action-button green-button ";
    if (this.props.overlayDisabled || !this.isReadyToSubmit()) {
      saveButtonClass += " save-disabled";
    }
    let message = "";
    if (this.props.overlay.message) {
      message = <div className="centerAligned italic highlight">{this.props.overlay.message}</div>;
    }
    const disabled = this.props.overlayDisabled;
    return (
      <React.Fragment>
        <form className="paymentForm">
          <input type="text" name="fname" placeholder="First Name" value={this.state.fname} onChange={this.handleChange} disabled={disabled} />
          <input type="text" name="lname" placeholder="Last Name" value={this.state.lname} onChange={this.handleChange} disabled={disabled} />
          <div className="minHeight3em" id="ccnumber" data-private></div>
          <div className="minHeight3em" id="ccexp" data-private></div>
          <div className="minHeight3em" id="cvv" data-private></div>
          <input
            type="text"
            name="zip"
            placeholder="Zip/Postal Code"
            value={this.state.zip}
            onChange={this.handleChange}
            disabled={disabled}
            data-private
          />
          <div className="controls-two-buttons control-buttons gridCenter">
            <span
              data-testid="Overlay Cancel Button"
              className="action-button red-button"
              onClick={() => {
                this.props.callback(Constants.OVERLAY_RESPONSE_CANCEL);
                this.props.hideOverlay();
              }}
            >
              Cancel
            </span>
            <span data-testid="Overlay Save Button" className={saveButtonClass} onClick={this.props.handleSubmitNMITokenization}>
              {this.props.saveButtonLabel || "Save"}
            </span>
          </div>
          {message}
        </form>
      </React.Fragment>
    );
  }

  handleChange = event => {
    this.setState(prevState => ({
      [event.target.name]: event.target.value,
      validation: { ...prevState.validation, [event.target.name]: event.target.value.trim() !== "" },
    }));
  };

  isReadyToSubmit = () => {
    return (
      this.state.validation.fname &&
      this.state.validation.lname &&
      this.state.validation.zip &&
      this.state.validation.ccnumber &&
      this.state.validation.ccexp &&
      this.state.validation.cvv
    );
  };
}

export default NMICollectJS;
