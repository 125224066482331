import React from "react";
import { deepCopy, renderFullAddress, renderShortAddress } from "./Helper";

// Components
import BaseListViewComponent from "./BaseListViewComponent";
import * as Helper from "./Helper";
import * as Constants from "./Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import TagList from "./TagList";
import Tooltip from "./Tooltip";

class CustomerList extends BaseListViewComponent {
  renderTopControlButtons = () => {
    let customerTabClassName = "action-tab";
    let prospectTabClassName = "action-tab";
    if (this.props.filtertype.tab === Constants.TAB_CUSTOMER) {
      customerTabClassName += " selected ";
    } else if (this.props.filtertype.tab === Constants.TAB_PROSPECTS) {
      prospectTabClassName += " selected ";
    }

    return (
      <React.Fragment>
        <div className="orderlisttopcontrols">
          <span
            data-testid="Customer Tab Customers"
            className={customerTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.TAB_CUSTOMER, active: Constants.ACTIVE });
              this.handleFilterList({ tab: Constants.TAB_CUSTOMER, active: Constants.ACTIVE });
            }}
          >
            Customers
          </span>
          <span
            data-testid="Customer Tab Prospects"
            className={prospectTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.TAB_PROSPECTS, active: Constants.ACTIVE });
              this.handleFilterList({ tab: Constants.TAB_PROSPECTS, active: Constants.ACTIVE });
            }}
          >
            Prospects
          </span>
        </div>
        <span className="desktop"></span>
        <span className="action-tab-balance">
          <span></span>
          {this.renderCalendarFilter()}
        </span>
      </React.Fragment>
    );
  };

  getHeaderRowItems = () => {
    if (this.props.filtertype.tab === Constants.TAB_CUSTOMER) {
      return this.getHeaderRowItemsCustomer();
    } else if (this.props.filtertype.tab === Constants.TAB_PROSPECTS) {
      return this.getHeaderRowItemsProspects();
    }
  };

  getHeaderRowItemsProspects = () => {
    return [
      {
        classes: "header list-header-caret customer firstLeft",
        sortable: false,
        columnheading: "Prospect",
        sortkey: "prospects.firstname,prospects.lastname",
      },
      {
        classes: "header desktop mobilephone",
        sortable: false,
        columnheading: "Primary",
        sortkey: "prospects.mobilephone",
      },
      {
        classes: "header desktop email",
        sortable: false,
        columnheading: "Email",
        sortkey: "prospects.email",
      },
      {
        classes: "header desktop otherphone",
        sortable: false,
        columnheading: "Secondary",
        sortkey: "prospects.otherphone",
      },
      {
        classes: "header desktop created",
        sortable: false,
        columnheading: "Creation Date",
        sortkey: "prospects.creationdatetime",
      },
      {
        classes: "header desktop campaignname",
        sortable: false,
        columnheading: "Campaign Name",
        sortkey: "prospects.campaignname",
      },
      {
        classes: "header desktop centerAligned lastRight",
        sortable: false,
        columnheading: "Status",
        sortkey: "prospects.prospectstatus",
      },
    ];
  };

  getHeaderRowItemsCustomer = () => {
    return [
      {
        classes: "header list-header-caret customer firstLeft",
        sortable: true,
        columnheading: "Customer",
        sortkey: "contacts.firstname,contacts.lastname",
      },
      {
        classes: "header mobilephone",
        sortable: true,
        columnheading: "Primary",
        sortkey: "contacts.mobilephone",
      },
      {
        classes: "header desktop email",
        sortable: true,
        columnheading: "Email",
        sortkey: "contacts.email",
      },
      {
        classes: "header desktop otherphone",
        sortable: true,
        columnheading: "Secondary",
        sortkey: "contacts.otherphone",
      },
      {
        classes: "header desktop address lastRight",
        sortable: true,
        columnheading: "Address",
        sortkey: "contacts.address1,contacts.address2",
      },
    ];
  };

  renderItemToColumns = item => {
    if (this.props.filtertype.tab === Constants.TAB_CUSTOMER) {
      return this.renderItemToColumnsCustomers(item);
    } else if (this.props.filtertype.tab === Constants.TAB_PROSPECTS) {
      return this.renderItemToColumnsProspects(item);
    }
  };

  renderItemToColumnsProspects = item => {
    let recordnumber = item.recordnumber ?? "";
    let expander = false;
    let fullname = item.firstname + " " + item.lastname;
    let email = item.email ? item.email : "-";
    // let mobileviewphone = item.mobilephone ? item.mobilephone : item.otherphone ? item.otherphone : "-";
    let mobilephone = item.mobilephone ? item.mobilephone : "-";
    let otherphone = item.otherphone ? item.otherphone : "-";
    // let contactaddress = renderShortAddress(item);
    // let fulladdress = renderFullAddress(item);
    let campaignname = <div>{item.campaignname ?? ""}</div>;
    let status = Helper.renderStatus(item.prospectstatus);
    if (item.prospectstatus === Constants.PROSPECT_STATUS_NEW && !item.firstname && !item.lastname) {
      fullname = <span title="Email provided but form not submitted">-</span>;
    }

    return [
      { rowvalue: recordnumber, classes: "right-aligned recordnumber" },
      { rowvalue: "", expander: expander, classes: "expander " },
      { rowvalue: fullname, classes: "customer" },
      { rowvalue: mobilephone, classes: "desktop mobilephone" },
      { rowvalue: email, classes: "email desktop", isEmail: true },
      { rowvalue: "", classes: "mobile span2" },
      { rowvalue: email, classes: "email mobile marginBottom1em", isEmail: true },
      { rowvalue: otherphone, classes: "desktop otherphone" },
      { rowvalue: Helper.formatDate(item.creationdatetime, false), classes: "desktop" },
      { rowvalue: campaignname, classes: "desktop campaignname" },
      { rowvalue: status, classes: "desktop prospectstatus" },
    ];
  };

  renderItemToColumnsCustomers = item => {
    const contact = item.contacts?.length ? item.contacts[0] : item;
    let expander = false;
    let companyname = item.companyname ? " (" + item.companyname + ")" : "";
    let fullname = contact.firstname + " " + contact.lastname + companyname;
    let recordnumber = <span data-testid={"Record-" + item.recordnumber + " " + fullname}> {item.recordnumber ?? ""}</span>;
    let email = contact.email ? contact.email : "-";
    let mobileviewphone = contact.mobilephone ? contact.mobilephone : contact.otherphone ? contact.otherphone : "-";
    let mobilephone = contact.mobilephone ? contact.mobilephone : "-";
    let otherphone = contact.otherphone ? contact.otherphone : "-";
    let contactaddress = renderShortAddress(contact);
    let fulladdress = renderFullAddress(contact);
    let tagListElement = "";
    let tooltip = "";

    if (item.contacts?.length > 1) {
      expander = true;
    }

    if ([Constants.CONTACT, Constants.PROSPECT].includes(item.type)) {
      fullname = item.firstname + " " + item.lastname;
      if (item.companyname) {
        fullname += " (" + item.companyname + ")";
      }
    }
    const icon = <FontAwesomeIcon icon={faTag} />;
    if (item.tags?.length > 0) {
      tagListElement = <TagList tags={item.tags} readonly={true} />;
      tooltip = <Tooltip text={icon} tooltip={tagListElement} wrapperClasses="tagListParent" />;
      fullname = (
        <span>
          {fullname} {tooltip}
        </span>
      );
    }

    return [
      { rowvalue: recordnumber, classes: "right-aligned recordnumber" },
      { rowvalue: "", expander: expander, classes: "expander" },
      { rowvalue: fullname, classes: "customer" },
      { rowvalue: mobileviewphone, classes: "mobile mobileviewphone" },
      { rowvalue: mobilephone, classes: "desktop mobilephone" },
      { rowvalue: email, classes: "desktop email", isEmail: true },
      { rowvalue: otherphone, classes: "desktop otherphone" },
      { rowvalue: contactaddress, classes: "desktop tooltip address", tooltipvalue: fulladdress },
    ];
  };

  maybeExpandRecords = records => {
    const newrecords = [];
    records = records.forEach(record => {
      newrecords.push(record);
      if (record.contacts) {
        record.contacts.forEach((contact, index) => {
          contact.type = Constants.CONTACT;
          contact.company = deepCopy(record);
          contact.parentuuid = record.uuid;
          contact.companyname = "";
          contact.recordnumber = "";
          contact.collapsed = true;
          if (index > 0) {
            newrecords.push(contact);
          }
        });
      }
      if (record.type === Constants.CONTACT) {
        record.company = {
          companyname: record.companyname,
          companyuuid: record.companyuuid,
          creationdatetime: record.companycreationdatetime,
          contacts: [deepCopy(record)],
        };
      }
    });
    return newrecords;
  };

  getListGridClassName = () => {
    if (this.props.filtertype.tab === Constants.TAB_PROSPECTS) {
      return "customerlist prospectlist";
    }
    return "customerlist";
  };
}
export default CustomerList;
