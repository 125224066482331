import React from "react";

import { animated, useSpring } from "@react-spring/web";

// Style
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare, faDownload, faShuffle, faSun, faMoon, faBug } from "@fortawesome/free-solid-svg-icons";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import * as ApiBase from "./ApiBase";
import * as Constants from "./Constants";
import * as Helper from "./Helper";

function GearMenu(props) {
  props.setGearCloseFunctionCallback(handleCloseGearMenu);
  const [springs, api] = useSpring(() => ({
    from: { y: -500 },
    to: { y: 0 },
  }));
  return (
    <animated.div
      id="gear-menu"
      style={{
        ...springs,
      }}
    >
      <div id="gear-container">
        <div data-testid="Logout Menu Option" className="gear-menu-option" id="gear-menu-logout" onClick={props.handleLogout}>
          Logout
        </div>
        {renderImportMenuItem(props)}
        {renderExportMenuItem(props)}
        {renderSettingsMenuItem(props)}
        {renderDarkModeMenuItem(props)}
        {renderChangePassword(props)}
        {renderCheckGiftCardBalance(props)}
        {renderQADelete(props)}
        {renderQAOrder(props)}
        {renderQAUpload(props)}
        {renderInventoryMenuItem(props)}
        {renderHowToVideosMenuItem(props)}
        {renderFaqMenuItem(props)}
        {renderSwitchPeerMenuItem(props)}
        {renderMaintenanceMode(props)}
        {renderDebugLogsMenuItem(props)}
        {renderSwitchClientMenuItem(props)}
      </div>
    </animated.div>
  );

  function renderDebugLogsMenuItem(props) {
    const label = props.logRocketActive ? "Deactivate" : "Activate";
    return (
      <div className="gear-menu-option" id="gear-menu-debug-logs" data-testid="Debug Logs" onClick={props.handleToggleLogRocket}>
        <FontAwesomeIcon icon={faBug} /> {label} Logging
      </div>
    );
  }

  function renderQAUpload(props) {
    if (props.appState.isqa && props.appState.usertype === Constants.USER_TYPE_ADMIN) {
      return (
        <div
          className="gear-menu-option"
          id="gear-menu-upload-qa"
          data-testid="QA Upload File"
          onClick={event => {
            // Prompt for manager login
            props.showOverlay({
              type: Constants.OVERLAY_UPLOAD,
              callback: props.postFileUpload,
            });
          }}
        >
          QA Upload File
        </div>
      );
    }
    return "";
  }

  function renderQADelete(props) {
    if (props.appState.isqa && props.appState.usertype === Constants.USER_TYPE_ADMIN) {
      return (
        <div
          className="gear-menu-option"
          id="gear-menu-delete-qa"
          onClick={event => {
            // Prompt for manager login
            props.showOverlay({
              type: Constants.OVERLAY_AUTH_PROMPT,
              prompt: "Manager authorization required.",
              user: props.appState.username,
              callback: (response, authtoken = null) => {
                if (response === Constants.OVERLAY_RESPONSE_YES) {
                  props.handleQAAction(authtoken, Constants.COMMAND_DELETE_QA);
                }
              },
            });
          }}
        >
          QA Delete Data
        </div>
      );
    }
    return "";
  }

  function renderQAOrder(props) {
    if (props.appState.isqa && props.appState.usertype === Constants.USER_TYPE_ADMIN) {
      return (
        <div
          className="gear-menu-option"
          id="gear-menu-order-qa"
          data-testid="QA Load Order Data"
          onClick={event => {
            // Prompt for manager login
            props.showOverlay({
              type: Constants.OVERLAY_AUTH_PROMPT,
              prompt: "Manager authorization required.",
              user: props.appState.username,
              callback: (response, authtoken = null) => {
                if (response === Constants.OVERLAY_RESPONSE_YES) {
                  props.handleQAAction(authtoken, Constants.COMMAND_ORDER_QA);
                }
              },
            });
          }}
        >
          QA Load Order Data
        </div>
      );
    }
    return "";
  }

  function renderCheckGiftCardBalance(props) {
    let features = props.appState?.features;
    if (features?.includes(Constants.FEATURE_PRODUCTS)) {
      return (
        <div
          className="gear-menu-option"
          id="gear-menu-change-password"
          onClick={event => {
            props.handleCheckGiftCardBalance();
          }}
        >
          Check Gift Card Balance
        </div>
      );
    } else {
      return "";
    }
  }

  function renderChangePassword(props) {
    return (
      <div
        className="gear-menu-option"
        id="gear-menu-change-password"
        onClick={event => {
          props.handleChangePassword();
        }}
      >
        Change Password
      </div>
    );
  }

  function renderImportMenuItem(props) {
    if (Helper.authorize(Constants.ACTION_IMPORT_EXPORT, props.appState.usertype)) {
      return (
        <div className="gear-menu-option" id="gear-menu-import" onClick={props.handleShowImportPrompt}>
          <FontAwesomeIcon icon={faUpload} /> Import
        </div>
      );
    } else {
      return "";
    }
  }

  function renderExportMenuItem(props) {
    if (Helper.authorize(Constants.ACTION_IMPORT_EXPORT, props.appState.usertype)) {
      const enabled = Constants.EXPORT_VIEWS.includes(props.appState.currentView);
      const classes = enabled ? "gear-menu-option" : "gear-menu-option save-disabled";
      const handleExport = enabled ? props.handleExport : () => {};
      return (
        <div className={classes} id="gear-menu-export" onClick={handleExport}>
          <FontAwesomeIcon icon={faDownload} /> Export
        </div>
      );
    } else {
      return "";
    }
  }

  function renderSettingsMenuItem(props) {
    if (Helper.authorize(Constants.ACTION_EDIT_SETTINGS, props.appState.usertype)) {
      return (
        <div
          className="gear-menu-option"
          id="gear-menu-settings"
          onClick={() => {
            props.hideOverlay();
            props.handleShowSettings();
          }}
        >
          Settings
        </div>
      );
    } else {
      return "";
    }
  }

  function renderDarkModeMenuItem(props) {
    const title = props.appState.colorMode === Constants.COLOR_MODE_DARK ? "Light Mode" : "Dark Mode";
    const icon = props.appState.colorMode === Constants.COLOR_MODE_DARK ? <FontAwesomeIcon icon={faSun} /> : <FontAwesomeIcon icon={faMoon} />;
    return (
      <div
        className="gear-menu-option"
        id="gear-menu-dark-mode"
        onClick={() => {
          props.hideOverlay();
          props.handleToggleColorMode();
        }}
      >
        {icon}&nbsp;
        {title}
      </div>
    );
  }

  function renderInventoryMenuItem(props) {
    let features = props.appState?.features;
    if (features?.includes(Constants.FEATURE_PRODUCTS) && Helper.authorize(Constants.ACTION_INVENTORY, props.appState.usertype)) {
      if (props.appState.inventory === null) {
        return renderInventoryBeginMenuItem(props);
      } else if (props.appState.inventory === false) {
        return renderInventoryResumeMenuItem(props);
      }
    } else {
      return "";
    }
  }

  function renderInventoryBeginMenuItem(props) {
    return (
      <div
        className="gear-menu-option"
        id="gear-menu-inventory-begin"
        onClick={() => {
          props.hideOverlay();
          props.handleInventory(Constants.ACTION_INVENTORY_BEGIN);
        }}
      >
        Begin Inventory
      </div>
    );
  }

  function renderInventoryResumeMenuItem(props) {
    return (
      <div
        className="gear-menu-option"
        id="gear-menu-inventory-resume"
        onClick={() => {
          props.hideOverlay();
          props.handleInventory(Constants.ACTION_INVENTORY_RESUME);
        }}
      >
        Resume Inventory
      </div>
    );
  }

  function renderHowToVideosMenuItem(props) {
    return (
      <div
        className="gear-menu-option"
        id="gear-menu-how-to-videos"
        onClick={() => {
          props.hideOverlay();
        }}
      >
        <a href={ApiBase.HOW_TO_VIDEOS_URL} target="_blank" rel="noreferrer">
          How To Videos <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      </div>
    );
  }

  function renderFaqMenuItem(props) {
    return (
      <div
        className="gear-menu-option"
        id="gear-menu-faq"
        onClick={() => {
          props.hideOverlay();
        }}
      >
        <a href={ApiBase.FAQ_URL} target="_blank" rel="noreferrer">
          Knowledge Base <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      </div>
    );
  }

  function renderMaintenanceMode(props) {
    if (Helper.authorize(Constants.ACTION_EDIT_GLOBAL_SETTINGS, props.appState.usertype)) {
      return (
        <div
          className="gear-menu-option"
          id="gear-menu-maint"
          onClick={() => {
            props.handleEditMaintenanceMode();
          }}
        >
          Toggle Maintenance Mode
        </div>
      );
    } else {
      return "";
    }
  }

  function renderSwitchClientMenuItem(props) {
    if (Helper.authorize(Constants.ACTION_SWITCH_CLIENT, props.appState.usertype) && !props.appState?.username?.startsWith("pw")) {
      return (
        <div
          className="gear-menu-option"
          id="gear-menu-settings"
          onClick={() => {
            props.hideOverlay();
            props.handleSwitchClient();
          }}
        >
          <FontAwesomeIcon icon={faShuffle} /> Switch Client
        </div>
      );
    } else {
      return "";
    }
  }

  function renderSwitchPeerMenuItem(props) {
    if (props.appState.peers?.length > 1 && Helper.authorize(Constants.ACTION_SWITCH_STORE, props.appState.usertype)) {
      return (
        <div
          className="gear-menu-option"
          id="gear-menu-settings"
          onClick={() => {
            props.hideOverlay();
            props.handleSwitchPeer();
          }}
        >
          <FontAwesomeIcon icon={faShuffle} /> Switch Store
        </div>
      );
    } else {
      return "";
    }
  }

  function handleCloseGearMenu() {
    api.start({ from: { y: 0 }, to: { y: -1000 }, onResolve: () => props.hideOverlay() });
  }
}

export default GearMenu;
